import React, { HTMLProps, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Montserrat } from '../../constants'
import { color } from '../../colors'
import { omit } from 'ramda'

const styles = css`
  font-family: ${Montserrat};
  font-style: normal;
  color: ${color.link.default};
  border: none;
  outline: none;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0;

  &:visited,
  &:focus,
  &:hover {
    color: ${color.link.default};
  }

  &:disabled {
    color: ${color.link.disabled};
  }

  & svg {
    vertical-align: middle;
  }
`

export const ButtonAsLink = styled.button<HTMLProps<HTMLButtonElement>>`
  ${styles}
`

type ReactAnchorProps = HTMLProps<HTMLAnchorElement>

type LinkProps = ReactAnchorProps & {
  className?: string
  // eslint-disable-next-line no-undef
  LinkComponent?: (props: ReactAnchorProps) => JSX.Element
}

const DefaultLink = (props: Omit<LinkProps, 'LinkComponent'>) => (
  <a {...props}>{props.children}</a>
)

export const Link = styled((initialProps: LinkProps) => {
  const props = useMemo(
    () => ({
      ...omit(['LinkComponent'], initialProps)
    }),
    [initialProps]
  )
  const LinkComponent = useMemo(
    () => initialProps.LinkComponent ?? DefaultLink,
    [initialProps.LinkComponent]
  )

  return <LinkComponent {...props} />
})`
  ${styles}
`
