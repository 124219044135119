import React, {
  MutableRefObject,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { CloseButton } from './buttons'
import { color } from '../../colors'
import { createPortal } from 'react-dom'
import { isBrowser } from '../../atoms/isBrowser'
import {
  motion,
  useAnimation,
  useDragControls,
  useMotionValue
} from 'framer-motion'
import { size } from '../../constants'

type Platform = 'mobile' | 'web'

const Background = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
  opacity: 0.5;
  background-color: #0f1b27;
`

export const PopupCloseButton = styled(CloseButton)``

export const PopupContent = styled.div<{ platform: Platform }>`
  ${({ platform }: { platform: Platform }) =>
    platform === 'web'
      ? css`
          width: 100%;
          max-height: 100vh;
          padding: 72px 40px 40px;
          background-color: ${color.background};
          overflow-y: auto;
          position: relative;
          border-radius: 16px;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background-color: transparent;
          }

          ${PopupCloseButton} {
            position: absolute;
            top: 24px;
            right: 24px;
          }
        `
      : css`
          width: 100%;
          height: fit-content;
          padding: 36px 20px;
          padding-top: 32px;
          max-height: unset;
          border-radius: 8px 8px 0px 0px;
          background-color: ${color.background};

          @media (max-width: ${size.xs}) {
            padding: 36px 16px;
          }

          ${PopupCloseButton} {
            position: absolute;
            top: 36px;
            right: 24px;
          }
        `}
`

const useHeight = (target: MutableRefObject<HTMLDivElement | null>) => {
  const [height, setHeight] = useState<number>()

  useEffect(() => {
    if (target.current !== null) {
      setHeight(target.current.getBoundingClientRect().height)
    }
  }, [target])

  return height
}

const getHeight = () => {
  if (!isBrowser) {
    return 0
  }
  const body = window.document.body
  const html = window.document.documentElement

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
}

const animation = {
  translateY: '100%',
  opacity: 0
}

interface useScrollYProps {
  height?: number
  hideTransitionDurationSec?: number
}

const useScrollY = ({ height, hideTransitionDurationSec }: useScrollYProps) => {
  const scrollY = useMotionValue(0)
  const animate = useAnimation()

  useEffect(() => {
    if (typeof height === 'number') {
      const updateScroll = (latest: number) => {
        if ((height && latest > height * 0.33) || latest > 80) {
          animate.start({
            ...animation,
            transition: {
              duration: hideTransitionDurationSec
            }
          })
        }
      }
      const unsubscribeY = scrollY.onChange(updateScroll)

      return () => unsubscribeY()
    }
  }, [height])

  return {
    scrollY,
    animate
  }
}

type useScrollInput = {
  hide: () => void
  hideTransitionDurationSec: number
}

const useScroll = ({ hide, hideTransitionDurationSec }: useScrollInput) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const height = useHeight(ref)
  const topMaxOffset = useMemo(() => (height || 0) - getHeight(), [height])
  const controls = useDragControls()
  const { scrollY, animate } = useScrollY({
    height,
    hideTransitionDurationSec
  })

  return useMemo(
    () => ({
      style: { y: scrollY },
      dragConstraints: {
        top: -(topMaxOffset > 0 ? topMaxOffset : 0),
        bottom: 0
      },
      onAnimationComplete: hide,
      drag: 'y' as const,
      ref,
      animate,
      dragControls: controls
    }),
    [topMaxOffset]
  )
}

const Dragable = styled(
  forwardRef<HTMLDivElement, any>(({ className, children, dragProps }, ref) => (
    <motion.div ref={ref} className={className} {...dragProps}>
      <PopupContent platform="mobile">{children}</PopupContent>
    </motion.div>
  ))
)`
  overflow: hidden;
  position: relative;
`

export const MobileTopPopupPlate = styled.div`
  position: absolute;
  width: 100%;
  height: 12px;
  background-color: ${color.background};
  border-radius: 8px 8px 0px 0px;
  top: 0;
  left: 0;

  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 4px;
    left: calc(50% - 40px / 2);
    top: 4px;
    background: #cccccc;
    border-radius: 10px;
  }
`

const GrabbingBlock = styled(
  ({ children, hideTransitionDurationSec, hide }) => {
    const { ref, ...dragProps } = useScroll({ hide, hideTransitionDurationSec })

    return (
      <motion.div whileTap={{ cursor: 'grabbing' }}>
        <Dragable ref={ref} dragProps={dragProps}>
          <MobileTopPopupPlate />
          {children}
        </Dragable>
      </motion.div>
    )
  }
)`
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  cursor: grab;
`

const PopupBodyMobile = styled(
  ({ className, children, hide, hideTransitionDurationSec }) => (
    <motion.div className={className}>
      <GrabbingBlock
        hide={hide}
        hideTransitionDurationSec={hideTransitionDurationSec}
      >
        {children}
      </GrabbingBlock>
    </motion.div>
  )
)``

export const PopupBodyWeb = styled(({ className, children }) => (
  <div className={className}>
    <PopupContent platform="web">{children}</PopupContent>
  </div>
))`
  max-width: 580px;
`

export interface Props {
  className?: string
  hide: () => void
  bodyClassName?: string
  children?: ReactNode
  closeClickOutside?: boolean
  container?: HTMLElement
  onOpen?: () => void
  showCloseButton?: boolean
  isMobile?: boolean
  minContent?: boolean
  hideTransitionDurationSec?: number
  autoCloseMilliseconds?: number
}

const noop = () => {}

const closePopupOnEscClick = (hide?: () => void) => {
  const handleKeyPress = ({ code }: KeyboardEvent) => {
    if (code === 'Escape') {
      typeof hide === 'function' ? hide() : noop()
    }
  }

  window.addEventListener('keydown', handleKeyPress)

  return () => {
    window.removeEventListener('keydown', handleKeyPress)
  }
}

interface useAutoCloseProps {
  ms?: number
  hide: () => void
}

const useAutoClose = ({ ms, hide }: useAutoCloseProps) => {
  useEffect(() => {
    if (!ms) {
      return
    }
    const timeoutID = setTimeout(() => hide(), ms)
    return () => {
      clearTimeout(timeoutID)
    }
  }, [ms, hide])
}

type PopupBodyProps = Pick<
  Props,
  | 'hide'
  | 'children'
  | 'isMobile'
  | 'showCloseButton'
  | 'className'
  | 'minContent'
  | 'hideTransitionDurationSec'
>

export const PopupBody = styled(
  ({
    className,
    children,
    isMobile,
    showCloseButton,
    hide,
    hideTransitionDurationSec
  }: PopupBodyProps) =>
    isMobile ? (
      <PopupBodyMobile
        className={className}
        hide={hide}
        hideTransitionDurationSec={hideTransitionDurationSec}
      >
        {showCloseButton && <PopupCloseButton onClick={hide} />}
        {children}
      </PopupBodyMobile>
    ) : (
      <PopupBodyWeb className={className} hide={hide}>
        {showCloseButton && <PopupCloseButton onClick={hide} />}
        {children}
      </PopupBodyWeb>
    )
)`
  position: relative;
  width: 100%;
  height: fit-content;

  ${({
    minContent,
    isMobile
  }: Partial<{ minContent: boolean; isMobile: boolean }>) =>
    minContent &&
    !isMobile &&
    css`
      max-width: 364px;
    `}
`

export const Popup = styled(
  ({
    className,
    hide,
    bodyClassName = '',
    children = null,
    closeClickOutside = true,
    container,
    showCloseButton = false,
    isMobile = false,
    onOpen,
    autoCloseMilliseconds = 0,
    hideTransitionDurationSec = 1,
    minContent = false
  }: Props) => {
    useEffect(() => {
      typeof onOpen === 'function' && onOpen()
    }, [])
    useEffect(() => {
      closePopupOnEscClick(hide)
    }, [])
    useAutoClose({ ms: autoCloseMilliseconds, hide })

    return isBrowser ? (
      <>
        {createPortal(
          <div className={className}>
            <Background onClick={closeClickOutside ? hide : noop} />
            <PopupBody
              className={bodyClassName}
              hide={hide}
              hideTransitionDurationSec={hideTransitionDurationSec}
              isMobile={isMobile}
              minContent={minContent}
              showCloseButton={showCloseButton}
            >
              {children}
            </PopupBody>
          </div>,
          container ?? window.document.body
        )}
      </>
    ) : (
      <></>
    )
  }
)`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  ${({ isMobile }) =>
    isMobile &&
    css`
      align-items: flex-end;
    `}
`
