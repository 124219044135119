import { isBrowser } from '../../isBrowser'
import { useEffect } from 'react'

export const useKeyDown = ({
  onPressDown,
  onPressUp,
  onPressEnter,
  onPressBackspace
} = {}) => {
  useEffect(() => {
    if (!isBrowser) {
      return
    }
    const onKeyDown = (event) => {
      if (event.key === 'Backspace') {
        typeof onPressBackspace === 'function' && onPressBackspace()
      }
      if (event.key === 'ArrowDown') {
        typeof onPressDown === 'function' && onPressDown()
      }
      if (event.key === 'ArrowUp') {
        typeof onPressUp === 'function' && onPressUp()
      }
      if (event.key === 'Enter') {
        typeof onPressEnter === 'function' && onPressEnter()
      }
    }
    if (typeof window === 'undefined') {
      return
    }
    window.onkeydown = onKeyDown

    return () => (window.onkeydown = null)
  }, [onPressDown, onPressUp, onPressEnter, onPressBackspace])
}
