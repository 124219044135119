import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'
import { LOCAL_STORAGE, SESSION_STORAGE } from './index.types'
import { isBrowser } from '../../atoms/isBrowser'

type SetValue<T> = Dispatch<SetStateAction<T>>

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.log('parsing error on', { value })
    return undefined
  }
}

type UseStorageInput<T> = {
  key: string
  initialValue: T
  storageType: typeof LOCAL_STORAGE | typeof SESSION_STORAGE
}

export function useStorage<T>({
  key,
  initialValue,
  storageType
}: UseStorageInput<T>): [T, SetValue<T>] {
  const readValue = useCallback((): T => {
    if (!isBrowser) {
      return initialValue
    }

    try {
      const item = window[storageType].getItem(key)
      return item ? (parseJSON(item) as T) : initialValue
    } catch (error) {
      console.warn(`Error reading ${storageType} key “${key}”:`, error)
      return initialValue
    }
  }, [initialValue, key])

  const [storedValue, setStoredValue] = useState<T>(readValue)

  const setValue: SetValue<T> = useCallback((value) => {
    if (!isBrowser) {
      console.warn(
        `Tried setting ${storageType} key “${key}” even though environment is not a client`
      )
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value

      window[storageType].setItem(key, JSON.stringify(newValue))

      setStoredValue(newValue)

      window.dispatchEvent(new Event(storageType))
    } catch (error) {
      console.warn(`Error setting ${storageType} key “${key}”:`, error)
    }
  }, [])

  useEffect(() => {
    setStoredValue(readValue())
  }, [])

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return
      }
      setStoredValue(readValue())
    },
    [key, readValue]
  )

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('storage', handleStorageChange)
      window.addEventListener(storageType, handleStorageChange)

      return () => {
        window.removeEventListener('storage', handleStorageChange)
        window.removeEventListener(storageType, handleStorageChange)
      }
    }
  }, [])

  return [storedValue, setValue]
}
