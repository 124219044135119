import React, { MouseEventHandler, ReactNode, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { createPortal } from 'react-dom'
import { isBrowser } from '../../atoms/isBrowser'

const bottomRightCSS = ({ anchorEl }: { anchorEl: HTMLElement | null }) => {
  if (!anchorEl) return

  const DOMNodeParameters = anchorEl.getBoundingClientRect()
  const yPos = DOMNodeParameters.top + DOMNodeParameters.height + 'px'
  const xPos = DOMNodeParameters.left + 'px'

  return css`
    transform: translate(${xPos}, ${yPos});

    &::after {
      left: 6px;
    }
  `
}

export const DropdownContent = styled.div`
  & {
    z-index: 9;
    min-width: fit-content;
    position: fixed;

    padding-top: 4px;
    display: block;

    top: 0;
    left: 0;
  }

  ${bottomRightCSS}
`

type DropdownProps = {
  anchorEl: HTMLElement | null
  onMouseLeave: MouseEventHandler
  content: ReactNode
  id?: string
}

export const DropdownPopup = forwardRef(
  ({ anchorEl, content, id, onMouseLeave }: DropdownProps, ref: any) =>
    isBrowser ? (
      createPortal(
        <DropdownContent
          ref={ref}
          anchorEl={anchorEl}
          id={id}
          onMouseLeave={onMouseLeave}
        >
          {content}
        </DropdownContent>,
        window.document.body
      )
    ) : (
      <></>
    )
)
