import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.locale('ru')
dayjs.extend(updateLocale)

dayjs.extend(localizedFormat)
dayjs.extend(isoWeek)
dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs().localeData()

dayjs.extend(weekOfYear)

export const twoDaysIsEqual = (day1: dayjs.Dayjs, day2: dayjs.Dayjs) =>
  day1.isSame(day2, 'day')

dayjs.extend(utc)
dayjs.extend(timezone)

const timezonedDayjs = (...args: any[]) => dayjs(...args).tz()

const timezonedUnix = (value: number) => dayjs.unix(value).tz()

timezonedDayjs.unix = timezonedUnix

export { timezonedDayjs }
export const setTimezone = (tz?: string) => dayjs.tz.setDefault(tz)
