import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { FieldProps, FieldSize } from '../../index.field.types'
import { Input as PseudoInput } from '../Input'
import { TimeInput } from './TimeInput'
import { equals, omit } from 'ramda'
import { useReplacedField } from '../../useReplacedField'

const emptyTuple = [undefined, undefined]

type Value = [string | undefined, string | undefined]

const useRenderValues = (
  replacedField: FieldProps<Value>,
  beforeOnChange: (v: Value) => Value
) => {
  const [displayDates, setDisplayDates] = useState(
    Array.isArray(replacedField?.value) ? replacedField.value : emptyTuple
  )

  const [firstDisplayDate, secondDisplayDate] = displayDates
  const [firstFieldValue, secondFieldValue] = replacedField.value ?? emptyTuple

  const onChangeFirstFieldValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = beforeOnChange([event?.target?.value, secondFieldValue])
      setDisplayDates(newValue)
      replacedField.onChange(newValue)
    },
    [replacedField, beforeOnChange]
  )
  const onChangeSecondFieldValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = beforeOnChange([firstFieldValue, event?.target?.value])
      setDisplayDates(newValue)
      replacedField.onChange(newValue)
    },
    [replacedField, beforeOnChange]
  )

  return useMemo(
    () => ({
      replacedField,
      firstFieldValue: firstDisplayDate,
      secondFieldValue: secondDisplayDate,
      onChangeFirstFieldValue,
      onChangeSecondFieldValue
    }),
    [replacedField]
  )
}

const Input = styled(({ className, ...replacedField }) => (
  <input
    readOnly
    className={className}
    tabIndex={-1}
    {...omit(['error', 'success', 'onBlur', 'onFocus'], replacedField)}
  />
))`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0 !important;
  width: 0;
  overflow: hidden;
`

type InputTimeRangeProps = {
  className?: string
  focusErrors?: [boolean, boolean]
  disabled?: [boolean, boolean] | boolean
  error?: string | boolean
  beforeOnChange?: (v: Value) => Value
} & FieldSize &
  FieldProps<Value>

export const InputTimeRange = styled(
  ({
    className,
    size,
    focusErrors,
    error,
    disabled,
    beforeOnChange = (v) => v,
    ...field
  }: InputTimeRangeProps) => {
    const replacedField: FieldProps<Value> = useReplacedField(field, emptyTuple)
    const {
      firstFieldValue: firstDisplayDate,
      secondFieldValue: secondDisplayDate,
      onChangeFirstFieldValue,
      onChangeSecondFieldValue
    } = useRenderValues(replacedField, beforeOnChange)

    const [firstFieldError, secondFieldError] =
      Array.isArray(focusErrors) && error
        ? focusErrors
        : error
        ? [true, true]
        : [false, false]
    const [disabledFirstTimeField, disabledSecondTimeField] = useMemo(
      () => (Array.isArray(disabled) ? disabled : [disabled, disabled]),
      [disabled]
    )

    return (
      <div className={className}>
        <TimeInput
          clearCondition={false}
          disabled={disabledFirstTimeField}
          error={firstFieldError}
          onBlur={field.onBlur}
          onChange={onChangeFirstFieldValue}
          onFocus={field.onFocus}
          size={size}
          value={firstDisplayDate}
        />
        <TimeInput
          clearCondition={false}
          disabled={disabledSecondTimeField}
          error={secondFieldError}
          onBlur={field.onBlur}
          onChange={onChangeSecondFieldValue}
          onFocus={field.onFocus}
          size={size}
          value={secondDisplayDate}
        />
        <Input tabIndex={-1} {...replacedField} />
      </div>
    )
  }
)`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 119px;
  height: ${({ size }) => (size === 'large' ? '48px' : '40px')};
  z-index: 1;

  & > ${PseudoInput} {
    padding-right: 0px;
  }

  & input:not([tabindex='-1']) {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  }

  & > ${PseudoInput}:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;

    & > input:not([tabindex='-1']) {
      border-radius: 4px 0 0 4px;
    }

    ${({ focusErrors }) =>
      equals(focusErrors, [true, false]) &&
      css`
        z-index: 2;
      `}

    ${({ disabled }) =>
      equals(disabled, [true, false]) &&
      css`
        z-index: -1;
      `}

    &::before {
      content: unset !important;
    }
  }

  & > ${PseudoInput}:nth-child(2) {
    position: absolute;
    top: 0;
    right: 1px;
    width: 50%;

    ${({ focusErrors }) =>
      equals(focusErrors, [false, true]) &&
      css`
        z-index: 2;
      `}

    ${({ disabled }) =>
      equals(disabled, [false, true]) &&
      css`
        z-index: -1;
      `}

    & > input:not([tabindex='-1']) {
      border-radius: 0 4px 4px 0;
    }

    &::before {
      content: unset !important;
    }
  }

  &:focus-within {
    & input:focus {
      z-index: 2;
    }
    & input:not(:focus) {
      z-index: 0;
    }
  }
`
