import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as LoaderSVG } from './img/loader.svg'

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

export const Loader = styled(LoaderSVG)`
  z-index: 10;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  animation: ${rotate} 1s linear infinite;
  color: #008ae9;
  width: 36px;
  height: 36px;
`

export const FullScreenLoader = styled(({ className }) => (
  <div className={className}>
    <Loader />
  </div>
))`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`
