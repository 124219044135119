import { globalHistory, useLocation } from '@reach/router'
import { root } from './root'
import {
  selectScrollHistory,
  setHistory
} from '../state/reducers/scrollHistorySlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

export const useScrollHistory = () => {
  const history = useSelector(selectScrollHistory)
  const location = useLocation()

  useEffect(() => {
    if (location && !location.state) {
      location.state = { key: 'firstLocation' }
    }
  }, [])

  useEffect(() => {
    if (location.pathname.indexOf('/catalog/') === 0) return

    location.state &&
      history[location.state.key] &&
      setTimeout(() => {
        root.scrollTo({
          top: history[location.state.key].scrollTo,
          behavior: 'smooth'
        })
      })
  }, [])
}

export const useSaveLocationsHistory = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(
    () =>
      globalHistory.listen(() => {
        dispatch(
          setHistory({
            scroll: root.scrollTop,
            prevLocation: location
          })
        )
      }),
    []
  )
}
