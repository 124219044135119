import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { timezonedDayjs } from '../../daysjsConfig'

type UseInitialSelectedValueInput = {
  value?: dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs]
  selectedDate?: dayjs.Dayjs
  setSelectedDate: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>
}

export const useInitialSelectedValue = ({
  value,
  selectedDate,
  setSelectedDate
}: UseInitialSelectedValueInput) => {
  useEffect(() => {
    if (value && timezonedDayjs(value).isValid() && !selectedDate) {
      setSelectedDate(timezonedDayjs(value))
    }
  }, [value])
}
