/* eslint-disable */
import { Button } from '.'
import { type ButtonProps } from './index.types'
import { StyledComponent } from 'styled-components'

export default Button as unknown as {
  [key in keyof typeof Button]: StyledComponent<
    (props: ButtonProps) => JSX.Element,
    any,
    any,
    never
  >
}

export { ButtonProps }
