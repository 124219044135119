import styled from 'styled-components'
import { Button } from '../../../../atoms/Button'
import { Montserrat, size } from '../../../../constants'
import { Text } from '../../../../atoms/Text'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 8px;

  @media (max-width: ${size.sm}) {
    padding: 0;
  }
`

export const Title = styled(Text.Large)`
  margin-bottom: 16px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 26px;
  }
`

export const Plate = styled(Text.Average)`
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 12px;
  background-color: #e9f2fd;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const ButtonNewOutline = styled(Button.NewOutline)`
  width: 100%;
  white-space: nowrap;
`

export const ButtonNewPrimary = styled(Button.NewPrimary)`
  width: 100%;
  white-space: nowrap;
`

export const ListItem = styled.li`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`

export const List = styled.ol`
  list-style-type: decimal;
  padding-left: 1rem;
  margin-bottom: 16px;

  & > ${ListItem}:not(:first-child) {
    margin-top: 16px;
  }
`
