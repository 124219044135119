import React from 'react'
import {
  ButtonGrey,
  ButtonGroup,
  ButtonNewPrimary,
  Title,
  VideoCallIcon,
  Wrap
} from './index.styles'
import { selectDeviceSizeIsMobile } from '../../../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

export const QuestionStep = ({ goNext }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  return (
    <Wrap>
      <VideoCallIcon />
      <Title bold>
        Вы уже занимаетесь
        <br />с психологом в YouTalk?
      </Title>
      <ButtonGroup>
        <ButtonGrey onClick={goNext} size={isMobile ? 'medium' : 'small'}>
          Нет
        </ButtonGrey>
        <ButtonNewPrimary
          href="/account/login"
          size={isMobile ? 'medium' : 'small'}
          type="externalLink"
        >
          Да
        </ButtonNewPrimary>
      </ButtonGroup>
    </Wrap>
  )
}
