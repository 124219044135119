import {
  EmojiAdmin,
  EmojiAlarmClock,
  EmojiAudio,
  EmojiCheckNo,
  EmojiCheckYes,
  EmojiCloud,
  EmojiCloudRain,
  EmojiCloudSun,
  EmojiCoinAmerican,
  EmojiDizzy,
  EmojiEuro,
  EmojiEyes,
  EmojiFlagBritish,
  EmojiFlagRussian,
  EmojiFlagWhite,
  EmojiGlobe,
  EmojiLgbt,
  EmojiMan,
  EmojiMessenger,
  EmojiPurse,
  EmojiSmile,
  EmojiSmileConfusion,
  EmojiSmileCry,
  EmojiSmileNoMouth,
  EmojiSmilePensive,
  EmojiSmileSunglasses,
  EmojiSun,
  EmojiSyncMessages,
  EmojiTelegram,
  EmojiTwoPeople,
  EmojiUnicorn,
  EmojiVideo,
  EmojiWave,
  EmojiWhatsapp,
  EmojiWoman,
  EmojiWomanShrugging,
  EmojiWrappedGift
} from '.'

export default {
  EmojiAdmin,
  EmojiAlarmClock,
  EmojiAudio,
  EmojiCheckNo,
  EmojiCheckYes,
  EmojiCloud,
  EmojiCloudRain,
  EmojiCloudSun,
  EmojiCoinAmerican,
  EmojiDizzy,
  EmojiEuro,
  EmojiEyes,
  EmojiFlagBritish,
  EmojiFlagRussian,
  EmojiFlagWhite,
  EmojiGlobe,
  EmojiLgbt,
  EmojiMan,
  EmojiMessenger,
  EmojiPurse,
  EmojiSmile,
  EmojiSmileConfusion,
  EmojiSmileCry,
  EmojiSmileNoMouth,
  EmojiSmilePensive,
  EmojiSmileSunglasses,
  EmojiSun,
  EmojiSyncMessages,
  EmojiTelegram,
  EmojiTwoPeople,
  EmojiUnicorn,
  EmojiVideo,
  EmojiWave,
  EmojiWhatsapp,
  EmojiWoman,
  EmojiWrappedGift,
  EmojiWomanShrugging
}
