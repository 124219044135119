import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../../Button'
import { InputSearch } from '../Input/InputSearch'
import { Montserrat } from '../../../constants'
import { SelectItem, SelectItemEmpty } from './SelectItem'
import { color } from '../../../colors'
import { dropdownLoadingRenderer } from './DropdownLoader'
import { pick } from 'ramda'
import { useKeyDown } from './useKeyDown'
import { useScrollTo, useScrollToCurrentChecked } from './useScroll'
import { useSelectContext } from './SelectProvider'

const fullScreenDropdownCSS = ({ fullScreenDropdown }) =>
  fullScreenDropdown &&
  css`
    &&& {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 0;
      padding: 0;
    }
  `

const DropdownTitle = styled.p`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: ${color.text.dark};
  margin: 0;
  padding: 16px 24px 14px 24px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid ${color.border};
`

const ButtonGroup = styled(({ className, toggle, ...field }) => {
  const initialValue = useMemo(() => field.value, [])
  return (
    <div className={className}>
      <Button.NewPrimary onClick={toggle} size="medium" type="button">
        Выбрать
      </Button.NewPrimary>
      <Button.Grey
        onClick={useCallback(() => {
          field.onChange(initialValue)
          toggle()
        }, [toggle])}
        size="medium"
        type="button"
      >
        Отменить
      </Button.Grey>
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 3px -3px 6px rgba(25, 28, 31, 0.06),
    3px -1px 2px rgba(25, 28, 31, 0.06), 3px 1px 0px rgba(25, 28, 31, 0.04);

  & button {
    flex: 0 0 50%;
  }
`

export const OptionsList = styled.div`
  overflow-y: scroll;
  max-height: ${({ virtual, fullScreenDropdown }) =>
    fullScreenDropdown ? 'unset' : !virtual && '328px'};

  &::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 3px;
    height: 131px;
  }

  ${({ fullScreenDropdown }) =>
    fullScreenDropdown &&
    css`
      flex-grow: 1;

      & ${SelectItem} {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      ${SelectItem}:not(:last-child) {
        border-bottom: 1px solid ${color.border};
      }
    `}
`

const Search = styled(({ className }) => {
  const {
    pseudoInput: { onChange, value },
    field,
    searchMode
  } = useSelectContext()
  return (
    <div className={className}>
      <InputSearch
        initialFocus
        onChange={onChange}
        placeholder={field.placeholder}
        readOnly={!searchMode}
        value={value}
      />
    </div>
  )
})`
  padding: 24px 16px 32px 16px;
  width: 100%;
`

const Options = ({ toggle, hoveredItem }) => {
  const { dropdownLoading, options, fullScreenDropdown, field, optionType } =
    useSelectContext()
  return (
    <>
      {dropdownLoading ? (
        dropdownLoadingRenderer()
      ) : options.length ? (
        options.map((option) => (
          <SelectItem
            key={option.value}
            fullScreenDropdown={fullScreenDropdown}
            hoveredItem={hoveredItem}
            option={option}
            optionType={optionType}
            toggle={toggle}
            {...field}
          />
        ))
      ) : (
        <SelectItemEmpty />
      )}
    </>
  )
}

const getListItems = (refList) =>
  refList?.current?.childNodes ? Array.from(refList?.current?.childNodes) : []

const findNodeByForAttribute = (nodeList, value) => {
  if (!Array.isArray(nodeList)) {
    return
  }

  return nodeList.find((node) => node.getAttribute('for') === value)
}

const useScrollToOption = () => {
  const { optionType, field, dropdownScrollBehaviorMode } = useSelectContext()
  const scrollTo = useScrollTo(dropdownScrollBehaviorMode)
  useScrollToCurrentChecked(
    { optionType, id: field.value },
    dropdownScrollBehaviorMode
  )

  return useCallback(
    (element) => {
      if (!element) {
        return
      }
      const inView = element.getAttribute('data-inview')
      inView === 'false' && scrollTo(element)
    },
    [scrollTo]
  )
}
const useOnPressKeys = ({ hoveredItem, setHoveredItem, refList }) => {
  const scrollToOptionView = useScrollToOption()
  const { options, field } = useSelectContext()
  const onPressKey = useCallback(
    (index) => {
      const option = options[index]
      const element = findNodeByForAttribute(
        getListItems(refList),
        option?.value
      )

      scrollToOptionView(element)
      setHoveredItem({
        itemId: option?.value,
        listItemIndex: index,
        node: element
      })
    },
    [options]
  )

  useKeyDown({
    onPressDown: useCallback(() => {
      if (options[hoveredItem.listItemIndex + 1])
        onPressKey(hoveredItem.listItemIndex + 1)
    }, [hoveredItem, onPressKey, options]),
    onPressUp: useCallback(() => {
      if (options[hoveredItem.listItemIndex - 1]) {
        onPressKey(hoveredItem.listItemIndex - 1)
      }
    }, [hoveredItem, onPressKey, options]),
    onPressEnter: useCallback(() => {
      if (hoveredItem.node) hoveredItem.node.click()
    }, [hoveredItem.node]),
    onPressBackspace: useCallback(() => {
      field.onChange()
    }, [field])
  })
}
const useOptionManipulation = () => {
  const refList = useRef(null)
  const [hoveredItem, setHoveredItem] = useState({})

  useOnPressKeys({
    hoveredItem,
    setHoveredItem,
    refList
  })

  return {
    refList,
    hoveredItem,
    handleMouseMove: useCallback((event) => {
      event.stopPropagation()
      const labelForAttribute = event.target.getAttribute('for')

      if (!labelForAttribute) return
      const element = getListItems(refList).find(
        (label) => label.getAttribute('for') === labelForAttribute
      )

      setHoveredItem({
        itemId: labelForAttribute,
        listItemIndex: getListItems(refList).indexOf(element),
        node: element
      })
    }, [])
  }
}

export const Dropdown = styled(
  memo(({ className, toggle }) => {
    const {
      fullScreenDropdown,
      fullScreenDropdownTitle,
      dropdownLoading,
      virtual,
      field,
      searchMode
    } = useSelectContext()
    const { hoveredItem, handleMouseMove, refList } = useOptionManipulation()

    return (
      <div className={className} onMouseMove={handleMouseMove}>
        {fullScreenDropdown && (
          <DropdownTitle>{fullScreenDropdownTitle}</DropdownTitle>
        )}
        {fullScreenDropdown && searchMode && <Search />}
        <OptionsList
          ref={refList}
          dropdownLoading={dropdownLoading}
          fullScreenDropdown={fullScreenDropdown}
          virtual={virtual}
        >
          <Options hoveredItem={hoveredItem} toggle={toggle} />
        </OptionsList>
        {fullScreenDropdown && (
          <ButtonGroup
            toggle={toggle}
            {...pick(['value', 'onChange'], field)}
          />
        )}
      </div>
    )
  })
)`
  padding: 4px 0;
  background: ${color.background};
  box-shadow: 0px 12px 80px rgba(0, 0, 0, 0.06),
    0px 0.788403px 17.869px rgba(0, 0, 0, 0.0357664),
    0px 0.117173px 5.32008px rgba(0, 0, 0, 0.0242336);
  border-radius: 4px;
  z-index: 10;
  overflow-y: hidden;
  min-width: 113px;

  ${fullScreenDropdownCSS}
`
