import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close.svg'
import { color } from '../../styles/vars/colors'
import { createPortal } from 'react-dom'
import { size } from '../../constants'

const Background = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 0;
  opacity: 0.5;
  background-color: #0f1b27;
`

export const ModalContent = styled.div`
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  padding: 32px;
  background-color: ${color.background};
  border-radius: 24px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  @media (max-width: ${size.sm}) {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    padding: 56px 16px 16px;
    border-radius: 0;

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 56px;
      background-color: ${color.background};
    }
  }
`

export const ModalBody = styled.div`
  position: relative;
  width: 100%;

  max-height: calc(var(--vh, 1vh) * 100);

  @media (min-width: ${size.sm}) {
    max-width: 580px;
  }
`

export const ModalCloseButton = styled(({ className, onClick }) => (
  <button
    className={className}
    data-type="modal-close-btn"
    onClick={onClick}
    type="button"
  >
    <CloseIcon />
  </button>
))`
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: ${color.background};
  border: none;
  border-radius: 50%;
  transform: translate(-14px, 14px);

  ${({ closeButtonInside }) =>
    !closeButtonInside &&
    `
    transform: translateX(calc(100% + 24px));
  `}

  @media (max-width: ${size.sm}) {
    top: 16px;
    right: 8px;
    transform: translate(0);
  }

  @media (max-width: ${size.xs}) {
    top: 16px;
    right: 8px;
  }

  svg {
    display: block;
    width: 18px;
    height: auto;

    path {
      fill: ${color.text.dark};
    }
  }
`

const Modal = styled(
  ({
    className,
    closeButtonInside = false,
    hide,
    modifierPopupClass = '',
    bodyClassName = '',
    contentClassName = '',
    children = null,
    container = document.body,
    onOpen,
    showCloseButton = true
  }) => {
    useEffect(() => {
      onOpen && onOpen()
    }, [])
    return (
      <>
        {createPortal(
          <div className={`${className} ${modifierPopupClass}`}>
            <Background onClick={hide} />
            <ModalBody className={bodyClassName}>
              <ModalContent className={contentClassName}>
                {showCloseButton && (
                  <ModalCloseButton
                    closeButtonInside={closeButtonInside}
                    onClick={hide}
                  />
                )}
                {children}
              </ModalContent>
            </ModalBody>
          </div>,
          container
        )}
      </>
    )
  }
)`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  @media (max-width: ${size.sm}) {
    width: auto;
  }
`

export default Modal
