import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { CheckboxMini } from '../CheckboxMini'
import { RadioMini } from '../RadioMini'
import { Text } from '../../Text'
import { color } from '../../../colors'
import { gradientTextCSS } from './styles'
import { omit } from 'ramda'
import { useInView } from 'react-intersection-observer'
import { useSelectContext } from './SelectProvider'

const OptionDescription = styled.span`
  margin-left: 8px;
  color: ${color.formItem.select.option.description};
`

const OptionText = ({ text, description, id }) => (
  <Text.Average semiBold id={id}>
    {text}
    {description && <OptionDescription>{description}</OptionDescription>}
  </Text.Average>
)

const useOnChange = ({
  fieldOnChange,
  fieldValue,
  optionType,
  optionValue,
  toggle
}) => {
  const { fullScreenDropdown } = useSelectContext()
  return useCallback(
    (event) => {
      event.preventDefault()
      if (['default', 'radio', undefined].includes(optionType)) {
        fieldOnChange(optionValue)
        return !fullScreenDropdown && toggle()
      } else {
        return fieldOnChange(
          Array.isArray(fieldValue) && fieldValue.includes(optionValue)
            ? fieldValue.filter((value) => value !== optionValue)
            : [optionValue, ...fieldValue]
        )
      }
    },
    [fieldOnChange, optionValue, fieldValue, optionType, toggle]
  )
}

const getCheckboxOrRadioProps = ({ option, field, optionType }) => ({
  type: optionType,
  value: option.value,
  ...omit(['error'], field),
  id: option.value
})

const Label = styled.label`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 4;
  padding: 10px;

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 14px;
    `}

  ${({ hover, hoveredItem, htmlFor }) => {
    if (hoveredItem?.itemId === htmlFor) {
      return css`
        background: ${color.formItem.select.dropdown.hover};
      `
    }
    return (
      hover &&
      !hoveredItem.itemId &&
      css`
        background: ${color.formItem.select.dropdown.hover};
      `
    )
  }}

  &:active {
    background: ${color.formItem.select.dropdown.press};
  }
`

export const SelectItemEmpty = styled(({ className }) => {
  const { NotFoundText } = useSelectContext()
  return (
    <Label className={className}>
      {typeof NotFoundText === 'string' ? (
        <OptionText text={NotFoundText} />
      ) : (
        NotFoundText
      )}
    </Label>
  )
})`
  pointer-events: none;

  && {
    background: none;
  }
`

const Option = ({ option, optionType, field }) => (
  <>
    {optionType === 'checkbox' ? (
      <CheckboxMini
        fill
        {...getCheckboxOrRadioProps({ option, field, optionType })}
        checked={field.value.includes(option.value)}
      />
    ) : optionType === 'radio' ? (
      <RadioMini
        {...getCheckboxOrRadioProps({ option, field, optionType })}
        checked={option.value === field.value}
      />
    ) : null}
    <OptionText
      description={option.description}
      text={option.text}
      {...omit(['checkbox', 'radio'].includes(optionType) ? 'id' : '', {
        id: option.value
      })}
    />
  </>
)

export const SelectItem = styled(
  ({ className, option, toggle, hoveredItem }) => {
    const { field, size, optionType } = useSelectContext()
    const { ref, inView } = useInView({
      threshold: 0.5
    })
    const onChange = useOnChange({
      fieldOnChange: field.onChange,
      option,
      fieldValue: field.value,
      optionType,
      optionValue: option.value,
      toggle
    })

    return (
      <Label
        ref={ref}
        className={className}
        data-inview={inView}
        hoveredItem={hoveredItem}
        htmlFor={option.value}
        onClick={onChange}
        size={size}
      >
        <Option field={field} option={option} optionType={optionType} />
      </Label>
    )
  }
)`
  & > * {
    pointer-events: none;
  }

  ${({ fullScreenDropdown, optionType }) => {
    if (fullScreenDropdown && ['checkbox', 'radio'].includes(optionType)) {
      return css`
        display: grid;
        grid-template-columns: 18px 1fr;
        align-items: stretch;

        & > ${Text.Average} {
          white-space: normal !important;
          word-wrap: break-word;
        }
      `
    }
  }}

  & > ${Text.Average} {
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    width: 100%;
    ${({ description }) => !description && String(gradientTextCSS)}
  }
`
