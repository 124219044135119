import dayjs from 'dayjs'
import { twoDaysIsEqual } from '../../../daysjsConfig'

type checkInRanngeProps = {
  date: dayjs.Dayjs
  dateRange: Partial<{
    end: dayjs.Dayjs
    start: dayjs.Dayjs
  }>
}

export const checkInRange = ({ date, dateRange }: checkInRanngeProps) => {
  const inRangeCharacteristics = [] as string[]

  if (!dateRange.end || !dateRange.start) {
    inRangeCharacteristics.push('isNotRangeSelect')
    return inRangeCharacteristics
  }
  if (twoDaysIsEqual(dateRange.start, date)) {
    inRangeCharacteristics.push('isStartDate')
  }
  if (twoDaysIsEqual(dateRange.end, date)) {
    inRangeCharacteristics.push('isEndDate')
  }
  const inRange = date.isBetween(dateRange.start, dateRange.end)

  if (inRange) {
    inRangeCharacteristics.push('inRange')
  }

  return inRangeCharacteristics
}
