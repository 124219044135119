import { Montserrat } from '../../constants'
import { css } from 'styled-components'

export const datePickerTextCSS = css`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
