import { isBrowser } from '../../isBrowser'
import { useCallback, useEffect } from 'react'

export const useScrollToCurrentChecked = ({ optionType, id }, behavior) => {
  const scrollTo = useScrollTo(behavior)
  useEffect(() => {
    if (!isBrowser) {
      return
    }
    const currentElement = window.document.getElementById(String(id))
    if (optionType !== 'checkbox') {
      scrollTo(currentElement)
    }
  }, [])
}

export const useScrollTo = (behavior) =>
  useCallback(
    (currentElement) => {
      if (currentElement) {
        return currentElement.scrollIntoView({
          block: 'center',
          behavior: behavior ?? 'smooth'
        })
      }
    },
    [behavior]
  )
