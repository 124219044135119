import Icons from '../../atoms/Icons/index.library'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import dayjs, { default as defaultDayjs } from 'dayjs'
import styled, { css } from 'styled-components'
import { DatePicker } from './DatePicker'
import { DatePickerProvider } from './DatePickerProvider'
import {
  FieldError,
  FieldProps,
  FieldSize
} from '../../atoms/FormItems/index.field.types'
import { Input } from './Input'
import { color } from '../../colors'
import { omit } from 'ramda'
import { setTimezone } from '../../daysjsConfig'
import { useClickOutside } from '../../atoms/useClickOutside'

const sizeCSS = ({ size }: FieldSize) => {
  if (!size) return

  return {
    medium: css`
      && {
        & ${PseudoInput} {
          height: 40px;
        }

        & ${Calendar} {
          right: 10px;
          bottom: 11px;
        }

        &::after {
          padding: 10px;
        }
      }
    `,
    large: css`
      && {
        & ${PseudoInput} {
          height: 48px;
          padding: 14px;
          padding-right: 32px;
        }

        & ${Calendar} {
          right: 14px;
          bottom: 15px;
        }

        &::after {
          padding: 14px;
        }
      }
    `
  }[size]
}

const errorCSS = ({ error }: { error?: unknown }) =>
  Boolean(error) &&
  css`
    &:hover,
    &:active {
      &::before {
        z-index: -1;
      }
    }

    & input {
      border-color: ${color.formItem.border.error};

      &:hover,
      &:active {
        border-color: ${color.formItem.border.error};
      }

      &:hover {
        background: ${color.formItem.background.errorHover};
      }

      &:focus {
        border-color: ${color.formItem.border.error};
      }
    }
  `

const Calendar = styled(Icons.IconCalendar)<{ disabled: boolean }>`
  color: ${color.formItem.icon.default};
  width: 18px;
  height: 18px;

  ${({ disabled }) =>
    disabled &&
    css`
      &&& {
        color: ${color.formItem.icon.disabled};
      }
    `}
`

const PseudoInput = styled.input<{
  focus?: boolean
  success?: boolean
  size?: 'medium' | 'large'
}>`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${color.formItem.text.default};
  border-radius: 4px;
  padding-right: 32px !important;
  outline: none;
  border: 1px solid ${color.formItem.border.default};
  height: 40px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 48px inset #fff !important;
    -webkit-box-shadow: 0 0 0 48px inset #fff !important;
  }

  &::placeholder {
    color: ${color.formItem.placeholder};
  }

  &:hover {
    border-color: ${color.formItem.border.hover};
  }

  &:focus {
    border-color: ${color.formItem.border.focus};
  }

  &::placeholder {
    color: ${color.formItem.placeholder};
  }

  &:disabled {
    background: ${color.formItem.background.disabled};
    border-color: ${color.formItem.background.disabled};
  }

  ${({ success }) =>
    success &&
    css`
      border-color: #03b2a5 !important;
    `}

  ${({ focus }) =>
    focus &&
    css`
      border-color: ${color.formItem.border.focus} !important;
    `}
`

const useDropdownVisible = () => {
  const refInput = useRef(null)
  const ref = useRef(null)
  const [visible, setVisible] = useState(false)

  useClickOutside({
    ref,
    hide: useCallback(() => {
      setVisible(false)
    }, []),
    visible
  })

  return {
    ref,
    refInput,
    visible,
    toggle: useCallback(() => {
      setVisible(!visible)
    }, [visible])
  }
}

const getRenderingValue = ({ value, autoRange, renderFormat }: any) => {
  if (typeof autoRange === 'number' && autoRange > 1 && Array.isArray(value)) {
    return value.map((date) => date.format(renderFormat)).join(' - ')
  }
  if (value && defaultDayjs(value).isValid()) {
    return value.format(renderFormat)
  }
  return ''
}

const useRenderValue = ({
  refInput,
  replacedField,
  renderFormat,
  autoRange
}: any) => {
  useEffect(() => {
    refInput.current.value = getRenderingValue({
      value: replacedField.value,
      renderFormat,
      autoRange
    })
  }, [replacedField.value, autoRange])
}

export const useReplacedField = ({
  field,
  autoRange
}: {
  field: FieldProps<dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs], HTMLInputElement>
  autoRange?: number
}) => {
  const [state, setState] = useState()
  const dateValue = useMemo(() => field.value ?? state, [field.value, state])
  const onChangeDateValue = useMemo(
    () => field.onChange ?? setState,
    [field.onChange, setState]
  )

  const customOnChangeDateValue = useCallback(
    (value: typeof field['value']) => {
      if (!value) {
        return
      }
      if (typeof autoRange === 'number' && autoRange > 1) {
        onChangeDateValue(
          !Array.isArray(value)
            ? [value, value.add(autoRange - 1, 'day')]
            : value
        )
      } else if (
        (typeof autoRange !== 'number' || autoRange <= 1) &&
        Array.isArray(value)
      ) {
        const [dateRangeStart] = value as [dayjs.Dayjs, dayjs.Dayjs]
        onChangeDateValue(dateRangeStart)
      } else {
        onChangeDateValue(value)
      }
    },
    [autoRange, onChangeDateValue, dateValue]
  )

  useEffect(() => {
    const isRange = Array.isArray(dateValue)
    const needChangeForDateRange =
      typeof autoRange === 'number' && autoRange > 1 && !isRange
    const needChangeForDate =
      typeof autoRange === 'number' && autoRange <= 1 && isRange
    const needChanges = needChangeForDateRange || needChangeForDate

    if (needChanges) {
      //for auto change when replace date range
      customOnChangeDateValue(dateValue)
    }
  }, [autoRange])

  const replacedField = useMemo(
    () => ({
      ...field,
      onChange: customOnChangeDateValue,
      value: dateValue
    }),
    [field, customOnChangeDateValue]
  )

  return replacedField
}

type DatePickerProps = {
  className?: string
  initialMonth?: number
  externalState?: dayjs.Dayjs
  fullScreenDropdown?: boolean
  disabledDates?: defaultDayjs.Dayjs[]
  disabledCondition?: (date: defaultDayjs.Dayjs) => boolean
  autoRange?: number
  disabledNextMonthCondition?: (date: defaultDayjs.Dayjs) => boolean
  disabledPrevMonthCondition?: (date: defaultDayjs.Dayjs) => boolean
  renderFormat?: string
}

type Props = FieldProps<
  dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs],
  HTMLInputElement
> &
  DatePickerProps &
  FieldSize &
  FieldError

export const InputDatePicker = styled(
  ({
    className,
    fullScreenDropdown,
    disabledDates,
    disabledCondition,
    autoRange,
    disabledNextMonthCondition,
    disabledPrevMonthCondition,
    renderFormat = 'D MMMM',
    disabled,
    timezone,
    initialMonth,
    externalState,
    ...field
  }: Props) => {
    const { ref, refInput, toggle, visible } = useDropdownVisible()
    const replacedField = useReplacedField({
      field,
      autoRange
    })
    useRenderValue({ refInput, replacedField, autoRange, renderFormat })
    return (
      <DatePickerProvider
        externalState={externalState}
        initialMonth={initialMonth}
        timezone={timezone}
        value={replacedField.value}
      >
        <div ref={ref} className={className}>
          <PseudoInput
            ref={refInput}
            readOnly
            disabled={disabled}
            focus={visible}
            onClick={toggle}
            placeholder={replacedField.placeholder}
          />
          <Input {...omit(['error', 'success'], replacedField)} />
          {visible && (
            <DatePicker
              autoRange={autoRange}
              disabledCondition={disabledCondition}
              disabledDates={disabledDates}
              disabledNextMonthCondition={disabledNextMonthCondition}
              disabledPrevMonthCondition={disabledPrevMonthCondition}
              field={replacedField}
              fullScreen={fullScreenDropdown}
              toggle={toggle}
            />
          )}
          <Calendar disabled={disabled} />
        </div>
      </DatePickerProvider>
    )
  }
)`
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;

  ${Calendar} {
    position: absolute;
    right: 10px;
    bottom: 11px;
    z-index: 1;
    pointer-events: none;
    color: ${color.formItem.select.arrow.color};
  }

  ${DatePicker} {
    position: absolute;
  }

  abbr:focus-visible,
  div:focus-visible {
    outline: none;
    background: transparent !important;
  }

  ${errorCSS}
  ${sizeCSS}
`
