import React from 'react'
import dayjs from 'dayjs'
import styled, { css } from 'styled-components'
import { color } from '../../colors'
import { datePickerTextCSS } from './datePickerTextCSS'

const toISOFormatWeekDays = ([sunday, ...weekDays]: string[]) => [
  ...weekDays,
  sunday
]

const Weekday = styled.div<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      &&& {
        pointer-events: none !important;

        & abbr {
          background: transparent;
          color: ${color.datePicker.day.disabled};
        }
      }
    `}
`

type WeekdaysProps = {
  className?: string
  disabledWeekdays?: number[]
}

const isSunday = (index: number) => index === 7

export const Weekdays = styled(
  ({ className, disabledWeekdays }: WeekdaysProps) => {
    const weekdays = toISOFormatWeekDays(dayjs.weekdays())
    const weekdaysMin = toISOFormatWeekDays(dayjs.weekdaysMin())
    return (
      <div className={className}>
        {weekdaysMin.map((weekday, index) => (
          <Weekday
            key={index}
            disabled={(disabledWeekdays ?? []).includes(
              isSunday(index + 1) ? 0 : index + 1
            )}
          >
            <abbr aria-label={weekdays[index]} title={weekdays[index]}>
              {weekday}
            </abbr>
          </Weekday>
        ))}
      </div>
    )
  }
)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  gap: 8px;
  margin-bottom: 14px;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & abbr {
    ${datePickerTextCSS};
    font-weight: 500;
    pointer-events: none;
    text-decoration: none;
    text-transform: none;
    max-width: 32px;
    height: 24px;
    cursor: pointer;
    color: ${color.datePicker.header.default};
  }
`
