import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  min-width: 176px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0.7884026765823364px 17.869047164916992px 0px
    rgba(0, 0, 0, 0.04);
`

export const DropdownMenu: FC<PropsWithChildren> = ({ children }) => (
  <Container>{children}</Container>
)
