import styled, { css } from 'styled-components'
import { Montserrat, size } from '../../constants'

const defaultStyles = css`
  color: #333;
  font-family: ${Montserrat};
  margin: 0;
`

const h1CSS = css`
  ${defaultStyles}
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Title = {
  H1: styled.h1`
    ${h1CSS}
  `,
  H2asH1styles: styled.h2`
    ${h1CSS}
  `,
  H2: styled.h2`
    ${defaultStyles}
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    @media (max-width: ${size.sm}) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 18px;
      line-height: 26px;
    }
  `,
  H3: styled.h3`
    ${defaultStyles}
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    @media (max-width: ${size.sm}) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  H4: styled.h4`
    ${defaultStyles}
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;

    @media (max-width: ${size.sm}) {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  H5: styled.h5`
    ${defaultStyles}
    font-style: normal;
    font-weight: ${(props) => (props.bold ? '700' : '600')};
    font-size: 16px;
    line-height: 24px;
  `
}
