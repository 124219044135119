import Button from '../../atoms/Button/index.library'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { color } from '../../colors'

const staticProps = {
  type: 'button'
}

export const ButtonGroup = styled(
  ({
    className,
    onSelect,
    onCancel,
    toggle,
    selectedDate,
    autoRange,
    ...field
  }) => (
    <div className={className}>
      <Button.NewPrimary
        onClick={useCallback(() => {
          typeof onSelect === 'function' && onSelect()
          field.onChange(
            autoRange && autoRange > 1
              ? [selectedDate, selectedDate.add(autoRange - 1, 'day')]
              : selectedDate
          )
          toggle()
        }, [toggle, field, onSelect])}
        {...staticProps}
      >
        Выбрать
      </Button.NewPrimary>
      <Button.Grey
        onClick={useCallback(() => {
          typeof onCancel === 'function' && onCancel()
          toggle()
        }, [toggle, field, onCancel])}
        {...staticProps}
      >
        Отменить
      </Button.Grey>
    </div>
  )
)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding-top: 16px;
  border-top: 1px solid ${color.border};

  & button {
    height: 36px;
  }
`
