import styled from 'styled-components'
import { Montserrat, size } from '../../constants'

export const Text = {
  Medium: styled.p`
    font-family: ${Montserrat};
    font-style: normal;
    font-weight: ${(props) =>
      props.bold ? '600' : props.semiBold ? '500' : 'normal'};
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  `,
  Average: styled.p`
    font-family: ${Montserrat};
    font-style: normal;
    font-weight: ${(props) =>
      props.bold ? '700' : props.semiBold ? '500' : 'normal'};
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  `,
  Large: styled.p`
    font-family: ${Montserrat};
    font-style: normal;
    font-weight: ${(props) =>
      props.bold ? '700' : props.semiBold ? '500' : '400'};
    font-size: 18px;
    line-height: 26px;
    margin: 0;

    @media (max-width: ${size.sm}) {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  Small: styled.p`
    font-family: ${Montserrat};
    font-style: normal;
    font-weight: 400;
    font-weight: ${(props) =>
      props.bold ? '700' : props.semiBold ? '500' : '400'};
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  `
}
