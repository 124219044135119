import {
  IconArchive,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBillhead,
  IconBin,
  IconBomb,
  IconBrain,
  IconCalendar,
  IconCalendarOutline,
  IconCheck,
  IconClock,
  IconCoffeeSteam,
  IconCopy,
  IconCross,
  IconEducation,
  IconEnter,
  IconEye,
  IconEyeClose,
  IconFavorite,
  IconFilter,
  IconFire,
  IconGhost,
  IconHeartLock,
  IconInfo,
  IconLock,
  IconLongArrowLeft,
  IconLongArrowRight,
  IconLoupe,
  IconMail,
  IconNotebook,
  IconOther,
  IconPerson,
  IconPhone,
  IconPlus,
  IconQuestion,
  IconSettings,
  IconSmiles,
  IconSpeedometerMax,
  IconUnarchive,
  IconUserAdded,
  IconUsers,
  IconWindowCheck,
  IconPlay,
} from '.'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBin,
  IconCopy,
  IconCross,
  IconEye,
  IconEyeClose,
  IconFilter,
  IconInfo,
  IconLock,
  IconLongArrowLeft,
  IconLongArrowRight,
  IconLoupe,
  IconMail,
  IconPhone,
  IconPlus,
  IconQuestion,
  IconCalendar,
  IconArchive,
  IconUnarchive,
  IconSettings,
  IconPerson,
  IconEnter,
  IconClock,
  IconCheck,
  IconEducation,
  IconCalendarOutline,
  IconBrain,
  IconUserAdded,
  IconBillhead,
  IconNotebook,
  IconSmiles,
  IconWindowCheck,
  IconBomb,
  IconCoffeeSteam,
  IconFavorite,
  IconFire,
  IconGhost,
  IconHeartLock,
  IconOther,
  IconSpeedometerMax,
  IconUsers,
  IconPlay,
}
