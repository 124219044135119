import React, { useState } from 'react'
import { AnswerStep } from './AnswerStep'
import { IconButton, ModalHeader, StyledModal } from './index.styles'
import { IconCross, IconLongArrowLeft } from '../../../atoms/Icons'
import { QuestionStep } from './QuestionStep'
import { noop } from '../../../atoms/noop'
import { selectDeviceSizeIsMobile } from '../../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const CloseButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <IconCross />
  </IconButton>
)

const BackButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <IconLongArrowLeft />
  </IconButton>
)

const Header = ({ goBack, hide, showOnlyClose }) => (
  <ModalHeader showOnlyClose={showOnlyClose}>
    {!showOnlyClose && <BackButton onClick={goBack} />}
    <CloseButton onClick={hide} />
  </ModalHeader>
)

export const steps = {
  QUESTION: 'QUESTION',
  ANSWER: 'ANSWER'
}

export const AlreadyWorkingModal = ({ hide }) => {
  const [step, setStep] = useState(steps.QUESTION)
  const isMobile = useSelector(selectDeviceSizeIsMobile)

  return (
    <StyledModal
      hide={hide}
      step={step}
      {...(isMobile ? { showCloseButton: false } : {})}
    >
      {isMobile && (
        <Header
          goBack={step === steps.ANSWER ? () => setStep(steps.QUESTION) : noop}
          hide={hide}
          showOnlyClose={step === steps.QUESTION}
        />
      )}
      {step === steps.QUESTION && (
        <QuestionStep goNext={() => setStep(steps.ANSWER)} />
      )}
      {step === steps.ANSWER && <AnswerStep hide={hide} />}
    </StyledModal>
  )
}
