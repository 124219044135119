import { LOCAL_STORAGE } from './index.types'
import { useStorage } from './useStorage'

export function useLocalStorage<T>(key: string, initialValue: T) {
  return useStorage<T>({
    key,
    initialValue,
    storageType: LOCAL_STORAGE
  })
}
