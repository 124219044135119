import { root as gatsbyRoot, isBrowser } from './root'
import { useCallback, useEffect } from 'react'

const root = gatsbyRoot || (isBrowser ? window : null)

export const useClickOutside = ({ ref, visible, hide, onClose }) => {
  const clickOutside = useCallback(
    (event) => {
      if (
        ref?.current === event.target ||
        ref?.current.contains(event.target)
      ) {
        return
      }
      hide()
      typeof onClose === 'function' && onClose()
    },
    [hide, ref?.current]
  )

  useEffect(() => {
    if (!root || !visible)
      return root.removeEventListener('click', clickOutside)

    root.addEventListener('click', clickOutside)

    return () => root.removeEventListener('click', clickOutside)
  }, [visible])
}
