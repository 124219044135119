import './index.scss'
import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { useOpenSocial as GAuseOpenSocial } from '../GA'

const SocialList = styled(({ className }) => (
  <ul className={cn('soc-list', className)}>
    <li className="soc-list__item">
      <a
        href="https://www.facebook.com/youtalk.therapy/"
        onClick={GAuseOpenSocial('facebook')}
        rel="noreferrer"
        target="_blank"
      >
        <img alt="Иконка логотипа Twitter" src="/img/icons/header-soc/fb.svg" />
      </a>
    </li>
    <li className="soc-list__item">
      <a
        href="https://www.instagram.com/youtalk.therapy/"
        onClick={GAuseOpenSocial('instagram')}
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="Иконка логотипа Яндекс Дзен"
          src="/img/icons/header-soc/inst.svg"
        />
      </a>
    </li>
    <li className="soc-list__item">
      <a
        href="https://vk.com/youtalk.therapy"
        onClick={GAuseOpenSocial('vk')}
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="Иконка логотипа ВКонтакте"
          src="/img/icons/header-soc/vk.svg"
        />
      </a>
    </li>
    <li className="soc-list__item">
      <a
        href="https://t.me/youtalktherapy"
        onClick={GAuseOpenSocial('telegram')}
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="Иконка логотипа Telegram"
          src="/img/icons/header-soc/tg.svg"
        />
      </a>
    </li>
  </ul>
))``

export default SocialList
