import { SESSION_STORAGE } from './index.types'
import { useStorage } from './useStorage'

export function useSessionStorage<T>(key: string, initialValue: T) {
  return useStorage<T>({
    key,
    initialValue,
    storageType: SESSION_STORAGE
  })
}
