export const size = {
  xxs: '320px',
  xs375: '374px',
  xs: '413px',
  sm: '767px',
  md: '1023px',
  lg: '1439px'
} as const

export const maxWidth = {
  xxs: '100%',
  xs: '100%',
  sm: '748px',
  md: '980px',
  lg: '1224px'
} as const

export const Montserrat = 'Montserrat, sans-serif'
