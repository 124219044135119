import React, { ReactNode } from 'react'
import Text from '../../atoms/Text/index.library'
import styled, { css } from 'styled-components'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

interface Props {
  className?: string
  description?: ReactNode
  title?: ReactNode
  image?: ReactNode
  bottom?: ReactNode
}

export const PopupBodyFill = styled(
  ({ className, image, description, title, bottom }: Props) => (
    <div className={className}>
      {image}
      {title && <Title.H4>{title}</Title.H4>}
      {description && <Text.Average semiBold>{description}</Text.Average>}
      {bottom}
    </div>
  )
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;

  & ${Title.H4} {
    ${({ image }) =>
      image &&
      css`
        margin-top: 24px;
      `}

    font-size: 18px;
    line-height: 26px;
  }

  & ${Text.Average} {
    ${({ bottom }) =>
      bottom &&
      css`
        margin-bottom: 24px;
      `}
  }

  @media (max-width: ${size.md}) {
    width: 100%;
  }
`
