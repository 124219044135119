import '../../assets/style/index.scss'
import './index.scss'
import 'dayjs/locale/ru'
import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { CookiesPlate } from '../../organisms/CookiesPlate'
import { loadPolicy } from '../../state/reducers/policySlice'
import { resetUrl, selectUrl } from '../../state/reducers/routingSlice'
import { setDeviceWindowProps } from '../../state/reducers/deviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import {
  useSaveLocationsHistory,
  useScrollHistory
} from '../../atoms/useScrollHistory'

dayjs.locale('ru')

const App = ({ children }) => {
  const isBrowser = () => typeof window !== 'undefined'
  const isDocument = () => typeof document !== 'undefined'
  const dispatch = useDispatch()
  useSaveLocationsHistory()
  useScrollHistory()

  const navigate = useNavigate()

  const url = useSelector(selectUrl)

  useEffect(() => {
    if (url) {
      navigate(url, { replace: true })
      dispatch(resetUrl())
    }
  }, [url, dispatch])

  let root

  useEffect(() => {
    root = document.querySelector('.root')
  }, [])

  useEffect(() => {
    if (isBrowser() && isDocument()) {
      // eslint-disable-next-line no-inner-declarations
      function updateDeviceProps() {
        dispatch(
          setDeviceWindowProps({
            width: window.innerWidth
          })
        )

        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        document.documentElement.style.setProperty(
          '--height',
          `${window.innerHeight}px`
        )

        setTimeout(() => {
          document.documentElement.style.setProperty(
            '--scroll-width',
            `${window.innerWidth - root.clientWidth}px`
          )
        })
      }

      updateDeviceProps()
      window.addEventListener('resize', updateDeviceProps)

      return () => {
        window.removeEventListener('resize', updateDeviceProps)
      }
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(loadPolicy())
  }, [dispatch])

  return (
    <>
      <CookiesPlate />
      {children}
    </>
  )
}

export default App
