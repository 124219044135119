import React from 'react'
import styled from 'styled-components'
import { Button, Icons, Popup } from '../../youtalk-storybook/src/ui'
import { clearSessionStorage } from '../components/Header'

const LKEnterPopupButtons = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWithArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const lkURL = `${process.env.GATSBY_SITEURL}/account`

export const LKEnterPopup = styled(({ className, isMobile, hide }) => (
  <Popup className={className} hide={hide} isMobile={isMobile}>
    <LKEnterPopupButtons>
      <ButtonWithArrow>
        <Button.NoBordersBlack
          BeforeContent={<Icons.IconPerson />}
          href={lkURL}
          type="link"
        >
          Личный кабинет
        </Button.NoBordersBlack>
        <Icons.IconArrowRight />
      </ButtonWithArrow>
      <Button.NoBordersBlack
        BeforeContent={<Icons.IconEnter />}
        onClick={() => {
          clearSessionStorage()
          window.location.reload()
        }}
      >
        Выйти
      </Button.NoBordersBlack>
    </LKEnterPopupButtons>
  </Popup>
))`
  height: var(--height);
`
