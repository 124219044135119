import '../../../daysjsConfig'
import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Days } from './Days'
import { Input } from '../Input'
import { NavigationButtons } from './NavigationButtons'
import { Props } from './index.types'
import {
  WeekDatePickerProvider,
  useWeekDatePickerContext
} from './WeekDatePickerProvider'
import { Weekdays } from '../Weekdays'
import { checkIsDisabled } from '../helpers'
import { omit } from 'ramda'
import { useReplacedField } from '..'

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Calendar = ({
  autoRange,
  disabledCondition,
  disabledDates,
  onDayClick
}: Pick<
  Props,
  'autoRange' | 'disabledCondition' | 'disabledDates' | 'onDayClick'
>) => {
  const weekCalendar = useWeekDatePickerContext()

  const disabledWeekdays = useMemo(() => {
    const result = []
    const startOfWeek = weekCalendar.state.startOf('week')

    for (let day = 1; day <= 7; day++) {
      const currentDayOfWeek = startOfWeek.add(day - 1, 'days')
      const isSunday = currentDayOfWeek.day() === 0

      const disabled = checkIsDisabled({
        date: currentDayOfWeek,
        disabledDates,
        disabledCondition
      })

      if (disabled) {
        result.push(isSunday ? 0 : day)
      }
    }

    return result
  }, [weekCalendar])

  return (
    <Col>
      <Weekdays disabledWeekdays={disabledWeekdays} />
      <Days
        autoRange={autoRange}
        disabledCondition={disabledCondition}
        disabledDates={disabledDates}
        onDayClick={onDayClick}
      />
    </Col>
  )
}

export const InputWeekCalendar = styled(
  ({
    className,
    disabledDates,
    disabledCondition,
    autoRange,
    disabledNextWeekCondition,
    disabledPrevWeekCondition,
    onWeekChange,
    timezone,
    initialWeek,
    externalState,
    ...field
  }: Props) => {
    const replacedField = useReplacedField({
      field,
      autoRange
    })

    const onDayClick = useCallback(
      (date: dayjs.Dayjs) => {
        replacedField.onChange(date)
      },
      [replacedField]
    )

    return (
      <WeekDatePickerProvider
        externalState={externalState}
        initialWeek={initialWeek}
        timezone={timezone}
        value={replacedField.value}
      >
        <div className={className}>
          <Calendar
            autoRange={autoRange}
            disabledCondition={disabledCondition}
            disabledDates={disabledDates}
            onDayClick={onDayClick}
          />
          <NavigationButtons
            disabledNextWeekCondition={disabledNextWeekCondition}
            disabledPrevWeekCondition={disabledPrevWeekCondition}
            onWeekChange={onWeekChange}
          />
          <Input {...omit(['error', 'success'], replacedField)} />
        </div>
      </WeekDatePickerProvider>
    )
  }
)`
  display: grid;
  grid-template-columns: 272px auto;
  gap: 12px;

  ${Weekdays} {
    margin-bottom: 8px;

    abbr {
      color: #333;
    }
  }
`
