import React from 'react'
import styled from 'styled-components'
import { Day, DaysGrid } from '../Days'
import { Props } from './index.types'
import { checkInRange } from './helpers'
import {
  checkIsDisabled,
  checkIsSelected,
  getRange,
  selectDate
} from '../helpers'
import { useWeekDatePickerContext } from './WeekDatePickerProvider'

const staticProps = {
  role: 'button',
  tabIndex: 0
}

const DayGridWithHidden = styled(DaysGrid)`
  overflow: hidden;
  z-index: 0;
`

export const Days = ({
  disabledDates,
  disabledCondition,
  onDayClick,
  autoRange
}: Pick<
  Props,
  'autoRange' | 'disabledCondition' | 'disabledDates' | 'onDayClick'
>) => {
  const weekCalendar = useWeekDatePickerContext()
  const dateRange = getRange(weekCalendar.selectedDate, autoRange)

  return (
    <DayGridWithHidden>
      {weekCalendar.week.map((date, index) => (
        <Day
          key={index}
          disabled={checkIsDisabled({
            date,
            disabledDates,
            disabledCondition
          })}
          fullScreen={false}
          inRangeCharacteristics={checkInRange({
            date,
            dateRange
          })}
          onClick={selectDate(date, weekCalendar.setSelectedDate, onDayClick)}
          selected={
            weekCalendar.selectedDate
              ? checkIsSelected({
                  selectedDate: weekCalendar.selectedDate,
                  date
                })
              : false
          }
          type="button"
          {...staticProps}
        >
          <abbr aria-label={date.format('L')}>{date.get('D')}</abbr>
        </Day>
      ))}
    </DayGridWithHidden>
  )
}
