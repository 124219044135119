import React, { useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Arrow } from '../ArrowIcon'
import { Link } from 'gatsby'
import { color } from '../../styles/vars/colors'
import { omit } from 'ramda'
import { size } from '../../constants'

const spinnerAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359.99deg);
  }
`

export const buttonTextCSS = css`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`

const sizeCSS = ({ size }) =>
  ({
    small: css`
      & {
        height: 40px;
        padding-top: 6px;
        padding-bottom: 6px;

        ${({ iconOnly }) =>
          iconOnly &&
          css`
            padding: 0;
            width: 40px;
          `}
      }
    `,
    medium: css`
      & {
        height: 48px;
        padding-top: 10px;
        padding-bottom: 10px;

        ${({ iconOnly }) =>
          iconOnly &&
          css`
            padding: 0;
            width: 48px;
          `}
      }
    `,
    large: css`
      & {
        height: 56px;
        padding-top: 14px;
        padding-bottom: 14px;

        ${({ iconOnly }) =>
          iconOnly &&
          css`
            padding: 0;
            width: 56px;
          `}
      }
    `
  }[size])

const contentStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`
const widthCSS = ({ width }) =>
  ({
    fullScreen: css`
      @media (max-width: ${size.sm}) {
        width: 100%;
      }
    `
  }[width])

const BeforeContent = styled.span``
const AfterContent = styled.span``

const LinkOrButton = styled((initialProps) => {
  const props = useMemo(
    () => ({
      ...omit(['BeforeContent', 'AfterContent', 'loading', 'iconOnly'], {
        ...initialProps,
        children: (
          <>
            {initialProps.BeforeContent && (
              <BeforeContent>{initialProps.BeforeContent}</BeforeContent>
            )}
            {initialProps.children || initialProps.Icon}
            {initialProps.AfterContent && (
              <AfterContent>{initialProps.AfterContent}</AfterContent>
            )}
          </>
        )
      })
    }),
    [initialProps]
  )
  return props.type === 'link' ? (
    <Link to={props.href} {...omit(['type', 'href'], props)} />
  ) : props.type === 'externalLink' ? (
    <a {...omit('type', props)} />
  ) : (
    <button {...omit('href', props)} />
  )
})`
  overflow: visible;
  text-decoration: none !important;
  width: fit-content;
  ${buttonTextCSS}

  ${BeforeContent} {
    ${contentStyles}
    margin-right: 8px;
  }

  ${({ BeforeContent }) =>
    BeforeContent &&
    css`
      && {
        padding-left: 14px;
      }
    `}

  ${AfterContent} {
    ${contentStyles}
    margin-left: 8px;
  }

  ${({ AfterContent }) =>
    AfterContent &&
    css`
      && {
        padding-right: 14px;
      }
    `}
`

export const Button = styled.button.attrs((props) => ({
  type: props.type,
  href: props.href,
  disabled: props.disabled || false
}))`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 18px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  background: #e3f5f5;
  color: #03b2a5;
  border: 1px solid #e3f5f5;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.2s ease, border-color 0.2s ease,
    color 0.2s ease;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 4px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    background: #b4e8e4;
    border-color: #b4e8e4;
    color: #00a89b;
    text-decoration: none;
  }

  &:active {
    background: #80d8d1;
    border-color: #80d8d1;
    color: #0a9e91;
    text-decoration: none;
  }

  &:focus {
    background: #e3f5f5;
    border-color: #e3f5f5;
    color: #03b2a5;
    text-decoration: none;

    &::before {
      opacity: 1;
    }
  }

  ${({ spinner }) =>
    spinner &&
    css`
      && {
        color: transparent;

        &:hover,
        &:focus,
        &:active {
          color: transparent;
        }

        &::after {
          content: '' !important;
          position: absolute;
          z-index: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 25px;
          height: 25px;
          border: 4px solid #fff;
          border-right-color: transparent;
          border-radius: 50%;
          animation: ${spinnerAnimation} 0.75s 0.1s linear infinite;
        }
      }
    `}

  &:disabled {
    background: #eff2f2;
    border-color: #eff2f2;
    color: #9d9d9d;
    opacity: 0.5;
    pointer-events: none;

    &:active,
    &:focus,
    &:hover {
      background: #eff2f2;
      border-color: #eff2f2;
      color: #9d9d9d;
      opacity: 0.5;
      pointer-events: none;
    }
  }
`

Button.Cookie = styled.button`
  padding: 8px 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  border: 1px solid #fff;
  border-radius: 4px;
  border-color: #ffffff;
  border-width: 1px;
  background-color: transparent;
  color: #ffffff;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    border-color: #ffffff;
    background-color: transparent;
    color: #ffffff;
  }
`
Button.Primary = styled(Button)`
  border-color: #16c8bb;
  background-color: #16c8bb;
  color: #fff;

  &:hover {
    border-color: #03b2a5;
    background: #03b2a5;
    color: #fff;
  }

  &:active {
    border-color: #009e91;
    background: #009e91;
    color: #fff;
  }

  &:focus {
    border-color: #16c8bb;
    background-color: #16c8bb;
    color: #fff;

    &::before {
      border-color: #fff;
      opacity: 1;
    }
  }
`

Button.Reset = styled(Button)`
  & {
    border-color: #f8f9f9;
    background-color: #f8f9f9;
    color: #9d9d9d;

    &:hover,
    &:focus,
    &:active {
      border-color: #f8f9f9;
      background-color: #f8f9f9;
      color: #9d9d9d;
    }
  }
`

Button.Toggle = styled(({ className, children, onClick }) => (
  <Button className={className} onClick={onClick}>
    {children}
    <Arrow />
  </Button>
))`
  border-color: transparent;
  background-color: transparent;
  color: ${color.text.dark};
  padding-left: 0;
  padding-right: 0;

  & ${Arrow} {
    margin-left: 8px;
    transition: all 0.33s ease;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : 'rotate(0)')};
  }

  &:hover {
    border-color: transparent;
    background: transparent;
    color: ${color.text.dark};
  }

  &:active {
    border-color: transparent;
    background: transparent;
    color: ${color.text.dark};

    & ${Arrow} {
      path {
        stroke: ${color.text.dark};
      }
    }
  }

  &:focus {
    border-color: transparent;
    background-color: transparent;
    color: ${color.text.dark};

    & ${Arrow} {
      path {
        stroke: ${color.text.dark};
      }
    }

    &::before {
      border-color: #000;
      opacity: 1;
    }
  }
`
Button.Close = styled.button`
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media (max-width: ${size.sm}) {
    width: 32px;
    height: 32px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    background: #333333;
    border-radius: 4px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
Button.Outline = styled(Button)`
  border-color: #16c8bb;
  border-width: 2px;
  background-color: #fff;
  color: #16c8bb;

  &:hover {
    border-color: #03b2a5;
    background: #f3fcfb;
    color: #16c8bb;
  }

  &:active {
    border-color: #03b2a5;
    background: #e8faf9;
    color: #16c8bb;
  }

  &:focus {
    border-color: #03b2a5;
    background: #e8faf9;
    color: #16c8bb;

    &::before {
      border-color: #fff;
      opacity: 1;
    }
  }
`

Button.Icon = styled.button`
  padding: 10px;
  outline: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  border: 2px solid transparent;

  &:hover {
    background: #f2f7fc;
    border-color: #f2f7fc;
  }

  &:active {
    background: #eff5fb;
    border-color: #eff5fb;
  }

  &:disabled {
    background: #f8f9f9;
    border-color: #f8f9f9;
    color: #c4c4c4;

    & svg {
      path {
        stroke: #c4c4c4;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 4px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
    border: 2px solid #333333;
  }

  &:focus-visible {
    &::before {
      opacity: 1;
    }
  }
`

const loadingBackgoundTransitions = keyframes`
  0% { 
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
`

const loadingMaskTransitions = keyframes`
  0% { 
    left: -50%;
  }
  50% {
    left: 0;
  }
  100% {
    left: -50%;
  }
`

const resizeCSS = ({ noResize }) =>
  !noResize &&
  css`
    @media (max-width: ${size.sm}) {
      height: 56px;
    }
  `
const focusCSS = css`
  &:focus-visible {
    border: 2px solid #000 !important;
  }
`

const loadingPrimaryCSS = ({ loading }) =>
  loading &&
  css`
    &,
    &:hover,
    &:active {
      background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 100%
        ),
        ${color.button.primary.hover};
      background-size: 200% auto;
      animation: ${loadingBackgoundTransitions} 1s ease-in-out infinite;
    }
  `

const loadingOutlineCSS = ({ loading }) =>
  loading &&
  css`
    &,
    &:hover,
    &:active {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      &::before {
        content: '';
        position: absolute;
        height: calc(100% + 4px);
        width: 150%;
        top: -2px;
        left: -50%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: ${loadingMaskTransitions} 1s ease-in-out infinite;
      }
    }
  `

Button.Grey = styled(LinkOrButton)`
  height: 48px;
  border: 2px solid ${color.button.grey.border};
  outline: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.button.grey.border};
  padding: 14px 22px;
  box-sizing: border-box;
  color: ${color.button.text.default};

  &:hover {
    background: ${color.button.grey.hover};
    border: 2px solid ${color.button.grey.hover};
  }

  &:active {
    background: ${color.button.grey.press};
    border: 2px solid ${color.button.grey.press};
  }

  &:disabled {
    background: ${color.button.background.disabled};
    color: ${color.button.text.disabled};
  }

  ${focusCSS}
  ${loadingOutlineCSS}
  ${resizeCSS}
  ${sizeCSS}
  ${widthCSS}
`

Button.GreyBordered = styled(LinkOrButton)`
  border: 1px solid ${color.button.greyBordered.border};
  background: ${color.button.greyBordered.default};
  color: ${color.button.text.default};
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none !important;
  position: relative;
  outline: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 22px;
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;

  &:hover {
    background: ${color.button.greyBordered.hover};
    border: 1px solid ${color.button.greyBordered.border};
  }

  &:active {
    background: ${color.button.grey.press};
    border: 1px solid ${color.button.greyBordered.border};
  }

  &:disabled {
    background: ${color.button.background.disabled};
    color: ${color.button.greyBordered.border};
    border: 1px solid ${color.button.greyBordered.border};
  }

  ${focusCSS}
  ${loadingOutlineCSS}
  ${resizeCSS}
  ${sizeCSS}
`

Button.NewPrimary = styled(LinkOrButton)`
  position: relative;
  color: #ffffff;
  outline: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.button.primary.default};
  border: 2px solid ${color.button.primary.default};
  padding: 14px 22px;
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;

  &:hover {
    background: ${color.button.primary.hover};
    border: 2px solid ${color.button.primary.hover};
    color: #ffffff;
  }

  &:active {
    background: ${color.button.primary.press};
    border: 2px solid ${color.button.primary.press};
  }

  &:disabled {
    background: ${color.button.background.disabled};
    color: ${color.button.text.disabled};
    border: 2px solid ${color.button.background.disabled};

    & svg {
      path {
        stroke: ${color.button.text.disabled};
      }
    }
  }

  ${focusCSS}
  ${loadingPrimaryCSS}
  ${resizeCSS}
  ${sizeCSS}
  ${widthCSS}
`

Button.NewOutline = styled(LinkOrButton)`
  color: #ffffff;
  outline: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 22px;
  box-sizing: border-box;
  background: ${({ transparent }) =>
    transparent ? 'transparent' : String(color.button.background.default)};
  border: 2px solid ${color.button.outline.border};
  height: 48px;
  overflow: hidden;
  color: ${color.button.outline.content};

  &:hover {
    background: ${color.button.outline.hover};
    color: ${color.button.outline.content};
  }

  &:active {
    background: ${color.button.outline.press};
    color: ${color.button.outline.content};
  }

  &:disabled {
    border-color: ${color.button.background.disabled};
    background: ${color.button.background.disabled};
    color: ${color.button.text.disabled};

    & svg {
      path {
        stroke: ${color.button.text.disabled};
      }
    }
  }

  ${focusCSS}
  ${loadingOutlineCSS}
  ${resizeCSS}
  ${sizeCSS}
`
