import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useLocation } from '@reach/router'
import { useLocationEffect } from '../../components/GA'

export const getSeoMedia = (media) =>
  media.map(({ pathname, alt, url, ...props }) => ({
    url: url ?? `${process.env.GATSBY_SITEURL}${pathname}`,
    alt: [alt, 'alt'].join(' '),
    ...props
  }))

const isIOS =
  typeof window !== 'undefined'
    ? /iP(ad|od|hone)/i.test(navigator.userAgent)
    : false

const SEO = ({
  title = '',
  description = '',
  images,
  type,
  videos,
  noindex = false,
  nofollow = false,
  children,
  ...overrides
}) => {
  useLocationEffect(title)
  const { pathname, search } = useLocation()

  return (
    <>
      <GatsbySeo
        canonical={
          pathname === '/psychologist/'
            ? process.env.GATSBY_SITEURL + pathname + search
            : process.env.GATSBY_SITEURL + pathname
        }
        description={description}
        nofollow={nofollow}
        noindex={noindex}
        openGraph={{
          url: `${process.env.GATSBY_SITEURL}${pathname}`,
          title: title,
          type: type ?? 'Website',
          description: description,
          images: images?.length ? images : [],
          videos: videos?.length ? videos : [],
          site_name: 'YouTalk',
          ...overrides
        }}
        title={title}
        twitter={{
          site: '@youtalk_therapy'
        }}
      />
      {children}
      {isIOS && (
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          name="viewport"
        />
      )}
    </>
  )
}

export default SEO
