import Modal, { ModalBody, ModalContent } from '../../Modal'
import styled, { css } from 'styled-components'
import { size } from '../../../constants'
import { steps } from '.'

export const StyledModal = styled(Modal)`
  z-index: 101;

  & ${ModalBody} {
    max-width: 504px;
  }

  & ${ModalContent} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  ${({ step }) =>
    step === steps.QUESTION
      ? css`
          @media (max-width: ${size.sm}) {
            & ${ModalContent} {
              padding: 16px;
            }
          }
        `
      : css`
          && {
            @media (max-width: ${size.sm}) {
              ${ModalContent} {
                justify-content: flex-start;
                padding-top: 64px;
              }
            }
          }
        `}
`

export const ModalHeader = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0%;
  left: 0;
  padding: 24px 20px 0 20px;
  width: 100vw;
  z-index: 102;

  ${({ showOnlyClose }) =>
    showOnlyClose &&
    css`
      flex-direction: row-reverse;
    `}
`

export const IconButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
  text-decoration: none;

  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`
