import { useCallback, useMemo, useState } from 'react'

export const useReplacedToggleField = (field, initialValue) => {
  const [value, setValue] = useState(initialValue)

  const onChange = useCallback((event) => {
    setValue(typeof event === 'object' ? event.target.value : event)
  }, [])

  return useMemo(
    () => ({
      ...field,
      onChange: field.onChange ?? onChange,
      value: field.value ?? value
    }),
    [field, value, onChange]
  )
}
