import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import dayjs from 'dayjs'
import { getCurrentMonth } from './getCurrentMonth'
import { setTimezone, timezonedDayjs } from '../../daysjsConfig'
import { useInitialSelectedValue } from './useInitialSelectedValue'

const DatePickerContext = createContext({})

const getInitialValue = (value?: dayjs.Dayjs | dayjs.Dayjs[]) => {
  if (Array.isArray(value)) {
    const [initialValue] = value
    return initialValue
  }
  return !value ? undefined : value
}

type DatePickerProviderProps = {
  children: ReactNode
  value?: dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs]
  timezone?: string
  initialMonth?: number
  externalState?: dayjs.Dayjs
}

export const DatePickerProvider = ({
  children,
  value,
  timezone,
  initialMonth,
  externalState
}: DatePickerProviderProps) => {
  const withInitMonthValue = useMemo(() => {
    const initValue = getInitialValue(value)
    const month = initialMonth ?? initValue?.month()

    if (month !== undefined && initValue) {
      return timezonedDayjs(getInitialValue(value)).month(month)
    }
    if (month !== undefined && !initValue) {
      return timezonedDayjs().month(month)
    }

    return timezonedDayjs()
  }, [initialMonth])

  useLayoutEffect(() => {
    if (timezone) {
      setTimezone(timezone)
    }
  }, [timezone])

  const [state, setState] = useState(withInitMonthValue)
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | undefined>(
    getInitialValue(value)
  )

  useEffect(() => {
    if (externalState && externalState.isValid()) {
      setState(externalState)
    }
  }, [externalState])

  useInitialSelectedValue({
    value: getInitialValue(value) ?? undefined,
    selectedDate,
    setSelectedDate
  })

  const month = useMemo(() => getCurrentMonth(state), [state])

  return (
    <DatePickerContext.Provider
      value={{
        month,
        state,
        setState,
        selectedDate,
        setSelectedDate
      }}
    >
      {children}
    </DatePickerContext.Provider>
  )
}

type Context = {
  month: {
    date: dayjs.Dayjs
    type: string
  }[]
  state: dayjs.Dayjs
  setState: Dispatch<SetStateAction<dayjs.Dayjs>>
  selectedDate?: dayjs.Dayjs
  setSelectedDate: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>
}

export const useDatePickerContext = () =>
  useContext(DatePickerContext) as Context
