import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { FieldProps } from '../../atoms/FormItems/index.field.types'
import { timezonedDayjs } from '../../daysjsConfig'

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0 !important;
  width: 0;
  overflow: hidden;
`

export const Input = (
  field: FieldProps<dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs], HTMLInputElement>
) => {
  const valueProps = useMemo(() => {
    const newValue = timezonedDayjs(field.value).valueOf()
    if (Number.isNaN(newValue)) {
      return { value: undefined }
    } else {
      return { value: newValue }
    }
  }, [field.value])

  return <StyledInput {...field} {...valueProps} />
}
