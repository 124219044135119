import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Checkbox } from '../../atoms/FormItems/Checkbox'
import { Label } from '../FinalFormItems'
import { LayoutGroup, motion } from 'framer-motion'
import { color } from '../../colors'
import { size } from '../../constants'
import { useReplacedToggleField } from './useReplacedToggleField'

const errorCSS = ({ error }) =>
  error &&
  css`
    border: 1px solid ${color.formItem.border.error};

    &:hover {
      border: 1px solid ${color.formItem.border.error};
    }
  `
const animation = {
  initial: false,
  layoutId: 'outline',
  transition: {
    duration: 0.33,
    ease: 'linear'
  }
}

const Mask = styled(({ className }) => {
  const [state, setState] = useState(animation)
  useEffect(() => {
    setState({})
  }, [])

  return <motion.div className={className} {...state} />
})`
  border-radius: 24px;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  box-shadow: 0px 0px 0px 2px rgba(22, 200, 187, 1);
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  z-index: 3;
`

export const CheckboxMask = styled(({ className, children, visible }) => (
  <motion.div className={className}>
    {{
      ...children,
      props: { ...children.props, Mask: visible ? <Mask /> : null }
    }}
  </motion.div>
))`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const AnimateToggleMask = styled(({ className, children }) => {
  const [id] = useState(
    `checkboxes-group-${crypto.getRandomValues(new Uint32Array(1))[0]}`
  )
  return (
    <LayoutGroup id={id}>
      <div className={className}>{children}</div>
    </LayoutGroup>
  )
})`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${color.background};
  border-radius: 24px;
  border: 1px solid ${color.formItem.checkbox.border};
  box-sizing: border-box;

  @media (max-width: ${size.sm}) {
    ${Checkbox} {
      flex-grow: 1;
    }
  }

  & ${Checkbox} input + label {
    z-index: 0;
  }

  & ${Checkbox} input:checked + label {
    z-index: 1;
  }

  ${errorCSS}
`

export const AnimatedCheckbox = ({
  option,
  disabled,
  error,
  ...replacedField
}) => (
  <CheckboxMask visible={replacedField.value === option.value}>
    <Checkbox
      noBordered
      disabled={disabled}
      error={error}
      size={size}
      {...option}
      {...replacedField}
      checked={replacedField.value === option.value}
      id={option.id}
      value={option.value}
    />
  </CheckboxMask>
)

export const CheckboxTabsToggler = styled(
  ({ className, options, size, disabled, initialValue, error, ...field }) => {
    const replacedField = useReplacedToggleField(field, initialValue)
    return (
      <AnimateToggleMask className={className} error={error}>
        {options.map((option) => (
          <AnimatedCheckbox
            key={option.value}
            disabled={disabled ?? option.disabled}
            error={error}
            field={field}
            option={option}
            size={size}
            {...replacedField}
          />
        ))}
      </AnimateToggleMask>
    )
  }
)``

export const CheckboxTabsTogglerWithLabel = styled(
  ({ className, label, ...props }) => (
    <div className={className}>
      <Label>{label}</Label>
      <CheckboxTabsToggler {...props} />
    </div>
  )
)`
  & > label {
    margin-bottom: 8px;
  }
`
