import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { omit } from 'ramda'
import { useReplacedField } from '../useReplacedField'
import { useSearhValue } from '../useSearchValues'

const SelectContext = createContext({})

const getRenderingValue = ({ value, multipliePrefix, optionType, options }) => {
  const filteredOptions = options.filter((option) =>
    ['default', 'radio', undefined].includes(optionType)
      ? option.value === value
      : Array.isArray(value) && value.includes(option.value)
  )

  if (['default', 'radio', undefined].includes(optionType)) {
    return filteredOptions[0]?.description
      ? filteredOptions[0]?.text.concat(' ', filteredOptions[0]?.description)
      : filteredOptions[0]?.text
  }

  return filteredOptions.length > 1
    ? `${multipliePrefix} (${filteredOptions.length})`
    : filteredOptions.length === 1
    ? filteredOptions[0]?.description
      ? filteredOptions[0]?.text.concat(' ', filteredOptions[0]?.description)
      : filteredOptions[0]?.text
    : ''
}

const useRenderValue = ({
  replacedField,
  multipliePrefix,
  optionType,
  options
}) => {
  const [input, setInput] = useState({
    value: ''
  })
  useEffect(() => {
    setInput({
      value:
        getRenderingValue({
          value: replacedField.value,
          multipliePrefix,
          optionType,
          options
        }) ??
        replacedField.value ??
        '',
      isEnteredManually: false
    })
  }, [replacedField.value])
  const onChange = useCallback(
    (event) =>
      setInput({
        value: typeof event === 'object' ? event.target.value : event,
        isEnteredManually: true
      }),
    []
  )
  return {
    value: input.value,
    isEnteredManually: input.isEnteredManually,
    onChange
  }
}

export const SelectProvider = ({
  children,
  multipliePrefix,
  optionType,
  options,
  fullScreenDropdown,
  fullScreenDropdownTitle,
  dropdownScrollBehaviorMode,
  dropdownLoading,
  searchMode,
  virtual,
  size,
  searchFilter,
  NotFoundText,
  ...field
}) => {
  const replacedField = useReplacedField(
    omit(['optionType', 'multipliePrefix'], field),
    optionType === 'checkbox' ? [] : undefined
  )
  const { value, onChange, isEnteredManually } = useRenderValue({
    replacedField,
    multipliePrefix,
    optionType,
    options
  })
  const matches = useSearhValue({
    options,
    value,
    isEnteredManually,
    searchFilter
  })
  return (
    <SelectContext.Provider
      value={{
        pseudoInput: { value, onChange, isEnteredManually },
        virtual,
        field: replacedField,
        options: searchMode ? matches : options,
        optionType,
        size,
        fullScreenDropdown,
        fullScreenDropdownTitle,
        dropdownLoading,
        dropdownScrollBehaviorMode,
        searchMode,
        NotFoundText
      }}
    >
      {children}
    </SelectContext.Provider>
  )
}

export const useSelectContext = () => useContext(SelectContext)
