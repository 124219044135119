import Icons from '../../atoms/Icons/index.library'
import React, { ReactNode } from 'react'
import Text from '../../atoms/Text/index.library'
import styled, { css } from 'styled-components'
import { BackButton, CloseButton } from './buttons'
import { FullScreenLoader } from '../../atoms/Loader'
import { Popup, PopupBody, PopupContent, Props as PopupProps } from '.'
import { omit } from 'ramda'
import { size } from '../../constants'

export const ModalCloseButton = styled(CloseButton)``

const Loader = styled(FullScreenLoader)<{ modalHeight: number }>`
  height: ${({ modalHeight }) => `${modalHeight}px`};
  position: relative;
`

const ModalHeaderTitle = styled(Text.Large)`
  font-weight: 700;
`

const ModalHeader = styled.div<{
  withBackButtonHeader?: boolean
  withBackButtonText?: boolean
}>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 22px;
  display: grid;
  width: 100%;

  ${({ withBackButtonHeader, withBackButtonText }) =>
    withBackButtonHeader
      ? css`
          grid-template-columns: ${withBackButtonText ? 'auto' : '24px'} auto 24px;
          grid-template-areas: 'back title close';
        `
      : css`
          grid-template-columns: auto 24px;
          grid-template-areas: 'title close';
        `}

  & > ${BackButton} {
    grid-area: back;
  }

  & > ${ModalHeaderTitle} {
    grid-area: title;
  }

  & > ${ModalCloseButton} {
    grid-area: close;
    position: relative;
  }

  @media (max-width: ${size.sm}) {
    grid-template-areas: 'back title close';
    padding-bottom: 16px;
    margin-bottom: 24px;
    position: relative;

    ${({ withBackButtonText }) => css`
      grid-template-columns: ${withBackButtonText ? 'auto' : '24px'} auto 24px;
    `}

    & > ${ModalHeaderTitle} {
      text-align: center;
    }

    &::before {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      transform: translateX(-20px);
      border-bottom: 1px solid #dadada;
      bottom: 0;
      left: 0;
    }
  }

  @media (max-width: ${size.xs}) {
    &::before {
      width: calc(100% + 32px);
      transform: translateX(-16px);
    }
  }
`

type Props = {
  className?: string | undefined
  loading?: boolean
  hide: () => void
  title?: ReactNode
  children?: ReactNode
  modalHeight?: number
  withBackButtonHeader?: boolean
  backButtonText?: string
  BackButtonIcon?: ReactNode
  onBackButtonClick?: () => void
} & PopupProps

export const Modal = styled(
  ({
    className,
    loading,
    hide,
    title,
    children,
    modalHeight,
    withBackButtonHeader,
    backButtonText,
    BackButtonIcon = <Icons.IconLongArrowLeft />,
    onBackButtonClick,
    ...props
  }: Props) => (
    <Popup className={className} hide={hide} {...omit(['isMobile'], props)}>
      {loading ? (
        <Loader modalHeight={modalHeight} />
      ) : (
        <>
          <ModalHeader
            withBackButtonHeader={withBackButtonHeader}
            withBackButtonText={Boolean(backButtonText)}
          >
            {withBackButtonHeader && (
              <BackButton
                BackButtonIcon={BackButtonIcon}
                backButtonText={backButtonText}
                onClick={onBackButtonClick}
              />
            )}
            {title && <ModalHeaderTitle>{title}</ModalHeaderTitle>}
            <ModalCloseButton onClick={hide} />
          </ModalHeader>
          {children}
        </>
      )}
    </Popup>
  )
)`
  ${PopupContent} {
    padding: 24px;
    width: 100%;
    overflow: visible;
    max-width: 425px;

    @media (max-width: ${size.sm}) {
      height: 100vh;
      max-width: 100%;
      border-radius: 0;
      padding: 16px 20px;
    }
  }

  ${PopupBody} {
    max-width: 425px;

    @media (max-width: ${size.sm}) {
      max-width: 100%;
    }
  }

  @media (min-width: calc(${size.sm} + 1px)) and (max-height: 100vh) {
    ${PopupBody} {
      max-height: 100%;
    }

    ${PopupContent} {
      height: auto;
      max-height: unset;
    }
  }
`
