import styled from 'styled-components'
import { Button } from '../../../atoms/Button'

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 21px 32px 21px;
  width: 100%;
`

export const ButtonNewPrimary = styled(Button.NewPrimary)`
  width: 100%;
`
export const ButtonNewOutline = styled(Button.NewOutline)`
  width: 100%;
`
