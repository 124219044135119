import InputMask, { InputState } from 'react-input-mask'
import React, { ForwardedRef, forwardRef } from 'react'
import { FieldProps, FieldSize } from '../../index.field.types'
import { Input } from '../Input'
import { omit } from 'ramda'

type InputProps = {
  className?: string
  maskPlaceholder?: string | null
  onClick: () => void
  placeholder?: string
}

type TimeInputProps = Pick<
  FieldProps<string | undefined>,
  'onChange' | 'onBlur' | 'onFocus' | 'value' | 'disabled'
> & { clearCondition: boolean; error?: boolean } & FieldSize

const mask = '12:34'
const formatChars = {
  '1': '[0-9]',
  '2': '[0-9]',
  '3': '[0-9]',
  '4': '[0-9]'
}

const startWithForArray = (prefixes: string[], str: string) =>
  prefixes.some((prefix) => str.startsWith(prefix))
const endWithForArray = (prefixes: string[], str: string) =>
  prefixes.some((prefix) => str.endsWith(prefix))

const beforeMaskedValueChange = (newState: InputState) => {
  const { value } = newState

  const [hours, minutes] = value.split(':')

  let newHours = '__'
  let newMinutes = '__'
  let newSelection = newState.selection

  if (hours !== '__') {
    if (startWithForArray(['0', '1', '2'], hours)) {
      newHours = hours
    } else {
      const [secondPart] = hours
      newHours = `0${secondPart}`
      newSelection = {
        start: 2,
        end: 2
      }
    }
  }

  if (!Number.isNaN(hours) && Number(hours) > 23) {
    newHours = '23'
  }

  if (minutes !== '__' && minutes.endsWith('_')) {
    if (startWithForArray(['0', '1', '2', '3', '4', '5'], minutes)) {
      newMinutes = minutes
    } else {
      newMinutes = `0_`
    }
  }

  if (!Number.isNaN(minutes)) {
    const [firstPart] = minutes

    if (endWithForArray(['0', '1', '2', '8', '9'], minutes)) {
      newMinutes = `${firstPart}0`
    }
    if (endWithForArray(['3', '4', '5', '6', '7'], minutes)) {
      newMinutes = `${firstPart}5`
    }
    if (Number(minutes) > 57) {
      newMinutes = '00'
    }
  }

  return { value: `${newHours}:${newMinutes}`, selection: newSelection }
}

export const TimeInput = forwardRef(
  (props: TimeInputProps, ref: ForwardedRef<HTMLInputElement>) => (
    <InputMask
      alwaysShowMask
      beforeMaskedValueChange={beforeMaskedValueChange}
      formatChars={formatChars}
      mask={mask}
      maskPlaceholder={null}
      {...(props as any)}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    >
      {(inputProps: InputProps) => (
        <Input
          ref={ref}
          clearCondition={props.clearCondition}
          disabled={props.disabled}
          inputMode="numeric"
          type="text"
          {...omit(['maskPlaceholder'], inputProps)}
        />
      )}
    </InputMask>
  )
)
