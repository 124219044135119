import dayjs from 'dayjs'

const getStartEndOfMonth = (date: dayjs.Dayjs) => {
  const [startOfMonth, endOfMonth] = [date.startOf('M'), date.endOf('M')]

  return [startOfMonth, endOfMonth]
}

const getMainMonthChunk = (date: dayjs.Dayjs) => {
  const daysInMonth = date.daysInMonth()
  const [startOfMonth] = getStartEndOfMonth(date)
  const month = [
    {
      date: startOfMonth,
      type: 'current'
    }
  ]

  for (let i = 1; i < daysInMonth; i++) {
    month.push({
      date: startOfMonth.add(i, 'day'),
      type: 'current'
    })
  }

  return month
}

const getPrevMonthChunk = (date: dayjs.Dayjs) => {
  const [startOfMonth] = getStartEndOfMonth(date)
  const startOfWeek = startOfMonth.startOf('w')
  const prevMonthChunk =
    startOfMonth.diff(startOfWeek, 'day') !== 0
      ? [
          {
            date: startOfWeek,
            type: 'prev'
          }
        ]
      : []

  for (let i = 1; i < startOfMonth.diff(startOfWeek, 'day'); i++) {
    prevMonthChunk.push({
      date: startOfWeek.add(i, 'day'),
      type: 'prev'
    })
  }
  return prevMonthChunk
}

const getNextMonthChunk = (date: dayjs.Dayjs) => {
  const [_, endOfMonth] = getStartEndOfMonth(date)
  const endOfWeek = endOfMonth.endOf('w')
  const nextMonthChunk =
    endOfWeek.diff(endOfMonth, 'day') !== 0
      ? [
          {
            date: endOfWeek,
            type: 'next'
          }
        ]
      : []

  for (let i = 1; i < endOfWeek.diff(endOfMonth, 'day'); i++) {
    nextMonthChunk.push({
      date: endOfWeek.subtract(i, 'day'),
      type: 'next'
    })
  }

  return nextMonthChunk.reverse()
}

export const getCurrentMonth = (
  date: dayjs.Dayjs
): Array<{ date: dayjs.Dayjs; type: string }> => [
  ...getPrevMonthChunk(date),
  ...getMainMonthChunk(date),
  ...getNextMonthChunk(date)
]
