import { useCallback, useState } from 'react'

export const usePopup = () => {
  const [visible, seVisible] = useState<boolean>()

  const show = useCallback(() => {
    seVisible(true)
  }, [])

  const hide = useCallback(() => {
    seVisible(false)
  }, [])

  const toggle = useCallback(() => {
    seVisible(!visible)
  }, [visible])

  return { visible, show, hide, toggle }
}
