import React, { ReactNode, useCallback } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { DatePickerProps } from './DatePicker'
import { DatePickerProvider, useDatePickerContext } from './DatePickerProvider'
import { Day, Days } from './Days'
import { FieldProps } from '../../atoms/FormItems/index.field.types'
import { Header, Title } from './Header'
import { Input } from './Input'
import { Weekdays } from './Weekdays'
import { getRange } from './helpers'
import { omit } from 'ramda'
import { size } from '../../constants'
import { useReplacedField } from '.'

type CalendarActionProps = {
  onDayClick?: (date: dayjs.Dayjs) => void
  onMonthChange?: (date: dayjs.Dayjs) => void
}

type CalendarViewProps = Pick<
  DatePickerProps,
  | 'disabledDates'
  | 'disabledCondition'
  | 'disabledNextMonthCondition'
  | 'disabledPrevMonthCondition'
  | 'fullScreen'
  | 'autoRange'
> &
  CalendarActionProps & { BetweenHeadAndDays?: ReactNode }

export const CalendarView = ({
  disabledDates,
  disabledCondition,
  disabledNextMonthCondition,
  disabledPrevMonthCondition,
  fullScreen,
  autoRange,
  onDayClick,
  onMonthChange,
  BetweenHeadAndDays = null
}: CalendarViewProps) => {
  const datePicker = useDatePickerContext()

  return (
    <>
      <Header
        disabledNextMonthCondition={disabledNextMonthCondition}
        disabledPrevMonthCondition={disabledPrevMonthCondition}
        onMonthChange={onMonthChange}
        setState={datePicker.setState}
        state={datePicker.state}
      />
      {BetweenHeadAndDays}
      <Weekdays />
      <Days
        dateRange={getRange(datePicker.selectedDate, autoRange)}
        disabledCondition={disabledCondition}
        disabledDates={disabledDates}
        fullScreen={fullScreen}
        month={datePicker.month}
        onDayClick={onDayClick}
        selectedDate={datePicker.selectedDate}
        setSelectedDate={datePicker.setSelectedDate}
      />
    </>
  )
}

type CalendarProps = Pick<
  DatePickerProps,
  | 'disabledDates'
  | 'disabledCondition'
  | 'disabledNextMonthCondition'
  | 'disabledPrevMonthCondition'
  | 'autoRange'
> & { timezone: string } & FieldProps<
    dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs],
    HTMLInputElement
  > & { className?: string } & {
    BetweenHeadAndDays?: ReactNode
    initialMonth?: number
    externalState?: dayjs.Dayjs
  } & Pick<CalendarActionProps, 'onMonthChange'>

export const InputCalendar = styled(
  ({
    disabledDates,
    disabledCondition,
    disabledNextMonthCondition,
    disabledPrevMonthCondition,
    fullScreen,
    autoRange,
    timezone,
    className,
    BetweenHeadAndDays,
    onMonthChange,
    initialMonth,
    externalState,
    ...field
  }: CalendarProps) => {
    const replacedField = useReplacedField({
      field,
      autoRange
    })

    const onDayClick = useCallback(
      (date: dayjs.Dayjs) => {
        replacedField.onChange(date)
      },
      [replacedField]
    )

    return (
      <DatePickerProvider
        externalState={externalState}
        initialMonth={initialMonth}
        timezone={timezone}
        value={replacedField.value}
      >
        <div className={className}>
          <CalendarView
            BetweenHeadAndDays={BetweenHeadAndDays}
            autoRange={autoRange}
            disabledCondition={disabledCondition}
            disabledDates={disabledDates}
            disabledNextMonthCondition={disabledNextMonthCondition}
            disabledPrevMonthCondition={disabledPrevMonthCondition}
            onDayClick={onDayClick}
            onMonthChange={onMonthChange}
            {...field}
          />
          <Input {...omit(['error', 'success'], replacedField)} />
        </div>
      </DatePickerProvider>
    )
  }
)`
  && {
    max-width: 680px;
    width: 100%;

    & ${Day} abbr {
      width: 100%;
    }

    & ${Header} ${Title} {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    @media (max-width: ${size.sm}) {
      & ${Header} ${Title} {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
      }
    }
  }
`
