import { useMemo } from 'react'

interface Option {
  text: string | number
  description?: string | number
  value: string | number
}

type UseSearhValueInput<T> = {
  options: T[]
  value: string
  isEnteredManually: boolean
  searchFilter?: (value: string) => (v: T) => boolean
  formatForSearch?: (arg: string) => string
}

type SeacrhInput<T> = UseSearhValueInput<T>

export const searchOptions = <T extends Option = Option>({
  options,
  value,
  isEnteredManually,
  searchFilter
}: SeacrhInput<T>) => {
  if (!value || !isEnteredManually) {
    return options
  }

  const regexp = new RegExp(
    value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLocaleLowerCase()
  )

  const matches = options.filter(
    typeof searchFilter === 'function'
      ? searchFilter(value)
      : ({ text, description }) =>
          regexp.test([text, description].join(' ').toLocaleLowerCase())
  )

  return matches
}

export const useSearhValue = <T extends Option = Option>({
  options,
  value,
  isEnteredManually,
  searchFilter
}: UseSearhValueInput<T>) =>
  useMemo(
    () =>
      searchOptions({
        options,
        value,
        isEnteredManually,
        searchFilter
      }),
    [value, options, isEnteredManually, searchFilter]
  )
