import React, { forwardRef, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Dropdown } from './Dropdown'
import { IconArrowDown } from '../../Icons'
import { SelectProvider, useSelectContext } from './SelectProvider'
import { color } from '../../../colors'
import { omit } from 'rambda'
import { useClickOutside } from '../../useClickOutside'

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      & ${SearchInput} {
        height: 40px;
      }

      & ${Arrow} {
        right: 10px;
        bottom: 10px;
      }

      &::after {
        padding: 10px;
      }
    `,
    large: css`
      & ${SearchInput} {
        height: 48px;
        padding: 14px;
        padding-right: 36px;
      }

      & ${Arrow} {
        right: 14px;
        bottom: 14px;
      }

      &::before {
        right: 32px;
      }
    `
  }[size])

const errorCSS = ({ error }) =>
  error &&
  css`
    &:hover,
    &:active {
      &::before {
        z-index: -1 !important;
      }
    }

    & input {
      border-color: ${color.formItem.border.error};

      &:hover,
      &:active {
        border-color: ${color.formItem.border.error};
      }

      &:hover:not(:focus) {
        background: ${color.formItem.background.errorHover};
      }

      &:focus {
        border-color: ${color.formItem.border.error};
      }
    }
  `

const Arrow = styled(IconArrowDown)`
  ${({ disabled }) =>
    disabled &&
    css`
      &&& {
        color: ${color.formItem.text.disabled};
        pointer-events: none;
      }
    `}

  ${({ visible }) =>
    visible &&
    css`
      transform: rotate(180deg);
    `}
`

const SearchInput = styled(
  forwardRef(({ className, toggle, SearchInputComponent }, ref) => {
    const {
      pseudoInput: { onChange, value },
      field,
      searchMode,
      optionType
    } = useSelectContext()
    const { onFocus, onBlur, disabled, placeholder } = field

    const props = {
      className,
      disabled,
      onChange,
      onClick: toggle,
      onFocus,
      onBlur,
      placeholder,
      readOnly: !searchMode || optionType === 'checkbox',
      value
    }

    if (SearchInputComponent !== null) {
      return <SearchInputComponent ref={ref} {...props} />
    }

    return <input {...props} ref={ref} />
  })
)`
  cursor: ${({ searchMode }) => (searchMode ? 'text' : 'pointer')};
  width: 100%;
  height: 40px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${color.formItem.text.default};
  border-radius: 4px;
  padding-right: 32px;
  outline: none;
  border: 1px solid ${color.formItem.border.default};
  height: 40px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 48px inset #fff !important;
    -webkit-box-shadow: 0 0 0 48px inset #fff !important;
  }

  &::placeholder {
    color: ${color.formItem.placeholder};
  }

  &:hover {
    border-color: ${color.formItem.border.hover};
  }

  &:focus {
    border-color: ${color.formItem.border.focus};
  }

  &::placeholder {
    color: ${color.formItem.placeholder};
  }

  &:disabled {
    background: ${color.formItem.background.disabled};
    border-color: ${color.formItem.background.disabled};
    color: ${color.formItem.text.valueDisabled};
  }

  ${({ success }) =>
    success &&
    css`
      border-color: #03b2a5 !important;
    `}

  ${({ focus }) =>
    focus &&
    css`
      border-color: ${color.formItem.border.focus} !important;
    `}
`

const useDropdownVisible = () => {
  const ref = useRef()
  const [visible, setVisible] = useState(false)

  useClickOutside({
    ref,
    hide: useCallback(() => {
      setVisible(false)
    }, []),
    visible
  })

  return {
    ref,
    visible,
    toggle: useCallback(() => {
      setVisible(!visible)
    }, [visible])
  }
}

const Input = styled(({ className }) => {
  const { replacedField } = useSelectContext()
  return (
    <input
      readOnly
      className={className}
      tabIndex="-1"
      {...omit(['error', 'success', 'onBlur', 'onFocus'], replacedField)}
    />
  )
})`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0 !important;
  width: 0;
  overflow: hidden;
`

export const InputVariousSelect = styled(
  ({
    className,
    multipliePrefix,
    optionType,
    options,
    fullScreenDropdown,
    fullScreenDropdownTitle,
    dropdownLoading,
    dropdownScrollBehaviorMode,
    searchMode,
    virtual,
    size,
    showArrow = true,
    searchFilter,
    SearchInputComponent = null,
    refSearchInput,
    NotFoundText = 'Ничего не найдено',
    ...field
  }) => {
    const { ref, toggle, visible } = useDropdownVisible()
    return (
      <div ref={ref} className={className}>
        <SelectProvider
          NotFoundText={NotFoundText}
          dropdownLoading={dropdownLoading}
          dropdownScrollBehaviorMode={dropdownScrollBehaviorMode}
          fullScreenDropdown={fullScreenDropdown}
          fullScreenDropdownTitle={fullScreenDropdownTitle}
          multipliePrefix={multipliePrefix}
          optionType={optionType}
          options={options}
          searchFilter={searchFilter}
          searchMode={searchMode}
          size={size}
          virtual={virtual}
          {...field}
        >
          <SearchInput
            ref={refSearchInput}
            SearchInputComponent={SearchInputComponent}
            focus={visible}
            searchMode={searchMode}
            toggle={toggle}
          />
          <Input />
          {visible && (
            <Dropdown fullScreenDropdown={fullScreenDropdown} toggle={toggle} />
          )}
          {showArrow && <Arrow disabled={field.disabled} visible={visible} />}
        </SelectProvider>
      </div>
    )
  }
)`
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;

  ${Arrow} {
    position: absolute;
    right: 10px;
    bottom: 10px;
    transition: all 0.33s ease;
    z-index: 1;
    color: ${color.formItem.select.arrow.color};
  }

  ${Dropdown} {
    position: absolute;
    width: 100%;
    margin-top: 4px;
  }

  &:not(:focus-within):not(:-webkit-autofill):not(:-webkit-autofill:hover):not(
      :-webkit-autofill:focus
    ):not(:active) {
    &::before {
      content: '';
      cursor: pointer;
      pointer-events: none;
      position: absolute;
      right: ${({ size }) => (size === 'large' ? '32px' : '24px')};
      z-index: 1;
      width: 2ch;
      display: block;
      top: 0;
      bottom: 0;
      margin: auto;
      height: calc(100% - 4px);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0.6699054621848739) 100%
      );
    }

    ${({ disabled }) =>
      disabled &&
      css`
        &::before {
          display: none;
        }
      `}
  }

  ${errorCSS}
  ${sizeCSS}
`
