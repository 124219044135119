import { useCallback, useState } from 'react'

const useTogglePopup = (defaultValue = false) => {
  const [isShowing, setIsShowing] = useState(defaultValue)
  const [isVisible, setIsVisible] = useState(defaultValue)

  const tglPopup = useCallback(() => {
    if (isShowing) {
      setIsVisible(false)
      setTimeout(() => {
        setIsShowing(false)
      }, 300)
    } else {
      setIsShowing(true)
      setTimeout(() => {
        setIsVisible(true)
      }, 0)
    }
  }, [isShowing])

  return {
    tglPopup,
    isShowing,
    isVisible
  }
}

export default useTogglePopup
