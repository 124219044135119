import { MouseEventHandler, useCallback, useState } from 'react'

interface Props {
  alwaysShow?: boolean
}

export const useTooltip = ({ alwaysShow }: Props) => {
  const [visible, setVisible] = useState(alwaysShow ?? false)
  const [DOMNodeParameters, setDOMNodeParameters] = useState<DOMRect>()

  const onMouseOver: MouseEventHandler = useCallback((event) => {
    const rect = (event.target as HTMLElement).getBoundingClientRect()

    setDOMNodeParameters(rect)
    setVisible(true)
  }, [])
  const onMouseLeave: MouseEventHandler = useCallback(() => {
    !alwaysShow && setVisible(false)
  }, [alwaysShow])

  return {
    visible,
    onMouseOver,
    onMouseLeave,
    DOMNodeParameters
  }
}
