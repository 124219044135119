import React, { MouseEventHandler, ReactElement, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Popup, PopupContent } from './Popup'
import { TooltipProps } from './index.types'
import { isBrowser } from '../../atoms/isBrowser'
import { isMobile } from '../../atoms/isMobile'
import { useTooltip } from './useTooltip'

interface useReplaceChildrenProps {
  children: ReactElement
  onMouseOver: MouseEventHandler
  onMouseLeave: MouseEventHandler
}

const useReplaceChildren = ({
  children,
  onMouseOver,
  onMouseLeave
}: useReplaceChildrenProps) =>
  useMemo(
    () => ({
      ...children,
      props: {
        ...children.props,
        onMouseOver,
        onMouseLeave,
        onFocus: onMouseOver,
        onBlur: onMouseLeave
      }
    }),
    [children]
  )

export const Tooltip = styled(
  ({
    tooltipClassName,
    children,
    content,
    alwaysShow,
    fixedPlacement,
    id
  }: TooltipProps) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const { visible, onMouseOver, onMouseLeave, DOMNodeParameters } =
      useTooltip({
        alwaysShow
      })
    const Children = useReplaceChildren({ children, onMouseOver, onMouseLeave })
    return (
      <>
        {Children}
        {isBrowser && visible && (
          <Popup
            ref={ref}
            DOMNodeParameters={DOMNodeParameters}
            content={content}
            fixedPlacement={fixedPlacement}
            id={id}
            tooltipClassName={tooltipClassName}
          />
        )}
      </>
    )
  }
)`
  position: relative;
  width: fit-content;
  height: 100%;
  box-sizing: border-box;
  display: block;

  ${() =>
    isMobile &&
    css`
      & ${PopupContent} {
        display: none;
      }
    `}
`
