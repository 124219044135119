import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useWeekDatePickerContext } from './WeekDatePickerProvider'

interface useWeekNavigationInput {
  onWeekChange?: (date: dayjs.Dayjs) => void
}

export const useWeeksNavigation = ({
  onWeekChange
}: useWeekNavigationInput) => {
  const { state, setState } = useWeekDatePickerContext()
  const prevWeek = useCallback(() => {
    const prevWeekState = state.subtract(1, 'week')
    typeof onWeekChange === 'function' && onWeekChange(prevWeekState)
    setState(prevWeekState)
  }, [state, onWeekChange])
  const nextWeek = useCallback(() => {
    const nextWeekState = state.add(1, 'week')
    typeof onWeekChange === 'function' && onWeekChange(nextWeekState)
    setState(nextWeekState)
  }, [state, onWeekChange])

  return {
    prevWeek,
    nextWeek
  }
}
