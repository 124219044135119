import { useCallback, useEffect } from 'react'
import { useLocation } from '@reach/router'

export const Event = (event, action, label) => {
  Array.isArray(window?.dataLayer) &&
    window.dataLayer.push({ event, action, label })
}
// todo: add sentry check

Event.TariffToggler = (label) =>
  Event(
    'TariffToggler',
    'click',
    {
      one: 'oneToOne',
      family: 'family',
      baby: 'child',
      write: 'chat',
      sync: 'syncChat',
      async: 'asyncChat'
    }[label]
  )

Event.NewOpenForm = (who, how) =>
  Event(
    'NewOpenForm',
    'click',
    {
      one: 'oneOnOneT',
      family: 'familyT',
      baby: 'childT',
      write: 'chatT',
      sync: 'syncChatT',
      async: 'asyncChatT'
    }[who === 'write' ? how : who]
  )

Event.Info = (who, how) =>
  Event(
    'Info',
    'click',
    {
      one: 'oneOnOne',
      family: 'family',
      baby: 'child',
      sync: 'syncChat',
      async: 'asyncChat'
    }[who === 'write' ? how : who]
  )

Event.OpenFormCertificate = () =>
  Event('openFormCertificate', 'click', 'mainPage')

Event.NewOpenFormFeedback = (label) =>
  Event('NewOpenFormFeedback', 'click', label)

Event.SubmitFormFeedback = () => Event('SubmitFormFeedback', 'show', 'aboutUs')

Event.SendFormCertificate = (label) =>
  Event('sendFormCertificate', 'click', label)

Event.TariffInfo = (label) => Event('TariffInfo', 'click', label)

Event.NewOpenFormB2B = (label) => Event('NewOpenFormB2B', 'click', label)

export const submitForm = (label) => Event('SubmitForm', 'click', label)

export const useCallbackEvent = (...args) =>
  // eslint-disable-next-line
  useCallback(() => Event(...args), args)

export const useEffectEvent = (...args) =>
  useEffect(() => {
    Event(...args)
    // eslint-disable-next-line
  }, args)

export const useNewOpenForm = (label) =>
  useCallbackEvent('NewOpenForm', 'click', label)

export const useSubmitForm = (label) =>
  useEffectEvent('SubmitForm', 'show', label)

export const useProgressScreen = () =>
  useEffectEvent('progress_screen', 'visit')

export const useWizardWelcomeScreen = () => useEffectEvent('welcome', 'visit')

export const useSubmitRequestForm = () =>
  useEffectEvent('catalogSendForm', 'popup', 'success')

export const useNewMediaPartners = () =>
  useCallbackEvent('NewMediaPartners', 'click', '—')

export const useOpenSocial = (label) =>
  useCallbackEvent('OpenSocial', 'click', label)

export const useOpenPodcast = (label) =>
  useCallbackEvent(
    'OpenPodcast',
    'click',
    {
      '180 градусов': 'gradus',
      'Тёмная материя': 'darkMatter',
      'Предприни-мательницы': 'entrepreneur',
      'Лена Дегтярь': 'lena',
      'На Белой площади': 'whiteSquare',
      НОРМ: 'norm'
    }[label]
  )

export const useOpenProject = (label) =>
  useCallbackEvent('OpenProject', 'click', label)

export const useOpenAward = (label) =>
  useCallbackEvent('OpenAward', 'click', label)

export const useTariffQuestion = (label) =>
  useCallbackEvent('TariffQuestion', 'click', label)

export const useQuestion = ({ event, label }) =>
  useCallbackEvent(event ?? 'Question', 'click', label)

export const useLocationEffect = (title) => {
  const { pathname, search } = useLocation()

  useEffect(() => {
    window.requestIdleCallback(() =>
      // todo: add sentry check
      window?.dataLayer?.push({
        event: 'Pageview',
        pagePath: `${pathname}${search}`,
        pageTitle: title
      })
    )
  }, [])
}

Event.NewSubmitFormB2B = () => Event('NewSubmitFormB2B', 'show', 'application')
Event.NewSubmitFormJob = () =>
  Event('NewSubmitFormJob', 'show', 'jobApplication')
Event.NewsletterSubcribe = () => Event('NewsletterSubcribe', 'click', 'news')
Event.OpenLink = (label) => Event('OpenLink', 'click', label)
Event.NewOpenFormJob = (label) => Event('NewOpenFormJob', 'click', label)
Event.SeeAlso = (category) => Event(category, 'click', 'seeAlso')
Event.OpenMaterials = () => Event('OpenMaterials', 'click', 'header')

export const pressSendForm = (event, label) => Event(event, 'click', label)

Event.BypassPopup = () => Event('BypassPopup', 'show', 'anketa')
Event.BypassPress = (target) => {
  if (window.location.pathname !== '/form') {
    return
  }
  Event(`Bypass${target}`, 'click', 'anketa')
}
Event.SelectionOnNavigatePage = (event) => Event(event, 'click', 'navigatePage')
Event.ChangePageInCatalog = (label) => Event('openPage', 'click', label)
Event.OpenPsychologistProfile = (label) => Event('openProfile', 'click', label)
Event.PsychologistProfileActions = (event, label) =>
  Event(event, 'click', label)
Event.ResetCatalogFilters = (label) => Event('clearFilter', 'click', label)

Event.NeedHelpWizardClick = (event) => Event(event, 'click', 'sendHelp')
Event.PreviousStepWizardClick = (label) => Event('previousStep', 'click', label)

export const useEffectWizardVisitEvent = (event, label) => {
  useEffect(() => {
    window.requestIdleCallback(() => Event(event, 'visit', label ?? event))
  }, [])
}

export const wizardSendForm = (event, label) => Event(event, 'click', label)

Event.WizardMiniCatalogClick = (label) => Event('listingOK', 'click', label)

Event.OpenCertificateForm = (label) =>
  Event('openCertificateForm', 'click', label)

Event.CopyCertificateLink = () =>
  Event('copyCertificateLink', 'click', 'copyCertificateLink')

Event.GetCertificateHelp = (label) =>
  Event('getCertificateHelp', 'click', label)
Event.SavePsychologist = (label) => Event('savePsychologist', 'click', label)
Event.SendSavedPsychologist = (label) =>
  Event('sendSavedPsychologist', 'click', label)

Event.UnsavePsychologist = (label) =>
  Event('unsavePsychologist', 'click', label)

Event.OpenopenGiftup = (label) => Event('openGiftup', 'click', label)

Event.getGuide = (event, label) => Event('getGuide', event, label)

Event.anxietyTest = (eventAction, label) =>
  Event('anxietyTest', eventAction, label)

Event.openCatalog = (label) => Event('openCatalog', 'click', label)

Event.openForm = (label) => Event('openForm', 'click', label)

Event.openNavigate = (label) => Event('openNavigate', 'click', label)

Event.getPsychologist = (label) => Event('getPsychologist', 'click', label)

Event.pressYesImLucky = () => Event('feelingLucky', 'click', 'yes')

Event.relationshipsPopupClicks = (eventName) =>
  Event(eventName, 'click', 'gh-trudnosti')

Event.showAgeWarningInForm = () => Event('ageWarning', 'show', 'growthHacking')

Event.openWizardCalendar = (label) =>
  Event('wizard_calendar_open', 'click', label)

Event.bookWizardCalendar = (label) =>
  Event('wizard_calendar_book', 'click', label)

Event.visitRegistration = (label) => Event('registration', 'visit', label)

Event.submitLeadCreate = (label) => Event('lead_create', 'submit', label)

Event.registrationOk = (label) => Event('registration_ok', 'submit', label)

Event.openSemeinyiPageFirstScreen = () =>
  Event('NewOpenForm', 'click', 'SemeinyiPageFirstScreen')

export const less18Screen = () => Event('less18_screen', 'click')

export const accountExist = () => Event('account_exist', 'click')

export const usePsychologistApplication = () =>
  useEffectEvent('psychologistApplication', 'visit')

export const usePsychologistProfile = (label) =>
  useEffectEvent('psychologistProfile', 'visit', label)

export const trackVisitForm = (label) => {
  Event('visitForm', 'visit', label)
}
