import React, { useCallback, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { ClearButton } from './ClearButton'
import { Montserrat } from '../../../constants'
import { color } from '../../../colors'
import { useReplacedField } from '../useReplacedField'

const errorCSS = ({ error }) =>
  error &&
  css`
    border-color: ${color.formItem.border.error};

    &:hover,
    &:active {
      border-color: ${color.formItem.border.error};
    }

    &:hover {
      background: ${color.formItem.background.errorHover};
    }

    &:focus {
      border-color: ${color.formItem.border.error};
    }
  `

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      & textarea {
        padding: 10px;
      }

      & ${ClearButton} {
        right: 10px;
        top: 10px;
      }
    `,
    large: css`
      & textarea {
        padding: 14px;
      }

      & ${ClearButton} {
        right: 14px;
        top: 14px;
      }
    `
  }[size])

const Counter = styled.span`
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${color.text.greyDark};
  width: 100%;
  text-align: right;
`

export const TextArea = styled(
  ({
    className,
    countCharacters = {
      visible: false,
      max: 1000
    },
    ...field
  }) => {
    const ref = useRef(null)
    const replacedField = useReplacedField(field)

    const onClear = useCallback(() => {
      if (ref.current) {
        ref.current.focus()
      }
      replacedField.onChange('')
    }, [replacedField.onChange])

    const counter = useMemo(
      () => [replacedField.value?.length ?? 0, countCharacters.max].join('/'),
      [replacedField.value?.length, countCharacters.max]
    )

    const maxLengthProps = useMemo(
      () =>
        countCharacters.max && countCharacters.visible
          ? {
              maxLength: countCharacters.max
            }
          : {},
      [countCharacters]
    )

    return (
      <div className={className}>
        <textarea ref={ref} {...maxLengthProps} {...replacedField} />
        {replacedField.value && (
          <ClearButton disabled={replacedField.disabled} onClick={onClear} />
        )}
        {countCharacters.visible && <Counter>{counter}</Counter>}
      </div>
    )
  }
)`
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  width: 100%;

  & ${ClearButton} {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  & textarea {
    min-height: 174px;
    font-family: ${Montserrat};
    height: inherit;
    width: 100%;
    resize: vertical;
    padding: 10px;
    padding-right: 36px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${color.formItem.text.default};
    border-radius: 4px;
    outline: none;
    border: 1px solid ${color.formItem.border.default};

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      caret-color: ${color.formItem.text.default} !important;
      background-color: ${color.background} !important;
      color: ${color.formItem.text.default} !important;
      -webkit-text-fill-color: ${color.formItem.text.default};
      box-shadow: 0 0 0 174px inset ${color.background} !important;
      -webkit-box-shadow: 0 0 0 174px inset ${color.background} !important;
    }

    &:hover {
      border-color: ${color.formItem.border.hover};
    }

    &:focus {
      border-color: ${color.formItem.border.focus};
    }

    &::placeholder {
      color: ${color.formItem.placeholder};
    }

    &:disabled {
      background: ${color.formItem.background.disabled};
      border-color: ${color.formItem.background.disabled};
    }

    &::-webkit-scrollbar {
      background: transparent;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${color.formItem.scrollbar};
      border-radius: 3px;
    }

    ${errorCSS}
  }
  ${sizeCSS}
`
