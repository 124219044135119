export const color = {
  border: '#C1C5CD',
  borderCard: '#E6E6E6',
  background: '#fff',
  text: {
    dark: '#333333',
    white: '#fff',
    pimaryBlue: '#2963A3',
    greyDark: '#838383'
  },
  formItem: {
    icon: {
      default: '#9B9B9B',
      disabled: '#C2C2C2'
    },
    label: {
      disabled: '#C2C2C2'
    },
    required: '#FF162E',
    placeholder: '#9B9B9B',
    select: {
      arrow: {
        color: '#9B9B9B'
      },
      option: {
        description: '#838383'
      },
      dropdown: {
        hover: '#EFF5FB',
        press: ' #E9F2FD',
        info: '#9B9B9B'
      }
    },
    miniRadio: {
      checked: '#35B3A9',
      hover: '#03A094',
      press: '#039085',
      noCheckedPress: '#E3F5F5'
    },
    border: {
      default: '#9B9B9B',
      hover: '#838383',
      focus: '#35B3A9',
      focusVisible: '#1F1F1F',
      error: '#FF162E'
    },
    background: {
      disabled: '#F8F9F9',
      errorHover: '#FAEEF0'
    },
    text: {
      default: '#1F1F1F',
      error: '#FF162E',
      success: '#35B3A9',
      disabled: '#C2C2C2',
      valueDisabled: '#9B9B9B'
    },
    checkbox: {
      border: '#c1c5cd',
      disabled: '#C2C2C2'
    },
    scrollbar: '#9d9d9d'
  },
  link: {
    default: '#008AE9',
    disabled: '#C2C2C2'
  },
  button: {
    giftery: '#35B3A9',
    primary: {
      default: '#35B3A9',
      hover: '#03A094',
      press: '#028076'
    },
    grey: {
      border: '#eff5fb',
      hover: '#e9f2fd',
      press: '#dfebf9'
    },
    greyBordered: {
      border: '#C2C2C2',
      default: '#fff',
      hover: '#eff5fb',
      press: '#E9F2FD'
    },
    outline: {
      content: '#35B3A9',
      border: '#35B3A9',
      hover: '#F9FDFD',
      press: '#E3F5F5'
    },
    noBordersBlack: {
      hover: '#03A094',
      press: '#028076',
      loading: '#35B3A9'
    },
    background: {
      default: '#fff',
      disabled: '#F8F9F9'
    },
    text: {
      default: '#333333',
      disabled: '#C2C2C2'
    }
  },
  datePicker: {
    navigateButton: {
      hover: '#EFF5FB',
      press: '#E9F2FD',
      disabled: '#DADADA'
    },
    date: {
      active: '#35b3a9',
      hover: '#e3f5f5'
    },
    header: {
      default: '#838383'
    },
    day: {
      disabled: '#DADADA'
    }
  }
} as const
