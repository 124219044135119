import dayjs from 'dayjs'

export const getCurrentWeek = (date: dayjs.Dayjs) => {
  const days: dayjs.Dayjs[] = []

  for (let day = 0; day <= 6; day++) {
    const currWeek = date.startOf('week')
    days.push(currWeek.add(day, 'day'))
  }

  return days
}
