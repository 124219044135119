import React, {
  ReactNode,
  RefObject,
  forwardRef,
  useEffect,
  useState
} from 'react'
import styled, { StyledComponent, css } from 'styled-components'
import { Placement } from './index.types'
import { Text } from '../../atoms/Text'
import { createPortal } from 'react-dom'
import { isBrowser } from '../../atoms/isBrowser'
import { isEmpty } from 'ramda'

const PopupText = styled(Text.Small)`
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  white-space: pre;
`

interface PopupContentProps {
  visible?: boolean
  tooltipWidth?: number
  tooltipHeight?: number
  placement?: Placement
  position?: Placement
  DOMNodeParameters?: DOMRect
  opacity?: number
  ref: any
  children: ReactNode
  className?: string
  id?: string
}

const bottomRightCSS = ({
  DOMNodeParameters,
  position
}: {
  DOMNodeParameters?: DOMRect
  position?: Placement
}) => {
  if (!DOMNodeParameters) return

  const yPos = DOMNodeParameters?.top + DOMNodeParameters?.height + 16 + 'px'
  const xPos = DOMNodeParameters?.left + 'px'

  return (
    position === 'bottom-right' &&
    css`
      transform: translate(${xPos}, ${yPos});

      &::after {
        left: 6px;
      }
    `
  )
}

const topRightCSS = ({
  DOMNodeParameters,
  position,
  tooltipHeight
}: {
  DOMNodeParameters?: DOMRect
  position?: Placement
  tooltipHeight?: number
}) => {
  if (!DOMNodeParameters || !tooltipHeight) return

  const yPos = DOMNodeParameters?.top - tooltipHeight - 16 + 'px'
  const xPos = DOMNodeParameters?.left + 'px'
  const arrowYPos = `${tooltipHeight * -1}px`
  return (
    position === 'top-right' &&
    css`
      transform: translate(${xPos}, ${yPos});

      &::after {
        left: 6px;
        transform: scale(-1) translateY(calc(${arrowYPos}));
      }
    `
  )
}

const bottomLeftCSS = ({
  DOMNodeParameters,
  position,
  tooltipWidth
}: {
  DOMNodeParameters?: DOMRect
  position?: Placement
  tooltipWidth?: number
}) => {
  if (!DOMNodeParameters || !tooltipWidth) return

  const yPos = DOMNodeParameters?.top + DOMNodeParameters?.height + 16 + 'px'
  const xPos = DOMNodeParameters?.left - tooltipWidth + 22 + 'px'

  return (
    position === 'bottom-left' &&
    css`
      transform: translate(${xPos}, ${yPos});

      &::after {
        right: 6px;
      }
    `
  )
}

const topLeftCSS = ({
  DOMNodeParameters,
  position,
  tooltipWidth,
  tooltipHeight
}: {
  DOMNodeParameters?: DOMRect
  position?: Placement
  tooltipWidth?: number
  tooltipHeight?: number
}) => {
  if (!DOMNodeParameters || !tooltipHeight || !tooltipWidth) return

  const yPos = DOMNodeParameters?.top - tooltipHeight - 16 + 'px'
  const xPos = DOMNodeParameters?.left - tooltipWidth + 22 + 'px'
  const arrowYPos = `${tooltipHeight * -1}px`
  return (
    position === 'top-left' &&
    css`
      transform: translate(${xPos}, ${yPos});

      &::after {
        right: 6px;
        transform: scale(-1) translateY(calc(${arrowYPos}));
      }
    `
  )
}

export const PopupContent: StyledComponent<
  // eslint-disable-next-line no-undef, no-unused-vars
  (props: PopupContentProps) => JSX.Element,
  any,
  PopupContentProps,
  never
> = styled.div`
  & {
    z-index: 50;
    min-width: fit-content;
    position: fixed;
    color: #fff;
    background: #011632;
    padding: 8px;
    border-radius: 4px;
    text-align: left;
    display: inline-block;
    opacity: ${(props: PopupContentProps) => (props?.position ? 1 : 0)};

    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    z-index: 100;
    position: absolute;
    top: 0;
    border-style: solid;
    border-color: transparent transparent #011632 transparent;
    border-width: 0 5px 6px 5px;
    transform: translateY(-100%);
  }

  ${bottomRightCSS}
  ${bottomLeftCSS}
  ${topLeftCSS}
  ${topRightCSS}
`

interface PopupProps {
  content: ReactNode
  DOMNodeParameters?: DOMRect
  fixedPlacement?: Placement
  tooltipClassName?: string
  id?: string
}

const getTooltipParameters = (ref: any) =>
  (ref as RefObject<HTMLElement | null>)?.current
    ? {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
      }
    : {}

const getHeight = () => {
  if (!isBrowser) {
    return 0
  }
  const body = window.document.body
  const html = window.document.documentElement

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
}

const getWidth = () => {
  if (!isBrowser) {
    return 0
  }
  const body = window.document.body
  const html = window.document.documentElement

  return Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth
  )
}

type usePositionProps = Pick<
  PopupProps,
  'fixedPlacement' | 'DOMNodeParameters'
> & {
  ref: any
}

const usePosition = ({
  fixedPlacement,
  DOMNodeParameters,
  ref
}: usePositionProps) => {
  const [position, setPosition] = useState<Placement | undefined>()
  useEffect(() => {
    const tooltip = getTooltipParameters(ref)
    if (!DOMNodeParameters || isEmpty(tooltip)) return

    const tooltipHeight = tooltip.height!
    const tooltipWidth = tooltip.width!

    if (DOMNodeParameters.bottom + tooltipHeight + 16 < getHeight()) {
      tooltipWidth + DOMNodeParameters.right > getWidth()
        ? setPosition(fixedPlacement ?? 'bottom-left')
        : setPosition(fixedPlacement ?? 'bottom-right')
      return
    }
    tooltipWidth + DOMNodeParameters.right > getWidth()
      ? setPosition(fixedPlacement ?? 'top-left')
      : setPosition(fixedPlacement ?? 'top-right')
  }, [DOMNodeParameters, fixedPlacement])

  return position
}

export const Popup = forwardRef(
  (
    {
      content,
      DOMNodeParameters,
      fixedPlacement,
      tooltipClassName,
      id
    }: PopupProps,
    ref
  ) => {
    const position = usePosition({
      fixedPlacement,
      DOMNodeParameters,
      ref
    } as usePositionProps)
    return isBrowser ? (
      createPortal(
        <PopupContent
          ref={ref}
          DOMNodeParameters={DOMNodeParameters}
          className={tooltipClassName}
          id={id}
          position={position}
          tooltipHeight={getTooltipParameters(ref).height}
          tooltipWidth={getTooltipParameters(ref).width}
        >
          <PopupText>{content}</PopupText>
        </PopupContent>,
        window.document.body
      )
    ) : (
      <></>
    )
  }
)
