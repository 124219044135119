import React, { useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import styled, { css } from 'styled-components'
import { ButtonGroup } from './ButtonGroup'
import { CalendarView } from './CalendarView'
import { Days, DaysGrid } from './Days'
import { FieldProps } from '../../atoms/FormItems/index.field.types'
import { Header } from './Header'
import { Weekdays } from './Weekdays'
import { color } from '../../colors'
import { isBrowser } from '../../atoms/isBrowser'
import { size } from '../../constants'
import { useDatePickerContext } from './DatePickerProvider'

const fullScreenCSS = ({ fullScreen }: { fullScreen?: boolean }) =>
  fullScreen &&
  css`
    &&& {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      border-radius: 0;

      ${Days}, ${Weekdays} {
        width: 80%;
        max-width: 332px;
        margin: 0 auto;

        @media (max-width: ${size.xs}) {
          width: 85%;
        }
      }

      ${DaysGrid}, ${Weekdays} {
        gap: 18px;

        @media (max-width: ${size.xs}) {
          gap: 3%;
        }
      }

      ${Weekdays} {
        margin-bottom: 32px;
      }

      ${Header} {
        border-bottom: 1px solid ${color.border};
        padding: 16px;
        padding-top: 14px;
      }

      ${ButtonGroup} {
        position: absolute;
        bottom: 0;
        z-index: 10;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        box-shadow: 3px -3px 6px rgba(25, 28, 31, 0.06),
          3px -1px 2px rgba(25, 28, 31, 0.06),
          3px 1px 0px rgba(25, 28, 31, 0.04);

        & button {
          flex: 0 0 50%;
          height: 48px;
        }
      }
    }
  `

const usePosition = (fullScreen?: boolean) => {
  const ref = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (fullScreen) {
      return
    }
    if (isBrowser && ref.current && !ref.current.style.top) {
      const rootHeight = window.innerHeight
      const bottomPosition = ref.current.getBoundingClientRect().bottom
      const datePickerHeight = ref.current.clientHeight
      bottomPosition > rootHeight
        ? (ref.current.style.top = `-${datePickerHeight + 4}px`)
        : (ref.current.style.marginTop = `4px`)
    }
  }, [])

  return { ref }
}

export interface DatePickerProps {
  className?: string
  disabledDates?: dayjs.Dayjs[]
  disabledCondition?: (date: dayjs.Dayjs) => boolean
  disabledNextMonthCondition?: (date: dayjs.Dayjs) => boolean
  disabledPrevMonthCondition?: (date: dayjs.Dayjs) => boolean
  onCancel?: () => void
  onSelect?: () => void
  toggle?: () => void
  fullScreen?: boolean
  autoRange?: number
  field: FieldProps<dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs], HTMLInputElement>
}

export const DatePicker = styled(
  ({
    className,
    disabledDates,
    disabledCondition,
    disabledNextMonthCondition,
    disabledPrevMonthCondition,
    onCancel,
    onSelect,
    autoRange,
    fullScreen,
    toggle,
    field
  }: DatePickerProps) => {
    const { ref } = usePosition(fullScreen)
    const datePicker = useDatePickerContext()
    return (
      <div ref={ref} className={className}>
        <CalendarView
          autoRange={autoRange}
          disabledCondition={disabledCondition}
          disabledDates={disabledDates}
          disabledNextMonthCondition={disabledNextMonthCondition}
          disabledPrevMonthCondition={disabledPrevMonthCondition}
          fullScreen={fullScreen}
          {...field}
        />
        <ButtonGroup
          {...field}
          autoRange={autoRange}
          onCancel={onCancel}
          onSelect={onSelect}
          selectedDate={datePicker.selectedDate}
          toggle={toggle}
        />
      </div>
    )
  }
)`
  width: 304px;
  padding: 20px 16px 16px 16px;
  background: ${color.background};
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));

  ${fullScreenCSS}
`
