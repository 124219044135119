import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { ClearButton as DefaultClearButton } from './ClearButton'
import { SearchIcon } from './SearchIcon'
import { inputCSS } from './styles'
import { isEmptyInputValue } from './utils'
import { omit } from 'rambda'
import { useReplacedField } from '../useReplacedField'

export const Input = styled(
  ({ className, clearCondition = true, searchMode, ...field }) => {
    const ref = useRef()
    const replacedField = useReplacedField(field)
    const onClear = useCallback(() => {
      ref.current.focus()
      replacedField.onChange('')
    }, [field.onChange])

    return (
      <div className={className}>
        {searchMode && <SearchIcon disabled={replacedField.disabled} />}
        <input
          ref={ref}
          {...omit(['error', 'success', 'searchMode'], replacedField)}
        />
        {isEmptyInputValue(replacedField.value) && clearCondition && (
          <DefaultClearButton
            disabled={replacedField.disabled}
            onClick={onClear}
          />
        )}
      </div>
    )
  }
)`
  ${inputCSS}
`
