import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.button`
  background-color: #ffffff;
  border: none;
  padding: 10px;
  display: block;
  width: 100%;
  color: #333333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  &:hover {
    background-color: #eff5fb;
  }
`

type DropdownItemProps = {
  onClick: () => void
  label: string
}
export const DropdownItem: FC<DropdownItemProps> = ({ onClick, label }) => (
  <Container onClick={onClick} type="button">
    {label}
  </Container>
)
