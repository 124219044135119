import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { LopeIcon } from './LopeIcon'
import { inputCSS } from './styles'
import { omit } from 'rambda'
import { useReplacedField } from '../useReplacedField'

export const InputSearch = styled(({ className, initialFocus, ...field }) => {
  const ref = useRef()
  const replacedField = useReplacedField(field)

  useEffect(() => {
    if (initialFocus) {
      ref.current.focus()
    }
  }, [])

  return (
    <div className={className}>
      <input ref={ref} {...omit(['error', 'success'], replacedField)} />
      <LopeIcon disabled={field.disabled} />
    </div>
  )
})`
  ${inputCSS}
`
