import Icons from '../../atoms/Icons/index.library'
import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { color } from '../../colors'
import { datePickerTextCSS } from './datePickerTextCSS'
import { size } from '../../constants'

const NavigateButton = styled(({ className, onClick, disabled, children }) => (
  <button
    className={className}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
))`
  width: 24px;
  height: 24px;
  outline: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 4px;

  &:hover {
    background: ${color.datePicker.navigateButton.hover};
  }

  &:active {
    background: ${color.datePicker.navigateButton.press};
  }

  &:disabled {
    pointer-events: none;
    color: ${color.datePicker.navigateButton.disabled};
  }

  & svg {
    width: 18px;
    height: 18px;
    overflow: visible;
  }
`

export const Title = styled.span`
  text-transform: capitalize;
  ${datePickerTextCSS};
  font-weight: 700;
`

interface CheckIsDisabledProps {
  date: dayjs.Dayjs
  disabledCheckFunction: (date: dayjs.Dayjs) => boolean
}

const checkIsDisabled = ({
  date,
  disabledCheckFunction
}: CheckIsDisabledProps) => {
  if (typeof disabledCheckFunction !== 'function') {
    return false
  }

  return disabledCheckFunction(date)
}

interface useMonthsNavigationInput {
  state: dayjs.Dayjs
  setState: (date: dayjs.Dayjs) => void
  onMonthChange: (date: dayjs.Dayjs) => void
}

const useMonthsNavigation = ({
  state,
  setState,
  onMonthChange
}: useMonthsNavigationInput) => {
  const prevMonth = useCallback(() => {
    const prevMonth = state.subtract(1, 'month')
    typeof onMonthChange === 'function' && onMonthChange(prevMonth)
    setState(prevMonth)
  }, [state, onMonthChange])
  const nextMonth = useCallback(() => {
    const nextMonth = state.add(1, 'month')
    typeof onMonthChange === 'function' && onMonthChange(nextMonth)
    setState(nextMonth)
  }, [state, onMonthChange])

  return {
    prevMonth,
    nextMonth
  }
}

export const Header = styled(
  ({
    className,
    disabledNextMonthCondition,
    disabledPrevMonthCondition,
    onMonthChange,
    state,
    setState
  }) => {
    const { prevMonth, nextMonth } = useMonthsNavigation({
      state,
      setState,
      onMonthChange
    })
    return (
      <div className={className}>
        <NavigateButton
          disabled={checkIsDisabled({
            date: state,
            disabledCheckFunction: disabledPrevMonthCondition
          })}
          onClick={prevMonth}
        >
          <Icons.IconArrowLeft />
        </NavigateButton>
        <Title>{state.format('MMMM, YYYY')}</Title>
        <NavigateButton
          disabled={checkIsDisabled({
            date: state,
            disabledCheckFunction: disabledNextMonthCondition
          })}
          onClick={nextMonth}
        >
          <Icons.IconArrowRight />
        </NavigateButton>
      </div>
    )
  }
)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
  margin-bottom: 16px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 32px;
  }
`
