/* eslint-disable */
import { Tooltip } from '.'
import { TooltipProps } from './index.types'
import { StyledComponent } from 'styled-components'

export default Tooltip as unknown as StyledComponent<
  (props: TooltipProps) => JSX.Element,
  any,
  TooltipProps,
  never
>
