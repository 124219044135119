import arrowSlimSVG, {
  ReactComponent as ArrowSlimSVG
} from './img/arrow-slim.svg'
import styled from 'styled-components'
import { ReactComponent as ArrowSVG } from './img/arrow.svg'

export const Arrow = styled(ArrowSVG)``
export const ArrowSlim = styled(ArrowSlimSVG)``

export const arrowSlimResource = arrowSlimSVG
