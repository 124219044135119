import Button from '../../atoms/Button/index.library'
import Icons from '../../atoms/Icons/index.library'
import React from 'react'
import Text from '../../atoms/Text/index.library'
import styled, { css } from 'styled-components'
import { color } from '../../colors'

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: ${color.background};
  border: none;
`

const ButtonNoBordersBlack = styled(Button.NoBordersBlack)<{ withText: true }>`
  height: 24px;
  padding: 0;

  ${({ withText }) =>
    !withText &&
    css`
      width: 24px;
    `}
`

export const BackButton = styled(
  ({ className, onClick, BackButtonIcon, backButtonText }) => (
    <ButtonNoBordersBlack
      className={className}
      {...(backButtonText
        ? {
            BeforeContent: BackButtonIcon,
            children: <Text.Large>{backButtonText}</Text.Large>
          }
        : {
            iconOnly: true,
            Icon: BackButtonIcon,
            children: null
          })}
      onClick={onClick}
      type="button"
      withText={Boolean(backButtonText)}
    />
  )
)`
  ${Text.Large} {
    font-weight: 700;
  }
`

export const CloseButton = styled(({ className, onClick }) => (
  <IconButton
    className={className}
    data-type="modal-close-btn"
    onClick={onClick}
    type="button"
  >
    <Icons.IconCross />
  </IconButton>
))``
