/* eslint-disable */
import { Title } from '.'
import { StyledComponent } from 'styled-components'

export default Title as unknown as {
  [key in keyof typeof Title]: StyledComponent<
    (props: any) => JSX.Element,
    any,
    any,
    never
  >
}
