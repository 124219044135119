import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import cn from 'classnames'
import styled, { css } from 'styled-components'
import { ArrowSlim as Arrow } from './ArrowIcon'
import { Link as GatsbyLink } from 'gatsby'
import { maxWidth } from '../constants'
import { motion } from 'framer-motion'
import { useClickOutside } from './useClickOutside'

const linkStyles = css`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: #333333;
  display: flex;
  white-space: nowrap;
  text-align: left;
`

const linkStylesHover = css`
  &:hover {
    background-color: #f6f6f6;
    color: #03b2a5;
    text-decoration: none;
  }
`

const animationProps = {
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  transition: {
    type: 'spring',
    duration: 0.88
  }
}

const Menu = styled(
  forwardRef(({ className, menuItems = [] }, ref) => (
    <motion.div {...animationProps} ref={ref} className={className}>
      {menuItems.map(({ name, path, type, children, onClick }) => (
        <Link
          children={children}
          key={path}
          name={name}
          onClick={onClick}
          path={path}
          type={type}
        />
      ))}
    </motion.div>
  ))
)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 8px 1px;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #ffffff;
  transform: ${({ xPosition }) => {
    const translateX = xPosition + 6
    if (xPosition) {
      return `translate(${translateX}px, -16px)`
    }

    return `translate(0, 8px)`
  }};

  & a,
  button {
    display: flex;
    padding: 8px 16px;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
  }
`

const Link = styled(({ className, type, path, name, children, onClick }) => {
  const [visible, setVisible] = useState(false)
  const ref = useRef()
  const parentRef = useRef()
  const handleClose = useCallback(() => setVisible(false), [])
  const handleOpen = useCallback(() => setVisible(true), [])
  const [show, setShow] = useState(false)

  useClickOutside({ ref, visible, hide: handleClose })

  useEffect(() => {
    window.requestIdleCallback(() => setShow(visible))
  }, [visible])

  if (type === 'link' || type === 'externalLink') {
    return type === 'link' ? (
      <GatsbyLink className={className} to={path}>
        {name}
      </GatsbyLink>
    ) : (
      <a className={className} href={path}>
        {name}
      </a>
    )
  }

  return (
    <button
      ref={parentRef}
      className={className}
      onClick={onClick ?? handleOpen}
    >
      {name}
      {children && <Arrow />}
      {show && (
        <Menu
          ref={ref}
          menuItems={children}
          xPosition={parentRef?.current?.clientWidth}
        />
      )}
    </button>
  )
})`
  ${linkStyles}
  ${linkStylesHover}

  & ${Arrow} {
    transform: rotate(90deg);
  }
`

export const DropdownMenu = styled(
  ({
    className,
    children: triggeredElement,
    menuItems,
    customClassName,
    onOpen,
    arrowHide
  }) => {
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)
    const ref = useRef()

    const handleOpen = useCallback(() => setVisible(true), [])
    const handleClose = useCallback(() => setVisible(false), [])

    useEffect(() => {
      if (typeof onOpen === 'function' && visible) {
        onOpen()
      }
      window.requestIdleCallback(() => setShow(visible))
    }, [visible])

    useClickOutside({ ref, visible, hide: handleClose })

    return (
      <div
        className={cn(className, customClassName)}
        data-open={show}
        onClick={handleOpen}
      >
        <button>
          {triggeredElement}
          {!arrowHide && <Arrow />}
        </button>
        {show && <Menu ref={ref} menuItems={menuItems} />}
      </div>
    )
  }
)`
  position: relative;
  cursor: pointer;

  & > button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    wrap: nowrap;

    ${Arrow} {
      margin-left: 8px;
      transform: rotate(-180deg);
      height: 14px;
      display: inline-block;
      margin: auto;
      transition: transform 0.2s ease;
    }

    ${linkStyles}
  }

  &:hover > button {
    color: #03b2a5;
    text-decoration: none;
  }

  &[data-open='true'] > button {
    color: #03b2a5;
    text-decoration: none;

    ${Arrow} {
      transform: rotate(0deg);
      transition: transform 0.2s ease;
    }
  }

  a,
  button {
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  & ${Menu} {
    position: absolute;
    left: 0;

    @media (max-width: ${maxWidth.lg}) {
      left: unset;
      right: 0;
    }
  }
`
