import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import dayjs from 'dayjs'
import { getCurrentWeek } from './getCurrentWeek'
import { setTimezone, timezonedDayjs } from '../../../daysjsConfig'
import { useInitialSelectedValue } from '../useInitialSelectedValue'

const WeekDatePickerContext = createContext({})

const getInitialValue = (value?: dayjs.Dayjs | dayjs.Dayjs[]) => {
  if (Array.isArray(value)) {
    const [initialValue] = value
    return initialValue
  }
  return !value ? undefined : value
}

type DatePickerProviderProps = {
  children: ReactNode
  value?: dayjs.Dayjs | [dayjs.Dayjs, dayjs.Dayjs]
  timezone?: string
  initialWeek?: number
  externalState?: dayjs.Dayjs
}

export const WeekDatePickerProvider = ({
  children,
  value,
  timezone,
  initialWeek,
  externalState
}: DatePickerProviderProps) => {
  const withInitWeekValue = useMemo(() => {
    const initValue = getInitialValue(value)
    const week = initialWeek ?? initValue?.isoWeek()

    if (week !== undefined && initValue) {
      return timezonedDayjs(getInitialValue(initValue)).isoWeek(week)
    }
    if (week !== undefined && !initValue) {
      return timezonedDayjs().isoWeek(week)
    }

    return timezonedDayjs()
  }, [initialWeek])

  const [state, setState] = useState(withInitWeekValue)

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | undefined>(
    getInitialValue(value)
  )

  useEffect(() => {
    if (externalState && externalState.isValid()) {
      setState(externalState)
    }
  }, [externalState])
  useLayoutEffect(() => {
    if (timezone) {
      setTimezone(timezone)
    }
  }, [timezone])

  useInitialSelectedValue({
    value: getInitialValue(value) ?? undefined,
    selectedDate,
    setSelectedDate
  })

  const week = useMemo(() => getCurrentWeek(state), [state])

  return (
    <WeekDatePickerContext.Provider
      value={{ week, state, setState, selectedDate, setSelectedDate }}
    >
      {children}
    </WeekDatePickerContext.Provider>
  )
}

type Context = {
  week: dayjs.Dayjs[]
  state: dayjs.Dayjs
  setState: Dispatch<SetStateAction<dayjs.Dayjs>>
  selectedDate?: dayjs.Dayjs
  setSelectedDate: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>
}

export const useWeekDatePickerContext = () =>
  useContext(WeekDatePickerContext) as Context
