import { Text } from '../../Text'
import { color } from '../../../colors'
import { css } from 'styled-components'

const selectDisabledCSS = ({ disabled }) =>
  disabled &&
  css`
    .rc-select-selector {
      opacity: 1 !important;
    }

    .rc-select-selection-placeholder {
      color: ${color.formItem.text.disabled};
    }

    .rc-select-selector,
    .rc-select-selection-placeholder,
    .rc-select-selector:hover {
      border-color: ${color.formItem.background.disabled} !important;
      background: ${color.formItem.background.disabled};
    }

    .rc-select-selection-item {
      color: ${color.formItem.text.disabled};

      div p span span {
        color: ${color.formItem.text.disabled} !important;
      }
    }

    .rc-select-selector:focus-visible {
      border-color: ${color.formItem.background.disabled};
    }

    .rc-select-arrow svg {
      path {
        stroke: ${color.formItem.text.disabled};
      }
    }
  `

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      height: 40px !important;

      .rc-select-selection-search {
        height: 100%;
      }

      .rc-select-selector,
      .rc-select-arrow {
        height: 40px;
      }

      .rc-select-selection-item,
      .rc-select-selection-placeholder {
        height: 40px;
        padding: 10px;
        padding-right: 20px;
      }
    `,
    large: css`
      height: 48px;

      .rc-select-selector,
      .rc-select-arrow {
        height: 48px;
      }

      .rc-select-selection-search {
        height: 100%;
      }

      .rc-select-selection-item,
      .rc-select-selection-placeholder {
        padding: 14px;
        padding-right: 28px;
      }
    `
  }[size])

const selectErrorCSS = ({ error }) =>
  error &&
  css`
    .rc-select-selector {
      border-color: ${color.formItem.border.error} !important;
    }

    .rc-select-selector:hover {
      background: ${color.formItem.background.errorHover};
      border-color: ${color.formItem.border.error} !important;

      .rc-select-selection-placeholder {
        background: ${color.formItem.background.errorHover};
      }
    }

    .rc-select-selector:focus-visible {
      border-color: ${color.formItem.border.error};
    }
  `

export const selectStyles = css`
  cursor: pointer;
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;

  & input,
  input:focus {
    font-size: 16px !important;
  }

  .rc-select-arrow {
    height: 40px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in;
    transform: rotate(0deg);
  }

  .rc-select-arrow svg {
    transition: all 0.3s ease;
  }

  .rc-select-arrow svg[open] {
    transform: rotate(180deg);
  }

  .rc-select-selection-search-input {
    cursor: pointer;
  }

  .rc-select-selector {
    width: 100%;
    border: 1px solid ${color.formItem.border.default} !important;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 !important;
    height: 40px;
    background: #fff;
    position: relative;
  }

  .rc-select-selection-search,
  .rc-select-selection-placeholder,
  .rc-select-selection-item {
    padding: 10px;
    cursor: pointer;
  }

  .rc-select-selection-search {
    top: 0;
    left: 0;
    position: absolute;
    max-height: 100%;
  }

  .rc-select-selection-search input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .rc-select-selection-search-input {
    padding: unset;
  }

  .rc-select-arrow {
    position: absolute;
    top: 0;
    right: 16px;
  }

  .rc-select-selection-placeholder {
    top: 0 !important;
    left: 0 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    opacity: 1;
    color: ${color.formItem.placeholder};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    text-align: start;
    display: flex;
    align-items: center;
    max-height: 100%;
    border-radius: 4px;
  }

  .rc-select-selection-item {
    top: 0 !important;
    left: 0 !important;
    width: calc(100% - 38px);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 28px;
    text-align: start;
    position: absolute;
    width: 100%;

    & ${Text.Average} {
      text-overflow: clip;
    }
  }

  &:focus-within {
    .rc-select-selector {
      border: 1px solid #16c8bb !important;
    }

    ${({ error }) =>
      error &&
      css`
        .rc-select-selector {
          border-color: ${color.formItem.border.error} !important;
        }
      `}
  }

  ${selectDisabledCSS}
  ${selectErrorCSS}
  ${sizeCSS}
`

export const dropDownCSS = css`
  z-index: 101;
  position: absolute;

  .rc-select-dropdown {
    padding: 4px 0;
    box-shadow: 0px 12px 80px rgba(0, 0, 0, 0.06),
      0px 0.788403px 17.869px rgba(0, 0, 0, 0.0357664),
      0px 0.117173px 5.32008px rgba(0, 0, 0, 0.0242336);
    border-radius: 4px;
    border: unset !important;
    min-height: 100% !important;
    background: ${color.background};
  }

  .rc-select-dropdown-hidden {
    display: none;
  }

  .rc-select-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 !important;
  }

  .rc-select-item:hover {
    background: ${color.formItem.select.dropdown.hover};
  }

  .rc-select-item:active {
    background: ${color.formItem.select.dropdown.press};
  }

  .rc-select-item-option-active {
    background: unset;
  }

  .rc-select-item-option-state-icon {
    display: none;
  }

  .rc-select-item-option-content {
    padding: 10px;
    width: calc(100% - 20px);
  }

  .rc-virtual-list-holder {
    max-height: ${({ virtual }) => !virtual && '328px !important'};
  }

  .rc-virtual-list-holder::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }

  .rc-virtual-list-holder::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 3px;
    height: 131px;
  }

  .rc-virtual-list-scrollbar-thumb {
    width: 4px !important;
    background: #9d9d9d;
  }
`

const gradientTextBackgroundCSS = css`
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`

export const gradientTextCSS = ({ disabled }) =>
  disabled
    ? css`
        background-image: linear-gradient(
          100deg,
          rgba(194, 194, 194, 1) 0%,
          rgba(194, 194, 194, 1) 79%,
          rgba(255, 255, 255, 1) 96%,
          rgba(255, 255, 255, 1) 100%
        );
        ${gradientTextBackgroundCSS}
      `
    : css`
        background-image: linear-gradient(
          100deg,
          rgb(31, 31, 31) 0%,
          rgb(31, 31, 31) 79%,
          rgba(255, 255, 255, 1) 96%,
          rgba(255, 255, 255, 1) 100%
        );
        ${gradientTextBackgroundCSS}
      `
