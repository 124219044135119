import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as EyeCloseSVG } from '../img/eye-close.svg'
import { ReactComponent as EyeSVG } from '../img/eye.svg'
import { color } from '../../../colors'

const iconCSS = css`
  color: ${color.formItem.icon.default};

  ${({ disabled }) =>
    disabled &&
    css`
      &&& {
        color: ${color.formItem.icon.disabled};
      }
    `}
`

const EyeIcon = styled(EyeSVG)`
  ${iconCSS}
`

const EyeCloseIcon = styled(EyeCloseSVG)`
  ${iconCSS}
`

const Icon = styled(({ className, visible, onClick }) =>
  visible ? (
    <EyeCloseIcon className={className} onClick={onClick} />
  ) : (
    <EyeIcon className={className} onClick={onClick} />
  )
)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
`

export const EyeButton = styled(({ className, onClick, visible }) => {
  const mouseDownHandler = useCallback((event) => event.preventDefault(), [])
  return (
    <button
      className={className}
      onMouseDown={mouseDownHandler}
      tabIndex="-1"
      type="button"
    >
      <Icon onClick={onClick} visible={visible} />
    </button>
  )
})`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  text-align: center;
  height: 18px;
  width: 18px;
`
