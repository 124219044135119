import React from 'react'
import styled from 'styled-components'

const RotateBlock = styled.div.attrs<any, { rotate: number }>((props) => props)`
  transform: rotate(${(props) => props.rotate}deg);
  transition: transform 0.2s ease;
  display: flex;
`

export const ArrowSlim = ({ rotate = 0 }: { rotate?: number }) => (
  <RotateBlock rotate={rotate}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9 9.1001L7 4.9001L2.1 9.1001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </RotateBlock>
)
