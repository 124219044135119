/* eslint-disable */
import { StyledComponent } from 'styled-components'
import { Text } from '.'

export default Text as unknown as {
  [key in keyof typeof Text]: StyledComponent<
    (props: any) => JSX.Element,
    any,
    any,
    never
  >
}
