import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as LoaderSVG } from './img/loader.svg'

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

export const dropdownLoadingRenderer = () => <DropdownLoader />

const DropdownLoader = styled(({ className }) => (
  <div className={className}>
    <LoaderSVG />
  </div>
))`
  padding: 4px 0px;
  height: 168px;
  background: #ffffff;
  box-shadow: 0px 0.788403px 17.869px rgba(0, 0, 0, 0.0357664);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    animation: ${rotate} 1s linear infinite;
  }
`
