import React from 'react'
import dayjs from 'dayjs'
import styled, { css } from 'styled-components'
import {
  checkInRange,
  checkIsDisabled,
  checkIsSelected,
  selectDate
} from './helpers'
import { color } from '../../colors'
import { datePickerTextCSS } from './datePickerTextCSS'
import { size } from '../../constants'

interface DayProps {
  type: string
  selected: boolean
  disabled: boolean
  inRangeCharacteristics: string[]
  fullScreen: boolean
}

const inRangeElementAfterCSS = css`
  &:active {
    &::before {
      background: ${color.datePicker.date.hover};
      display: none;
    }
  }
  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 32px;
    border-radius: 4px;
    width: 0;
    transition: all 0.33s ease;
  }
`

const getRangeCharacteristics = (inRangeCharacteristics: string[]) => ({
  isEndOfMonth: inRangeCharacteristics.includes('isEndOfMonth'),
  isStartDate: inRangeCharacteristics.includes('isStartDate'),
  isEndDate: inRangeCharacteristics.includes('isEndDate'),
  inRange: inRangeCharacteristics.includes('inRange'),
  isStartOfMonth: inRangeCharacteristics.includes('isStartOfMonth'),
  isNotRangeSelect: inRangeCharacteristics.includes('isNotRangeSelect')
})

const getRangeStartDateCSS = (isEndOfMonth: boolean) => css`
  & abbr {
    ${inRangeElementAfterCSS}
    background: ${color.datePicker.date.active};
    color: ${color.text.white};

    &::before {
      width: ${isEndOfMonth ? 0 : '100%'};
      transform: translateX(50%);
      background: ${color.datePicker.date.hover};
      position: absolute;
    }
  }
`

const getRangeEndDateCSS = (isStartOfMonth: boolean) => css`
  & abbr {
    ${inRangeElementAfterCSS}
    background: ${color.datePicker.date.hover};

    &::before {
      width: ${isStartOfMonth ? 0 : '100%'};
      transform: translateX(-25%);
      background: ${color.datePicker.date.hover};
      position: absolute;
    }
  }
`

const getRangeEndOfMonth = () => css`
  & abbr {
    ${inRangeElementAfterCSS}
    background: ${color.datePicker.date.hover};

    &::before {
      width: 100%;
      transform: translateX(-50%);
      background: ${color.datePicker.date.hover};
      position: absolute;
    }
  }
`

const getRangeStartOfMonth = () => css`
  & abbr {
    ${inRangeElementAfterCSS}
    background: ${color.datePicker.date.hover};

    &::before {
      width: 100%;
      transform: translateX(50%);
      background: ${color.datePicker.date.hover};
      position: absolute;
    }
  }
`

const getInRangeCSS = () => css`
  & abbr {
    ${inRangeElementAfterCSS}
    background: ${color.datePicker.date.hover};

    &::before {
      width: 200%;
      transform: translateX(-25%);
      background: ${color.datePicker.date.hover};
    }
  }
`

const inRangeCSS = ({
  inRangeCharacteristics
}: {
  inRangeCharacteristics: string[]
}) => {
  if (!Array.isArray(inRangeCharacteristics)) {
    return
  }
  const date = getRangeCharacteristics(inRangeCharacteristics)
  if (date.isNotRangeSelect) {
    return
  }

  if (date.isStartDate) {
    return getRangeStartDateCSS(date.isEndOfMonth)
  }

  if (date.isEndDate) {
    return getRangeEndDateCSS(date.isStartOfMonth)
  }

  if (date.isEndOfMonth) {
    return getRangeEndOfMonth()
  }

  if (date.isStartOfMonth) {
    return getRangeStartOfMonth()
  }

  if (date.inRange) {
    return getInRangeCSS()
  }
}

export const Day = styled.div<DayProps>`
  position: relative;
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & abbr {
    ${datePickerTextCSS};
    color: ${color.text.dark};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    min-height: 100%;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    position: relative;

    ${({ fullScreen }) =>
      fullScreen &&
      css`
        &:hover:not(:active) {
          color: ${color.text.dark};
          background: ${color.datePicker.date.hover};
        }
      `}
  }

  ${({ type }) =>
    (type === 'prev' || type === 'next') &&
    css`
      opacity: 0;
      cursor: default;
      pointer-events: none;
    `}

  ${({ selected }) =>
    selected &&
    css`
      & abbr {
        background: #35b3a9;
        color: ${color.text.white};
      }
    `}
  
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none !important;

      & abbr {
        background: transparent;
        color: ${color.datePicker.day.disabled};
      }
    `}
  
  ${inRangeCSS}
`

export const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  column-gap: 8px;
  row-gap: 6px;
`

const staticProps = {
  role: 'button',
  tabIndex: 0
}

const Touch = styled.div`
  position: fixed;
  height: 48px;
  width: 48px;
  z-index: 4;

  @media (max-width: ${size.xs}) {
    height: 36px;
    width: 36px;
  }
`

type Button = 'prev' | 'next'

type MappingsDays = {
  date: dayjs.Dayjs
  type: Button
}

export const Days = styled(
  ({
    className,
    month,
    selectedDate,
    setSelectedDate,
    disabledDates,
    disabledCondition,
    dateRange,
    fullScreen,
    onDayClick
  }) => (
    <div className={className}>
      <DaysGrid>
        {month.map(({ date, type }: MappingsDays) => (
          <Day
            key={date.toString()}
            disabled={checkIsDisabled({
              date,
              disabledCondition,
              disabledDates
            })}
            fullScreen={fullScreen}
            inRangeCharacteristics={checkInRange({ date, dateRange })}
            onClick={selectDate(date, setSelectedDate, onDayClick)}
            selected={checkIsSelected({ selectedDate, date })}
            type={type}
            {...staticProps}
          >
            {fullScreen && (
              <Touch onClick={selectDate(date, setSelectedDate)} />
            )}
            <abbr aria-label={date.format('L')}>{date.get('D')}</abbr>
          </Day>
        ))}
      </DaysGrid>
    </div>
  )
)`
  overflow: hidden;
  height: fit-content;
  flex-grow: 1;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
`
