import { RefObject, useCallback, useEffect } from 'react'
import { isBrowser } from './isBrowser'

interface Props {
  ref: RefObject<HTMLElement>
  visible: boolean
  hide: () => void
  onClose?: () => any
}

export const useClickOutside = ({ ref, visible, hide, onClose }: Props) => {
  const clickOutside = useCallback(
    (event: Event): any => {
      if (
        ref?.current !== null &&
        (ref?.current === event.target ||
          ref?.current.contains(event.target as Node))
      ) {
        return
      }
      hide()
      typeof onClose === 'function' && onClose()
    },
    [hide, ref, onClose]
  )

  useEffect(() => {
    const root = window?.document

    if (!isBrowser) {
      return
    }

    if (root && !visible) {
      return root.removeEventListener('click', clickOutside)
    }

    root.addEventListener('click', clickOutside)

    return () => root.removeEventListener('click', clickOutside)
  }, [visible])
}
