import React, { useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { color } from '../../colors'
import { omit } from 'ramda'
import { size } from '../../constants'

export const buttonTextCSS = css`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`

const sizeCSS = ({ size }) =>
  ({
    small: css`
      height: 40px;
      padding-top: 6px;
      padding-bottom: 6px;

      ${({ iconOnly }) =>
        iconOnly &&
        css`
          padding: 0;
          width: 40px;
        `}
    `,
    medium: css`
      height: 48px;
      padding-top: 10px;
      padding-bottom: 10px;

      ${({ iconOnly }) =>
        iconOnly &&
        css`
          padding: 0;
          width: 48px;
        `}
    `,
    large: css`
      height: 56px;
      padding-top: 14px;
      padding-bottom: 14px;

      ${({ iconOnly }) =>
        iconOnly &&
        css`
          padding: 0;
          width: 56px;
        `}
    `
  }[size])

const contentStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`
const widthCSS = ({ width }) =>
  ({
    fullScreen: css`
      @media (max-width: ${size.sm}) {
        width: 100%;
      }
    `
  }[width])

const BeforeContent = styled.span``
const AfterContent = styled.span``

const DefaultLink = (props) => <a {...props}>{props.children}</a>

const LinkOrButton = styled((initialProps) => {
  const props = useMemo(
    () => ({
      ...omit(
        [
          'BeforeContent',
          'AfterContent',
          'loading',
          'Icon',
          'iconOnly',
          'LinkComponent'
        ],
        {
          ...initialProps,
          children: (
            <>
              {initialProps.BeforeContent && (
                <BeforeContent>{initialProps.BeforeContent}</BeforeContent>
              )}
              {initialProps.children || initialProps.Icon}
              {initialProps.AfterContent && (
                <AfterContent>{initialProps.AfterContent}</AfterContent>
              )}
            </>
          )
        }
      )
    }),
    [initialProps]
  )
  const Link = useMemo(
    () => initialProps.LinkComponent ?? DefaultLink,
    [initialProps.LinkComponent]
  )

  return props.type === 'link' ? (
    <Link {...omit('type', props)} />
  ) : (
    <button {...omit('href', props)} />
  )
})`
  overflow: visible;
  text-decoration: none !important;
  width: fit-content;
  ${buttonTextCSS}

  ${BeforeContent} {
    ${contentStyles}
    margin-right: 8px;
  }

  ${({ BeforeContent }) =>
    BeforeContent &&
    css`
      && {
        padding-left: 14px;
      }
    `}

  ${AfterContent} {
    ${contentStyles}
    margin-left: 8px;
  }

  ${({ AfterContent }) =>
    AfterContent &&
    css`
      && {
        padding-right: 14px;
      }
    `}
`

const loadingBackgoundTransitions = keyframes`
  0% { 
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
`

const loadingMaskTransitions = keyframes`
  0% { 
    left: -50%;
  }
  50% {
    left: 0;
  }
  100% {
    left: -50%;
  }
`

const focusCSS = css`
  &:focus-visible {
    border: 2px solid #000 !important;
  }
`

const loadingPrimaryCSS = ({ loading }) =>
  loading &&
  css`
    &,
    &:hover,
    &:active {
      background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 100%
        ),
        ${color.button.primary.hover};
      background-size: 200% auto;
      animation: ${loadingBackgoundTransitions} 1s ease-in-out infinite;
    }
  `

const loadingOutlineCSS = ({ loading }) =>
  loading &&
  css`
    &,
    &:hover,
    &:active {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      &::before {
        content: '';
        position: absolute;
        height: calc(100% + 4px);
        width: 150%;
        top: -2px;
        left: -50%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        animation: ${loadingMaskTransitions} 1s ease-in-out infinite;
      }
    }
  `

export const Button = {
  Grey: styled(LinkOrButton)`
    height: 48px;
    border: 2px solid ${color.button.grey.border};
    outline: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color.button.grey.border};
    padding: 14px 22px;
    box-sizing: border-box;
    color: ${color.button.text.default};

    &:hover {
      background: ${color.button.grey.hover};
      border: 2px solid ${color.button.grey.hover};
    }

    &:active {
      background: ${color.button.grey.press};
      border: 2px solid ${color.button.grey.press};
    }

    &:disabled {
      background: ${color.button.background.disabled};
      border: 2px solid ${color.button.background.disabled};
      color: ${color.button.text.disabled};
    }

    ${focusCSS}
    ${loadingOutlineCSS}
    ${sizeCSS}
  `,
  GreyBordered: styled(LinkOrButton)`
    border: 1px solid ${color.button.greyBordered.border};
    background: ${color.button.greyBordered.default};
    color: ${color.button.text.default};
    font-weight: 600;
    padding-left: 15px;
    padding-right: 15px;
    text-decoration: none !important;
    position: relative;
    outline: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 22px;
    box-sizing: border-box;
    height: 48px;
    overflow: hidden;

    &:hover {
      background: ${color.button.greyBordered.hover};
      border: 1px solid ${color.button.greyBordered.border};
    }

    &:active {
      background: ${color.button.grey.press};
      border: 1px solid ${color.button.greyBordered.border};
    }

    &:disabled {
      background: ${color.button.background.disabled};
      color: ${color.button.greyBordered.border};
      border: 1px solid ${color.button.greyBordered.border};
    }

    ${focusCSS}
    ${loadingOutlineCSS}
    ${sizeCSS}
  `,
  NewPrimary: styled(LinkOrButton)`
    position: relative;
    color: #ffffff;
    outline: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color.button.primary.default};
    border: 2px solid ${color.button.primary.default};
    padding: 14px 22px;
    box-sizing: border-box;
    height: 48px;
    overflow: hidden;

    &:hover {
      background: ${color.button.primary.hover};
      border: 2px solid ${color.button.primary.hover};
      color: #ffffff;
    }

    &:active {
      background: ${color.button.primary.press};
      border: 2px solid ${color.button.primary.press};
    }

    &:disabled {
      background: ${color.button.background.disabled};
      color: ${color.button.text.disabled};
      border: 2px solid ${color.button.background.disabled};

      & svg {
        path {
          stroke: ${color.button.text.disabled};
        }
      }
    }

    ${focusCSS}
    ${loadingPrimaryCSS}
    ${sizeCSS}  
    ${widthCSS}
  `,
  NewOutline: styled(LinkOrButton)`
    color: #ffffff;
    outline: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 22px;
    box-sizing: border-box;
    background: ${({ transparent }) =>
      transparent ? 'transparent' : String(color.button.background.default)};
    border: 2px solid ${color.button.outline.border};
    height: 48px;
    overflow: hidden;
    color: ${color.button.outline.content};

    &:hover {
      background: ${color.button.outline.hover};
      color: ${color.button.outline.content};
    }

    &:active {
      background: ${color.button.outline.press};
      color: ${color.button.outline.content};
    }

    &:disabled {
      border-color: ${color.button.background.disabled};
      background: ${color.button.background.disabled};
      color: ${color.button.text.disabled};

      & svg {
        path {
          stroke: ${color.button.text.disabled};
        }
      }
    }

    ${focusCSS}
    ${loadingOutlineCSS}

    ${sizeCSS}
  `,
  NoBordersBlack: styled(LinkOrButton)`
    border: 1px solid transparent;
    background: transparent;
    color: ${color.button.text.default};
    font-weight: 600;
    text-decoration: none !important;
    position: relative;
    outline: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    box-sizing: border-box;
    height: 48px;
    overflow: hidden;

    &:hover {
      color: ${color.button.noBordersBlack.hover};
      background: transparent;
      border: 1px solid transparent;
    }

    &:active {
      color: ${color.button.noBordersBlack.press};
      background: transparent;
      border: 1px solid transparent;
    }

    &:disabled {
      color: ${color.button.text.disabled};
    }

    ${({ BeforeContent }) =>
      BeforeContent &&
      css`
        && {
          padding-left: 0;
        }
      `}

    ${({ AfterContent }) =>
      AfterContent &&
      css`
        && {
          padding-right: 0;
        }
      `}

    ${({ loading }) =>
      loading &&
      css`
        color: ${color.button.noBordersBlack.loading};
      `}

    ${focusCSS}
    ${loadingOutlineCSS}
 
    ${sizeCSS}
  `
}
