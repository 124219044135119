import React from 'react'
import cookieSvg from './img/cookie.svg'
import styled from 'styled-components'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { documents } from '/static/db.json'
import { size } from '../../constants'

export const IconCookie = styled(({ className }) => (
  <img alt="cookie" className={className} src={cookieSvg} />
))`
  pointer-events: none;

  @media (max-width: ${size.xs}) {
    width: 25px;
    height: 24px;
  }
`

export const PlateTitle = styled(({ className }) => (
  <div className={className}>
    <IconCookie />
    <Title.H5 bold>Мы используем cookie</Title.H5>
  </div>
))`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Title.H5} {
    color: #fff;
  }

  ${IconCookie} {
    display: none;
  }

  @media (max-width: ${size.sm}) {
    && {
      ${Title.H5} {
        margin-left: 16px;
      }

      & ${IconCookie} {
        display: block !important;
      }
    }
  }
`

export const CookieTextContent = styled(({ className }) => (
  <div className={className}>
    <IconCookie />
    <div>
      <PlateTitle />
      <Text.Small>
        Куки помогают сайту работать лучше, так же как психологи помогают
        повысить качество жизни. О том, какие данные мы собираем, можно
        прочитать в нашей{' '}
        <a href={documents.urlPolicy} rel="noreferrer" target="_blank">
          Политике конфиденциальности.
        </a>
      </Text.Small>
    </div>
  </div>
))`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${PlateTitle} {
    @media (max-width: ${size.sm}) {
      margin-bottom: 8px;
    }
  }

  & br {
    display: none;
  }

  @media (max-width: 1224px) {
    & ${Text.Small} {
      & br {
        display: block;
      }
    }
  }

  @media (max-width: ${size.sm}) {
    & ${IconCookie} {
      display: none;
    }
  }

  ${Text.Small} {
    color: #fff;
    white-space: pre-line;
    letter-spacing: 0.25px;

    @media (max-width: ${size.xs}) {
      margin-bottom: 16px;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    @media (max-width: ${size.xxs}) {
      font-size: 12px !important;
      line-height: 18px !important;
    }

    a {
      text-decoration: none;
      color: #fff;
      border-bottom: 1px #fff solid;
      white-space: nowrap;
    }
  }
`
