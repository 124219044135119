import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import React, { memo, useCallback, useRef } from 'react'
import arrowSVG from '../img/arrow.svg'
import styled, { css } from 'styled-components'
import { ClearButton } from './ClearButton'
import { Montserrat } from '../../../constants'
import { color } from '../../../colors'
import { pick } from 'rambda'
import { useReplacedField } from '../useReplacedField'

const sizeCSS = ({ size }) =>
  ({
    medium: css`
      & input {
        height: 40px;
      }
    `,
    large: css`
      & input {
        height: 48px;
        padding: 14px;
        padding-right: 32px;
      }

      & ${ClearButton} {
        right: 14px;
      }
    `
  }[size])

const dropdownCSS = () => css`
  .country-list {
    margin-top: 4px;
    padding: 4px 0;
    background: #fff;
    box-shadow: 0px 12px 80px rgba(0, 0, 0, 0.06),
      0px 0.788403px 17.869px rgba(0, 0, 0, 0.0357664),
      0px 0.117173px 5.32008px rgba(0, 0, 0, 0.0242336);
    border-radius: 4px;
    z-index: 10;
    overflow-y: scroll;
    min-width: 113px;
    font-family: ${Montserrat};

    &::-webkit-scrollbar {
      background: transparent;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #9d9d9d;
      border-radius: 3px;
      height: 131px;
    }

    .country {
      text-align: left;
    }

    .country:hover {
      background: ${color.formItem.select.dropdown.hover};

      .dial-code {
        color: ${color.formItem.select.option.description};
      }
    }
  }
`

const errorCSS = ({ error }) =>
  error &&
  css`
    &:hover,
    &:active {
      &::before {
        z-index: -1;
      }
    }

    & input {
      border-color: ${color.formItem.border.error};

      &:hover,
      &:active {
        border-color: ${color.formItem.border.error};
      }

      &:hover {
        background: ${color.formItem.background.errorHover};
      }

      &:focus {
        border-color: ${color.formItem.border.error};
      }
    }

    & input + .flag-dropdown {
      border-color: ${color.formItem.border.error};
    }

    & input:hover + .flag-dropdown,
    &:active + .flag-dropdown {
      border-color: ${color.formItem.border.error};
    }

    & input:hover + .flag-dropdown {
      background: ${color.formItem.background.errorHover};
    }

    & input:focus + .flag-dropdown {
      border-color: ${color.formItem.border.error};
    }
  `

const getProps = (field) =>
  pick(
    [
      'onBlur',
      'onChange',
      'onFocus',
      'value',
      field.placeholder ? 'placeholder' : ''
    ],
    field
  )

export const InputPhone = styled(
  memo(
    ({
      className,
      clearCondition = true,
      country,
      disableDropdown = true,
      onChangePhoneInput,
      ...field
    }) => {
      const ref = useRef()
      const replacedField = useReplacedField(field)
      const onClear = useCallback(() => {
        ref.current.focus()
        field.onChange('')
      }, [field.onChange])

      const onChangePhoneInputHandler = useCallback(
        (value, country, e, formattedValue) => {
          if (typeof onChangePhoneInput === 'function') {
            onChangePhoneInput(value, country, e, formattedValue)
          }
          field.onChange(value)
        },
        [onChangePhoneInput, field]
      )

      return (
        <div className={className}>
          <PhoneInput
            country={country}
            disableDropdown={disableDropdown}
            inputProps={{
              name: field.name,
              autoFocus: false,
              ref,
              id: field.id
            }}
            {...getProps(replacedField)}
            onChange={onChangePhoneInputHandler}
            prefix="+"
          />
          {field.value && clearCondition && <ClearButton onClick={onClear} />}
        </div>
      )
    }
  )
)`
  && {
    .special-label {
      display: none;
    }

    .selected-flag {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
    }

    .flag-dropdown {
      background-color: ${color.background} !important;
    }

    position: relative;
    height: fit-content;
    width: 100%;
    box-sizing: border-box;

    & ${ClearButton} {
      position: absolute;
      z-index: 2;
      right: 10px;
      display: block;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
    }

    & input {
      width: 100%;
      height: 40px;
      padding: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${color.formItem.text.default};
      letter-spacing: 0px;
      border-radius: 4px;
      padding-right: 32px !important;
      padding-left: 70px !important;
      outline: none;
      border: 1px solid ${color.formItem.border.default};
      color: ${color.formItem.text.default};
      height: 40px;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0 48px inset #fff !important;
        -webkit-box-shadow: 0 0 0 48px inset #fff !important;
      }

      &::placeholder {
        color: ${color.formItem.placeholder};
      }

      &:hover {
        border-color: ${color.formItem.border.hover};
      }

      &:focus {
        border-color: ${color.formItem.border.focus};
      }

      &::placeholder {
        color: ${color.formItem.placeholder};
      }

      &:disabled {
        background: ${color.formItem.background.disabled};
        border-color: ${color.formItem.background.disabled};
      }

      ${({ success }) =>
        success &&
        css`
          border-color: #03b2a5 !important;
        `}
    }

    .flag-dropdown {
      border: 1px solid ${color.formItem.border.default};
    }

    & input:hover + .flag-dropdown {
      border-color: ${color.formItem.border.hover};
    }

    & input:focus + .flag-dropdown {
      border-color: ${color.formItem.border.focus};
    }

    &:not(:focus-within) {
      &::before {
        content: '';
        cursor: pointer;
        pointer-events: none;
        position: absolute;
        right: 24px;
        z-index: 1;
        width: 2ch;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
        height: calc(100% - 4px);
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0.6699054621848739) 100%
        );
      }
    }

    ${errorCSS}
    ${sizeCSS}
    ${dropdownCSS}
  }
`
