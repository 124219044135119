import React, { memo, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

const Counter = styled.span`
  font: inherit;
`

const formatter = (timeCount: number | string) =>
  timeCount.toString().length === 1 ? [0, timeCount].join('') : timeCount

interface Props {
  className?: string
  time: Date
  onComplete: () => void
  onTick?: () => void
}

type Timer = {
  seconds: number
  minutes: number
}

export const Countdown = styled(
  memo((props: Props) => {
    const refInterval = useRef<ReturnType<typeof setInterval>>()
    const finish = dayjs(props.time)
    const [state, setState] = useState<Timer>({
      minutes: finish.diff(dayjs(), 'minute'),
      seconds: finish.diff(dayjs(), 'second') % 60
    })

    useEffect(() => {
      const { minutes, seconds } = state
      if (minutes === 0 && seconds === 0) {
        typeof props.onComplete === 'function' && props.onComplete()
        clearTimeout(refInterval.current)
      }
    }, [state])

    useEffect(() => {
      const { minutes, seconds } = state
      if (seconds < 0 || minutes < 0) {
        return
      }

      refInterval.current = setInterval(() => {
        setState({
          seconds: finish.diff(dayjs(), 'second') % 60,
          minutes: finish.diff(dayjs(), 'minute')
        })

        typeof props.onTick === 'function' && props.onTick()
      }, 1000)

      return () => clearInterval(refInterval.current)
    }, [])

    return (
      <Counter className={props.className}>
        {formatter(state.minutes)}:{formatter(state.seconds)}
      </Counter>
    )
  })
)``
