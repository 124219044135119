import React, { useCallback } from 'react'
import {
  ButtonGroup,
  ButtonNewOutline,
  ButtonNewPrimary,
  List,
  ListItem,
  Plate,
  Title,
  Wrap
} from './index.styles'
import { navigate } from 'gatsby'
import { selectDeviceSizeIsMobile } from '../../../../state/reducers/deviceSlice'
import { useNewOpenForm } from '../../../GA'
import { useSelector } from 'react-redux'

export const AnswerStep = ({ hide }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)

  const toForm = useCallback(() => {
    navigate('/form')
    hide()
  }, [])

  return (
    <Wrap>
      <Title bold>
        Чтобы войти в личный кабинет, нужно стать нашим клиентом
      </Title>
      <List>
        <ListItem>
          Выберите психолога самостоятельно или с помощью наших специалистов
        </ListItem>
        <ListItem>
          Мы свяжемся с вами в WhatsApp или SMS, чтобы подтвердить или уточнить
          запрос
        </ListItem>
      </List>
      <Plate semiBold>
        👆🏻 С этого момента вам доступен личный кабинет, где вы сможете
        отслеживать и пополнять баланс
      </Plate>
      <ButtonGroup>
        <ButtonNewPrimary
          href="/wizard/"
          onClick={useNewOpenForm('mainPageFirstScreen')}
          size={isMobile ? 'medium' : 'small'}
          type="link"
        >
          Подобрать самостоятельно
        </ButtonNewPrimary>
        <ButtonNewOutline onClick={toForm} size={isMobile ? 'medium' : 'small'}>
          Заполнить анкету
        </ButtonNewOutline>
      </ButtonGroup>
    </Wrap>
  )
}
