import {
  AvatarClosed1,
  AvatarClosed2,
  AvatarClosed3,
  AvatarClosed4,
  AvatarOpen1,
  AvatarOpen2,
  AvatarOpen3,
  AvatarOpen4,
  AvatarWink1,
  AvatarWink2,
  AvatarWink3,
  AvatarWink4
} from '.'

export default {
  AvatarClosed1,
  AvatarClosed2,
  AvatarClosed3,
  AvatarClosed4,
  AvatarOpen1,
  AvatarOpen2,
  AvatarOpen3,
  AvatarOpen4,
  AvatarWink1,
  AvatarWink2,
  AvatarWink3,
  AvatarWink4
}
