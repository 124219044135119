import Icons from '../../Icons/index.library'
import styled, { css } from 'styled-components'
import { color } from '../../../colors'

export const SearchIcon = styled(Icons.IconLoupe)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  color: ${color.formItem.icon.default};

  ${({ disabled }) =>
    disabled &&
    css`
      &&& {
        color: ${color.formItem.icon.disabled};
      }
    `}
`
