import React, { useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Checkbox } from '../../atoms/FormItems/Checkbox'
import { CheckboxMiniWithLabel } from '../../atoms/FormItems/CheckboxMini'
import { CheckboxTabsToggler } from '../CheckboxToggler'
import { Field, useField } from 'react-final-form'
import { Input } from '../../atoms/FormItems/Input/Input'
import { InputPassword } from '../../atoms/FormItems/Input/InputPassword'
import { InputPhone } from '../../atoms/FormItems/Input/PhoneInput'
import { InputSelect } from '../../atoms/FormItems/Select'
import { InputTimeRange } from '../../atoms/FormItems/Input/InputTimeRange'
import { InputVariousSelect } from '../../atoms/FormItems/Select/InputVariousSelect'
import { RadioMiniWithLabel } from '../../atoms/FormItems/RadioMini'
import { RangeWithLabel } from '../../atoms/FormItems/Range'
import { Text } from '../../atoms/Text'
import { TextArea } from '../../atoms/FormItems/Input/TextArea'
import { color } from '../../colors'
import { omit, pick } from 'rambda'

const fade = keyframes`
  from {
    opacity: 0;
    transform: translateX(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const ErrorMessage = styled(Text.Small)`
  color: ${color.formItem.text.error} !important;
  z-index: 0;
  animation: ${fade} 0.33s linear 1 alternate;
  text-align: start;
  font-weight: 500;
`

export const SuccessMessage = styled(Text.Small)`
  color: ${color.formItem.text.success} !important;
  z-index: 0;
  animation: ${fade} 0.33s linear 1 alternate;
  text-align: start;
`
export const Label = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  pointer-events: none;

  &:hover + * {
    &::before {
      z-index: -1;
    }
  }
`

export const Description = styled(Text.Small)``

const RequiredStar = styled(({ className }) => (
  <span className={className}>*</span>
))`
  color: ${color.formItem.required};
`

const getValidationMode = ({ mode = 'touch', meta }) => {
  if (mode === 'submitFailed') {
    return meta.submitFailed
  }
  if (mode === 'touch') {
    return meta.touched && !meta.active
  }
}

const isError = (meta, mode) =>
  (meta.error || meta.submitError) && getValidationMode({ mode, meta })

export const FormItemOnControl = styled(({ className, error, ...props }) => {
  const { meta } = props
  const metaMessageIsString = useMemo(
    () =>
      typeof meta?.error === 'string' || typeof meta?.submitError === 'string',
    [meta?.error, meta?.submitError]
  )

  return (
    <div className={className}>
      {props.label && (
        <Label htmlFor={props.id}>
          {props.label} {props.required && <RequiredStar />}
        </Label>
      )}
      {props.description && <Description>{props.description}</Description>}
      {props.children}
      {metaMessageIsString && getValidationMode({ mode: props.mode, meta }) && (
        <ErrorMessage>
          {(meta?.error || meta?.submitError) ?? 'Ошибка'}
        </ErrorMessage>
      )}
      {error && error.message && (
        <ErrorMessage>{error.message ?? 'Ошибка'}</ErrorMessage>
      )}
      {props.success?.visible && (
        <SuccessMessage>{props.success?.message ?? 'Успешно'}</SuccessMessage>
      )}
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > ${Label} {
    margin-bottom: ${({ description }) => (description ? '4px' : '8px')};
  }

  & > ${Description} {
    color: #9d9d9d;
    margin-bottom: 8px;
  }

  & > ${ErrorMessage}, & > ${SuccessMessage} {
    margin-top: 4px;
    width: fit-content;
    margin-bottom: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      & > ${Label}, ${Description} {
        color: ${color.formItem.label.disabled};
      }
    `}
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const FormItemOnControlRow = styled(({ className, ...props }) => (
  <div className={className}>
    <Row>
      {props.children}
      {props.label && (
        <label htmlFor={props.id}>
          {props.label} {props.required && <RequiredStar />}
        </label>
      )}
    </Row>
  </div>
))`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Row} {
    margin-bottom: 8px;
  }

  & ${Row} > label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  & > ${ErrorMessage} {
    margin-top: 4px;
    width: fit-content;
  }
`

const pickedProps = [
  'meta',
  'label',
  'description',
  'required',
  'id',
  'disabled',
  'error',
  'mode',
  'className'
]

const getDisabledFormItemOnControlProp = (disabled) => ({
  disabled: Array.isArray(disabled)
    ? disabled.filter(Boolean).length === disabled.length
    : disabled
})

const ControlFormItem = styled(({ FormItem, onChange, ...props }) => (
  <Field
    name={props.name}
    parse={props.parse}
    {...pick(props.required ? ['validate'] : [], props)}
  >
    {({ input, meta }) => (
      <FormItemOnControl
        {...pick(pickedProps, {
          ...props,
          meta
        })}
        {...getDisabledFormItemOnControlProp(props.disabled)}
      >
        <FormItem
          {...input}
          countCharacters={props.countCharacters}
          disabled={props.disabled}
          error={
            (meta.error || meta.submitError) &&
            getValidationMode({ mode: props.mode, meta })
          }
          id={props.id}
          onBlur={props.onBlur}
          onChange={onChange || input.onChange}
          onClick={props.onClick}
          onFocus={props.onFocus}
          options={props.options}
          placeholder={props.placeholder}
          size={props.size}
          virtual={props.virtual}
          {...pick(
            [
              props.focusErrors ? 'focusErrors' : '',
              props.multipliePrefix ? 'multipliePrefix' : '',
              props.optionType ? 'optionType' : '',
              props.dropdownLoading ? 'dropdownLoading' : '',
              props.fullScreenDropdown ? 'fullScreenDropdown' : '',
              props.fullScreenDropdownTitle ? 'fullScreenDropdownTitle' : '',
              props.dropdownLoading ? 'dropdownLoading' : '',
              props.searchMode ? 'searchMode' : '',
              props.timezone ? 'timezone' : '',
              props.beforeOnChange ? 'beforeOnChange' : ''
            ],
            props
          )}
        />
      </FormItemOnControl>
    )}
  </Field>
))``

const CheckboxItem = ({ input, option, meta, noBordered, ...props }) => (
  <Checkbox
    {...pick(['size', 'flexStart'], props)}
    {...input}
    {...option}
    error={meta.error && meta.touched}
    id={option.id}
    noBordered={noBordered}
    value={option.value}
  />
)

export const ControlCheckboxes = styled(
  ({ className, options, validate, ...props }) => {
    const { meta } = useField(props.name)

    return (
      <FormItemOnControl
        type={props.type || 'radio'}
        {...pick(pickedProps, {
          ...props,
          meta
        })}
      >
        <div className={className}>
          {(options ?? []).map((option) => (
            <Field
              key={option.value}
              name={props.name}
              type={props.type || 'radio'}
              validate={validate}
              value={option.value}
            >
              {({ input }) => (
                <CheckboxItem
                  input={{ type: props.type || 'radio', ...input }}
                  meta={meta}
                  option={option}
                  {...pick(['size', 'flexStart'], props)}
                />
              )}
            </Field>
          ))}
        </div>
      </FormItemOnControl>
    )
  }
)``

export const ControlMiniCheckboxes = styled(
  ({ className, options, fields, ...props }) => {
    const { meta } = useField(props.name)
    return (
      <FormItemOnControl
        {...pick(pickedProps, {
          ...props,
          meta
        })}
      >
        <div className={className}>
          {(options || [])
            .filter((field) => (fields ? fields.includes(field.name) : true))
            .map(({ name, label, requiredField }) => (
              <Field
                key={name}
                name={name}
                type="checkbox"
                validate={requiredField && props.validate()}
              >
                {({ input }) => (
                  <CheckboxMiniWithLabel
                    fill={props.fill ?? true}
                    id={name}
                    label={label}
                    {...input}
                  />
                )}
              </Field>
            ))}
        </div>
      </FormItemOnControl>
    )
  }
)``

export const ControlMiniRadioGroup = styled(
  ({ className, options, ...props }) => {
    const { meta } = useField(props.name)
    return (
      <FormItemOnControl
        {...pick(pickedProps, {
          ...props,
          meta
        })}
      >
        <div className={className}>
          {(options || []).map(({ value, label, requiredField }) => (
            <Field
              key={value}
              name={props.name}
              type="radio"
              validate={requiredField && props.validate()}
              value={value}
            >
              {({ input }) => (
                <RadioMiniWithLabel id={value} label={label} {...input} />
              )}
            </Field>
          ))}
        </div>
      </FormItemOnControl>
    )
  }
)``

export const ControlCheckboxesToggler = styled(
  ({ className, options, validate, ...props }) => {
    const {
      meta,
      input: { value }
    } = useField(props.name)
    return (
      <FormItemOnControl
        {...pick(pickedProps, {
          ...props,
          meta
        })}
      >
        <Field
          className={className}
          name={props.name}
          type="radio"
          validate={validate}
          value={value}
        >
          {({ input }) => (
            <CheckboxTabsToggler
              disabled={props.disabled}
              error={isError(meta, props.mode)}
              options={options}
              size={props.size}
              {...input}
              value={value}
            />
          )}
        </Field>
      </FormItemOnControl>
    )
  }
)``

export const ControlSelect = styled(({ className, ...props }) => (
  <ControlFormItem className={className} {...props} FormItem={InputSelect} />
))``

export const ControlVariousSelect = styled(({ className, ...props }) => (
  <ControlFormItem
    className={className}
    {...props}
    FormItem={InputVariousSelect}
  />
))``

export const ControlInputTimeRange = styled(({ className, ...props }) => (
  <ControlFormItem className={className} {...props} FormItem={InputTimeRange} />
))``

export const ControlTextArea = styled(({ className, ...props }) => (
  <ControlFormItem className={className} {...props} FormItem={TextArea} />
))``

export const ControlInput = styled(({ className, ...props }) => (
  <ControlFormItem className={className} {...props} FormItem={Input} />
))``

export const ControlInputPassword = styled(({ className, ...props }) => (
  <ControlFormItem className={className} {...props} FormItem={InputPassword} />
))``

export const ControlRange = styled(
  ({ className, label, rangeValues, name, initialValue }) => (
    <Field
      {...omit(initialValue ? [] : ['initialValue'], {
        name,
        initialValue
      })}
    >
      {({ input }) => (
        <RangeWithLabel
          className={className}
          label={label}
          rangeValues={rangeValues ?? [24, 65]}
          {...input}
        />
      )}
    </Field>
  )
)``

export const ControlPhoneInput = styled(({ className, onChange, ...props }) => (
  <Field name={props.name || 'tel'} validate={props.validate}>
    {({ input, meta }) => (
      <FormItemOnControl
        className={className}
        description={props.description}
        id={props.id || 'tel'}
        label={props.label}
        meta={meta}
        required={props.required}
      >
        <InputPhone
          {...input}
          country={props.country}
          disableDropdown={props.disableDropdown}
          error={meta.error && meta.touched && !meta.active}
          id={props.id || 'tel'}
          onChange={onChange ?? input.onChange}
          onChangePhoneInput={props.onChangePhoneInput}
          placeholder={props.placeholder}
          size={props.size}
        />
      </FormItemOnControl>
    )}
  </Field>
))``
