import dayjs from 'dayjs'
import { MouseEvent } from 'react'
import { twoDaysIsEqual } from '../../daysjsConfig'

export const checkIsSelected = ({
  selectedDate,
  date
}: Record<string, dayjs.Dayjs>) =>
  selectedDate ? twoDaysIsEqual(selectedDate, date) : false

type checkIsDisabledProps = {
  date: dayjs.Dayjs
  disabledDates?: dayjs.Dayjs[]
  disabledCondition?: (date: dayjs.Dayjs) => boolean
}

export const checkIsDisabled = ({
  date,
  disabledDates,
  disabledCondition
}: checkIsDisabledProps): boolean => {
  if (Array.isArray(disabledDates)) {
    return Boolean(
      disabledDates.find((disabledDate) =>
        checkIsSelected({ selectedDate: disabledDate, date })
      )
    )
  }
  if (typeof disabledCondition !== 'function') {
    return false
  }

  return disabledCondition(date)
}

export const selectDate =
  (
    date: dayjs.Dayjs,
    setSelectedDate: (date: dayjs.Dayjs) => void,
    onDayClick?: (date: dayjs.Dayjs) => void
  ) =>
  (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (typeof onDayClick === 'function') {
      onDayClick(date)
    }
    return setSelectedDate(date)
  }

type checkInRanngeProps = {
  date: dayjs.Dayjs
  dateRange: Partial<{
    end: dayjs.Dayjs
    start: dayjs.Dayjs
  }>
}

export const checkInRange = ({ date, dateRange }: checkInRanngeProps) => {
  const inRangeCharacteristics = [] as string[]

  if (!dateRange.end || !dateRange.start) {
    inRangeCharacteristics.push('isNotRangeSelect')
    return inRangeCharacteristics
  }
  if (twoDaysIsEqual(dateRange.start, date)) {
    inRangeCharacteristics.push('isStartDate')
  }
  if (twoDaysIsEqual(dateRange.end, date)) {
    inRangeCharacteristics.push('isEndDate')
  }
  const inRange = date.isBetween(
    dateRange.start.subtract(1, 'day'),
    dateRange.end.add(1, 'day')
  )
  if (inRange) {
    inRangeCharacteristics.push('inRange')
  }
  if (inRange && twoDaysIsEqual(date, date.endOf('M'))) {
    inRangeCharacteristics.push('isEndOfMonth')
  }
  if (inRange && twoDaysIsEqual(date, date.startOf('M'))) {
    inRangeCharacteristics.push('isStartOfMonth')
  }

  return inRangeCharacteristics
}

export const getRange = (date?: dayjs.Dayjs, autoRange?: number) => {
  if (!autoRange || typeof autoRange !== 'number' || autoRange <= 1) {
    return {}
  }
  const range = { start: date } as {
    start: dayjs.Dayjs
    end?: dayjs.Dayjs
  }

  if (date && dayjs(date).isValid()) {
    range.end = date!.add(autoRange - 1, 'day')
  }

  return range
}
