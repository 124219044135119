import React, { useCallback } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ReactComponent as CrossSVG } from '../img/cross.svg'
import { color } from '../../../colors'

const CrossIcon = styled(CrossSVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  color: ${color.formItem.icon.default};

  ${({ disabled }) =>
    disabled &&
    css`
      &&& {
        color: ${color.formItem.icon.disabled};
      }
    `}
`

const rotate = keyframes`
  from {
    transform: scale(0.5) rotate(180deg);
  }
  to {
    transform: scale(1) rotate(0);
  }
`

export const ClearButton = styled(({ className, onClick, disabled }) => {
  const mouseDownHandler = useCallback((event) => event.preventDefault(), [])

  return (
    <button className={className} onMouseDown={mouseDownHandler} tabIndex="-1">
      <CrossIcon disabled={disabled} onClick={onClick} />
    </button>
  )
})`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  text-align: center;
  height: 18px;
  width: 18px;
  animation: ${rotate} 0.33s linear 1 alternate;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`
