export const size = {
  xxs: '320px',
  xs375: '374px',
  xs: '413px',
  sm: '767px',
  md: '1023px',
  lg: '1439px'
}

export const maxWidth = {
  xxs: '100%',
  xs: '100%',
  sm: '748px',
  md: '980px',
  lg: '1224px'
}

export const Montserrat = 'Montserrat, sans-serif'
export const lkURL = `${process.env.GATSBY_SITEURL}/account`

export const PSYCHOLOGIST_WORKS_WITH_TEENS = 'psychologist_works_with_teens'
