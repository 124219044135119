import styled, { css } from 'styled-components'
import { ReactComponent as LopeSVG } from '../img/lope.svg'
import { color } from '../../../colors'

export const LopeIcon = styled(LopeSVG)`
  color: ${color.formItem.icon.default};

  ${({ disabled }) =>
    disabled &&
    css`
      &&& {
        color: ${color.formItem.icon.disabled};
      }
    `}
`
