import Button from '../../../atoms/Button/index.library'
import Icons from '../../../atoms/Icons/index.library'
import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useWeekDatePickerContext } from './WeekDatePickerProvider'
import { useWeeksNavigation } from './useWeeksNavigation'

export const ArrowButton = styled(Button.NoBordersBlack)`
  width: 24px;
  height: 24px;
  padding: 0;
`

export const ArrowButtonRight = styled(ArrowButton).attrs({
  type: 'button',
  children: <Icons.IconArrowRight />
})``

export const ArrowButtonLeft = styled(ArrowButton).attrs({
  type: 'button',
  children: <Icons.IconArrowLeft />
})``

const ButtonGroup = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 4px;
`

interface CheckIsDisabledProps {
  date: dayjs.Dayjs
  disabledCheckFunction?: (date: dayjs.Dayjs) => boolean
}

const checkIsDisabled = ({
  date,
  disabledCheckFunction
}: CheckIsDisabledProps) => {
  if (typeof disabledCheckFunction !== 'function') {
    return false
  }

  return disabledCheckFunction(date)
}

type NavigationProps = {
  disabledNextWeekCondition?: (date: dayjs.Dayjs) => boolean
  disabledPrevWeekCondition?: (date: dayjs.Dayjs) => boolean
  onWeekChange?: (day: dayjs.Dayjs) => void
}

export const NavigationButtons = ({
  onWeekChange,
  disabledPrevWeekCondition,
  disabledNextWeekCondition
}: NavigationProps) => {
  const weekCalendar = useWeekDatePickerContext()
  const { prevWeek, nextWeek } = useWeeksNavigation({
    onWeekChange
  })
  return (
    <ButtonGroup>
      <ArrowButtonLeft
        disabled={checkIsDisabled({
          date: weekCalendar.state,
          disabledCheckFunction: disabledPrevWeekCondition
        })}
        onClick={prevWeek}
      />
      <ArrowButtonRight
        disabled={checkIsDisabled({
          date: weekCalendar.state,
          disabledCheckFunction: disabledNextWeekCondition
        })}
        onClick={nextWeek}
      />
    </ButtonGroup>
  )
}
