import React from 'react'
import {
  IllustrationAccept,
  IllustrationBreak,
  IllustrationBreakRemove,
  IllustrationBroken,
  IllustrationCalendar,
  IllustrationCalendarBurn,
  IllustrationCalendarError,
  IllustrationCalendarPay,
  IllustrationCalendarRemoveEvent,
  IllustrationChangePsy,
  IllustrationDialogue,
  IllustrationGift,
  IllustrationMessage,
  IllustrationNewYear,
  IllustrationPaid,
  IllustrationPaymentError,
  IllustrationProblem,
  IllustrationSearch,
  IllustrationSend,
  IllustrationSignature,
  IllustrationUserCard,
  IllustrationWait
} from '.'

const viewBox = '0 0 120 103'

const smallSize = {
  height: '74px',
  width: '86px',
  viewBox
}

const mediumSize = {
  height: '103px',
  width: '120px',
  viewBox
}

const getPropsBySize = (size?: Size) =>
  size
    ? {
        medium: mediumSize,
        small: smallSize
      }[size]
    : mediumSize

export type Size = 'small' | 'medium'

type Props = {
  size?: Size
} & React.SVGProps<SVGSVGElement>

export default Object.fromEntries(
  Object.entries({
    IllustrationBroken,
    IllustrationCalendar,
    IllustrationCalendarBurn,
    IllustrationCalendarError,
    IllustrationCalendarPay,
    IllustrationCalendarRemoveEvent,
    IllustrationDialogue,
    IllustrationGift,
    IllustrationMessage,
    IllustrationNewYear,
    IllustrationPaid,
    IllustrationPaymentError,
    IllustrationSearch,
    IllustrationSend,
    IllustrationSignature,
    IllustrationBreak,
    IllustrationBreakRemove,
    IllustrationProblem,
    IllustrationUserCard,
    IllustrationAccept,
    IllustrationChangePsy,
    IllustrationWait
  }).map(([icon, Icon]) => [
    icon,
    ({ size }: Props) => <Icon {...getPropsBySize(size)} />
  ])
)
